import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { staffData } from '../Admin/Data';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import './industry.css';
import Select from 'react-select';

const IndustryInfo = () => {
  const [industryData, setIndustryData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchStaff, setSearchStaff] = useState('');
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);
  const [id, setId] = useState('');
  const [industryLists, setIndustryLists] = useState([]);
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);
  const token = useSelector((state) => state.token);
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'industry_type',
    orderby: 'asc'
  });
  useEffect(() => {
    getBrandList();
  }, [searchClient, itemOffset, deleteRow, id, isArrowObj,pageCount]);

  const getBrandList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'listindustry?' +
          'search=' +
          searchClient +
          (id !== '' ? '&industrytype_id=' + id.value : '') +
          '&page=' +
          itemOffset +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setIndustryData(value.data.response.data) &
          console.log('staffdataaaaaaa', value.data.response.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'listindustry', config)
      .then(
        (value) =>
          setIndustryLists(value.data.response.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (industryData) {
      setResponse(industryData);
    }
  }, [industryData, searchClient, deleteRow]);

  // function for pagination

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhStaffByName(searchStaffByName);
  };
  // function for delete row

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deleteindustry/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };
  const transformIndustryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Industry' };
    const dataOptions = data.map(({ id, industry_type }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));

    return [selectOption, ...dataOptions];
  };

  const industryOptions = transformIndustryDataToOptions(industryLists);

  //   function for delete row
  const handleClearInput = () => {
    setSearchClient('');
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  return (
    <>
      <Constant>
        <BreadScrum
          btn_name="Add Industry"
          routepath={'addindustry'}
          heading={'Manage Industry'}
          btn={'Add Industry'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' ||
            permissions?.Manage_Industry?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div
                        className="search-field "
                        style={{ marginBottom: '13px' }}
                      >
                        <div className="mb-3">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              className="d-flex align-items-center h-100 searchbar_parent"
                              action="#"
                            >
                              <div
                                className=" position-relative"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  border: '1px solid black'
                                }}
                              >
                                <div className="input-group-prepend bg-transparent"></div>
                                <input
                                  style={{
                                    height: 30,
                                    paddingRight: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchClient(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                  value={searchClient}
                                />
                                {searchClient && (
                                  <span
                                    className="position-absolute top-50 end-0 translate-middle-y"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: 10
                                    }}
                                    onClick={handleClearInput}
                                  >
                                    ✕
                                  </span>
                                )}
                              </div>
                            </div>
                            <Select
                              defaultValue={id}
                              onChange={setId}
                              options={industryOptions}
                              placeholder="Select Industry"
                              maxMenuHeight="200px" // Set the minimum height here
                              // minMenuHeight="180px"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  width: '250px' // Set the width here
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="wrapper">
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ display: 'flex' }}
                                className="tablehead"
                              >
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('industry_type', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'industry_type' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Industry Name
                              </th>{' '}
                              <th>
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('industry_type', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'industry_type' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Active/Not-Active
                              </th>{' '}
                              {type === 'superadmin' ||
                              permissions?.Manage_Industry?.includes('view') ||
                              permissions?.Manage_Industry?.includes(
                                'update'
                              ) ||
                              permissions?.Manage_Industry?.includes(
                                'delete'
                              ) ? (
                                <th style={{ textAlign: 'center' }}>Action</th>
                              ) : null}
                            </tr>
                          </thead>

                          <tbody>
                            {industryData.length == 0 ? (
                              <tr className="text-center">
                                <td
                                  colSpan="9"
                                  style={{ fontSize: 16, marginTop: '10px' }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {industryData.map((e) => {
                                  return (
                                    <tr>
                                      <td>
                                        {e.industry_type ? e.industry_type : ''}
                                      </td>
                                      <td>
                                        {e.enabled === 1
                                          ? 'Active'
                                          : 'Not-Active'}
                                      </td>
                                      {type === 'superadmin' ||
                                      permissions?.Manage_Industry?.includes(
                                        'view'
                                      ) ||
                                      permissions?.Manage_Industry?.includes(
                                        'update'
                                      ) ||
                                      permissions?.Manage_Industry?.includes(
                                        'delete'
                                      ) ? (
                                        <td>
                                          <div
                                            className="col-12"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-evenly'
                                            }}
                                          >
                                            {type === 'superadmin' ||
                                            permissions?.Manage_Industry?.includes(
                                              'view'
                                            ) ? (
                                              <Link
                                                to={'/viewindustry/' + e.id}
                                                className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.Manage_Industry?.includes(
                                              'update'
                                            ) ? (
                                              <Link
                                                to={'/manageindustry/' + e.id}
                                                className="nav-icon fas fa-edit"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.Manage_Industry?.includes(
                                              'delete'
                                            ) ? (
                                              <i
                                                onClick={() => {
                                                  handleShow();
                                                  setBrandIdToDelete(e.id);
                                                }}
                                                className="nav-icon fas fa-times pointer_cursor"
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Industry</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this Industry?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Industry</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Industry Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default IndustryInfo;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

const SalesReportDetails = () => {
  const { leadId } = useParams();
  const { staffId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // const [staffId, setStaffId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [isChecked, setIsChecked] = useState(0);
  const [industry, setIndustry] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState();
  const token = useSelector((state) => state.token);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [gstError, setGstError] = useState('');
  const [finalCommunication, setFinalCommunication] = useState({});
  const [breadData, setBreadData] = useState([]);
  //dynamic input fields
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);

  const inputParam = inputFields.map((field, key) => ({
    [`contact_person_name_${key}`]: field.value1,
    [`contact_person_email_${key}`]: field.value2,
    [`contact_person_mobile_${key}`]: field.value3,
    [`contact_person_designation_${key}`]: field.value4
  }));
  const [productsData, setproductsData] = useState([]);
  const [leadData, setUpdateleadData] = useState('');
  const [communicationlead, setCommunicationlead] = useState([]);
  const [staffLists, setStaffLists] = useState([]);

  console.log(inputParam);

  const handleInputChange = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };
  // const handleAddFields = () => {
  //   const currentInputValues = inputFields[inputFields.length - 1];

  //   // Check if any field is empty
  //   const isEmpty = Object.values(currentInputValues).some(
  //     (value) => value.trim() === ''
  //   );
  //   if (isEmpty) {
  //     // Display an error message or take appropriate action to inform the user about empty input.
  //     alert('Please fill all fields before adding more.');
  //     return;
  //   }

  //   // Check if any combination is duplicate
  //   const isDuplicate = inputFields.some(
  //     (field) =>
  //       field.value1 === currentInputValues.value1 &&
  //       field.value2 === currentInputValues.value2 &&
  //       field.value3 === currentInputValues.value3 &&
  //       field.value4 === currentInputValues.value4
  //   );

  //   if (isDuplicate) {
  //     // Display an error message or take appropriate action to inform the user about the duplicate entry.
  //     alert(
  //       'Duplicate entry! Please enter unique details for each Contact Person.'
  //     );
  //     return;
  //   }

  //   setInputFields([
  //     ...inputFields,
  //     { value1: '', value2: '', value3: '', value4: '' }
  //   ]);
  // };

  const handleAddFields = () => {
    const currentInputValue1 = inputFields[inputFields.length - 1].value1;
    const isDuplicate = inputFields
      .slice(0, -1)
      .some((field) => field.value1 === currentInputValue1);
    const currentInputValue2 = inputFields[inputFields.length - 1].value2;
    const isDuplicate2 = inputFields
      .slice(0, -1)
      .some((field) => field.value2 === currentInputValue2);
    const currentInputValue3 = inputFields[inputFields.length - 1].value3;
    const isDuplicate3 = inputFields
      .slice(0, -1)
      .some((field) => field.value3 === currentInputValue3);
    const currentInputValue4 = inputFields[inputFields.length - 1].value4;
    const isDuplicate4 = inputFields
      .slice(0, -1)
      .some((field) => field.value4 === currentInputValue4);
    const currentInputValues = inputFields[inputFields.length - 1];

    // Check if any field is empty
    const isEmpty = Object.values(currentInputValues).some(
      (value) => value.trim() === ''
    );
    if (isEmpty) {
      // Display an error message or take appropriate action to inform the user about empty input.
      alert('Please fill all fields before adding more.');
      return;
    }
    // if (currentInputValue1.trim() === '') {
    //   // Display an error message or take appropriate action to inform the user about empty input.
    //   alert('Please enter a value for Contact Person before adding more.');
    // }
    else if (isDuplicate || isDuplicate2 || isDuplicate3 || isDuplicate4) {
      // Display an error message or take appropriate action to inform the user about the duplicate entry.
      alert('Duplicate entry! Please enter a unique name for Contact Person.');
    } else {
      setInputFields([
        ...inputFields,
        { value1: '', value2: '', value3: '', value4: '' }
      ]);
    }
  };

  // const handleAddFields = () => {
  //   // Check the total number of fields added
  //   if (inputFields.length < 3) {
  //     setInputFields([
  //       ...inputFields,
  //       { value1: '', value2: '', value3: '', value4: '' }
  //     ]);
  //   }
  // };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  // function for select staff

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  // funciton to get select industry type
  const IndustryChangeHandler = (event) => {
    setId2(event.target.value);
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffmanagerlist', config)
      .then(
        (value) => setStaffLists(value.data.response)

        // setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);
  // api function to get staff id
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'staffmanagerlist', config)
  //     .then((value) =>
  //     //  setStaffId(value.data.response))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   if (staffId) {
  //     setResponse(staffId);
  //   }
  // }, [staffId]);
  // function for store clients info

  // const submitHandler = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };

  //   const params = {
  //     clientInfo: inputParam,
  //     organisation: userData.organisation,
  //     address: userData.address,
  //     gst_no: userData.gst_no,
  //     notes: userData.notes,
  //     staff_id: id?.value,
  //     enabled: isChecked,
  //     industry_id: id2?.value
  //   };

  //   axios
  //     .post(Url.BASEURL + 'addclient', params, config)
  //     .then((value) => {
  //       if (value && value.data.status == 200) {
  //         openModal();
  //         // navigate('/listclients');
  //         // toast('Client added Successfully');
  //         setClientData(value.response);
  //         // setTimeout(() => {
  //         // }, 2000);
  //       } else {
  //         // setError(value.data.errors);
  //         handleServerError(value.data.errors);
  //         setHasError(true);

  //         console.log(gstError);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   // }
  // };
  useEffect(() => {
    LeadData();
  }, []);

  const LeadData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    // const params = {
    //   staff_id: staffId
    // };
    axios
      .get(Url.BASEURL + 'editlead/' + leadId + '?staff_id=' + staffId, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          setproductsData(value.data.response.lead.leadproducts);
          setUpdateleadData(value.data.response.lead);
          setClientData(value.data.response.lead.client_data.clientInfo[0]);
          setCommunicationlead(value.data.response.lead.leadcommunication);
          setId(value.data.response.lead.staff_id);
        } else {
          // setError(value.data.errors);
          handleServerError(value.data.errors);
          setHasError(true);
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  // Update error state based on server response
  const handleServerError = (serverErrors) => {
    setError((prevError) => ({
      ...prevError,
      ...serverErrors
    }));
  };

  // state for add user data
  const [userData, setUserData] = useState({
    gst_no: '',
    organisation: '',
    address: '',
    notes: ''
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('USERDATA>>>......', userData);
  };

  // function for selct/unselect active status of client

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listclients');
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'listindustry', config)
      .then((value) => setIndustry(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  console.log('-checkid', id);

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      staff_id: id.value,
      old_staff_id: staffId,
      lead_id: leadId
    };
    axios
      .put(Url.BASEURL + 'update-staff', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          closeModal();
          navigate('/sales_report')
        } else {
          closeModal();
          console.log(gstError);
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  // const transformDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, name, email }) => ({
  //     value: id.toString(),
  //     label: `${name} (${email})`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };

  // const options = transformDataToOptions(staffId);
  const transformIndustryToOptionsData = (data) => {
    // console.log('datadatatdatdtatata', data);
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type, email }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };

  const optionsData = transformIndustryToOptionsData(industry);
  // api for breadscrums
  useEffect(() => {
    axios
      .get(Url.BASEURL + 'breadcrumbs' + location.pathname)
      .then((value) => {
        setBreadData(value.data.breadcrumbs);
      })
      .catch((err) => console.log(err));
  }, []);
  const transformClientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));
    return [selectOption, ...dataOptions];
  };
  const staffOptions = transformClientDataToOptions(staffLists);

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  return (
    <Constant>
      <BreadScrum path="/listclients" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title> Re-assign Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Are You sure, You want to Re-assign this lead to selected staff ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={submitHandler}
              >
                YES
              </Button>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                NO
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Lead Details</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <p className="h5 m-0 px-1 py-2">
                          Client and Lead Details:
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 10
                          }}
                        >
                          <div style={{ marginRight: 10, fontWeight: 'bold' }}>
                            Assigned to:-
                          </div>
                          <Select
                            style={{ width: '200px' }}
                            // isDisabled
                            // defaultValue={id}
                            value={staffOptions.find((e) => e.value == id)}
                            // defaultValue={id}
                            onChange={(staffID) => {
                              setId(staffID);
                              handleModalOpen();
                            }}
                            options={staffOptions}
                            placeholder="Select Staff"
                            maxMenuHeight="200px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '250px' // Set the width here
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="inputs col-3">
                              <div className="form-group ">
                                <label htmlFor="exampleInputEmail1">
                                  <span className="required">GSTIN Number</span>
                                </label>
                                <input
                                  maxLength={15}
                                  onChange={handleInputChange1}
                                  // value={userData.gst_no}
                                  value={leadData?.client?.gst_no}
                                  name="gst_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter GSTIN Number"
                                  disabled
                                />
                                {error && error.gst_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.gst_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail2">
                                  <span className="required">Organisation</span>
                                </label>
                                <input
                                  onChange={handleInputChange1}
                                  value={leadData?.client?.organisation}
                                  // value={userData.organisation}
                                  name="organisation"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Enter Organisation"
                                  disabled
                                />
                                {error && error.organisation ? (
                                  <div style={{ color: 'red' }}>
                                    {error.organisation}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail3">
                                  <span className="required">Address</span>
                                </label>
                                <input
                                  onChange={handleInputChange1}
                                  value={leadData?.client?.address}
                                  name="address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Enter Address"
                                  disabled
                                />
                                {error && error.address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.address}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail4">
                                  <span className="required">
                                    Contact Person Name
                                  </span>
                                </label>
                                <input
                                  onChange={handleInputChange1}
                                  // value={userData.address}
                                  value={clientData?.contact_person_name_0}
                                  name="address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Enter Address"
                                  disabled
                                />
                                {error && error.industry_id ? (
                                  <div style={{ color: 'red' }}>
                                    {error.industry_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row row align-items-center justify-content-between inputrow">
                              <div className="inputs col-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail4">
                                    <span className="required">
                                      Contact Person Email
                                    </span>
                                  </label>
                                  <input
                                    maxLength={15}
                                    onChange={handleInputChange1}
                                    // value={userData.gst_no}
                                    value={clientData?.contact_person_email_0}
                                    name="gst_no"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Enter GSTIN Number"
                                    disabled
                                  />
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs col-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail4">
                                    <span className="required">
                                      Contact number
                                    </span>
                                  </label>
                                  <input
                                    maxLength={15}
                                    onChange={handleInputChange1}
                                    value={clientData?.contact_person_mobile_0}
                                    // value={userData.gst_no}
                                    name="gst_no"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Enter GSTIN Number"
                                    disabled
                                  />
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs col-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail4">
                                    <span className="required">Lead Stage</span>
                                  </label>
                                  <input
                                    onChange={handleInputChange1}
                                    value={leadData?.leadstage?.stage}
                                    // value={userData.address}
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail3"
                                    placeholder="Enter Address"
                                    disabled
                                  />
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs col-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail4">
                                    <span className="required">
                                      Expected Close Date
                                    </span>
                                  </label>
                                  <input
                                    onChange={handleInputChange1}
                                    // value={userData.address}
                                    value={leadData?.expt_close_date}
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail3"
                                    placeholder="Enter Address"
                                    disabled
                                  />
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span class="required">lead Title</span>
                            </label>
                            <textarea
                              type="text"
                              value={leadData?.title}
                              name="descriptipn"
                              class="form-control form-control-solid"
                              placeholder="Enter Description"
                              disabled
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {/* client Information */}
                      <p
                        className="h5 m-0 px-1 py-2 "
                        style={{ marginLeft: '24px' }}
                      >
                        Product Details:
                      </p>
                      <div
                        class="card card-flush box_shadow "
                        style={{ marginTop: '20px' }}
                      >
                        <div style={{ marginBottom: '10px', padding: '24px' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifycontent: 'space-between',
                              maxHeight: '200px',
                              overflow: 'auto'
                            }}
                          >
                            <table class="table">
                              <thead
                                style={{
                                  position: 'sticky',
                                  top: '0px',
                                  backgroundcolor: 'white',
                                  zindex: 1
                                }}
                              >
                                <tr>
                                  <th scope="col">Sr no.</th>
                                  <th scope="col">Product Name</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">SubCategory</th>
                                  <th scope="col">Totol quanity</th>
                                  <th scope="col">Price /pc </th>
                                  <th scope="col">Discount</th>
                                  <th scope="col">GST</th>
                                  <th scope="col">Net-price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productsData.length == 0 ? (
                                  <tr className="text-center">
                                    <td
                                      colSpan="9"
                                      style={{
                                        fontSize: 18,
                                        marginTop: '10px'
                                      }}
                                    >
                                      No Data found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {productsData.map((e, index) => {
                                      console.log('eeeeee', e, '000>>', index);
                                      if (Object.keys(e).length === 0) {
                                        return null; // Skip rendering the row
                                      }
                                      return (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>
                                            {e.product_name
                                              ? e.product_name
                                              : '------'}
                                          </td>
                                          <td>
                                            {e.category_name
                                              ? e.category_name
                                              : '------'}
                                          </td>
                                          <td>
                                            {e.sub_category_name
                                              ? e.sub_category_name
                                              : '------'}
                                          </td>
                                          <td>
                                            {e.total_quantity
                                              ? e.total_quantity
                                              : '------'}
                                          </td>
                                          {/* <td>120</td> */}
                                          <td>
                                            {e.product_price
                                              ? e.product_price
                                              : '------'}
                                          </td>
                                          {/* <td>24000</td> */}
                                          <td>{e.discount}%</td>
                                          <td>{e.product_gst}%</td>
                                          <td>
                                            {e.total_price
                                              ? e.total_price
                                              : '------'}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                                {/* <tr>
                                  <th scope="row">1</th>
                                  <td>GI PIPE</td>
                                  <td>65 NB</td>
                                  <td>HEAVY</td>
                                  <td>12.00</td>
                                  <td>886</td>
                                  <td>0%</td>
                                  <td>18%</td>
                                  <td>12546</td>
                                </tr>
                                <tr>
                                  <th scope="row">2</th>
                                  <td>GI PIPE</td>
                                  <td>25 NB</td>
                                  <td>MEDIUM</td>
                                  <td>48.00</td>
                                  <td>347</td>
                                  <td>0%</td>
                                  <td>18%</td>
                                  <td>19654</td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Lead Communication</p>
                      {communicationlead.map((data) => {
                        return (
                          <div
                            class="card"
                            // style={{ width: '18rem' }}
                            style={{
                              boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                              marginTop: '20px',
                              width: '20rem'
                            }}
                          >
                            {console.log('datatatattata', data)}
                            <div class="card-body">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <div>Title :-</div>
                                <div
                                  style={{ fontWeight: 'bold', marginLeft: 10 }}
                                >
                                  {' '}
                                  {data.communication}
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 10
                                }}
                              >
                                <div>Reminder date :-</div>
                                <div
                                  style={{ fontWeight: 'bold', marginLeft: 10 }}
                                >
                                  {data.communication_date}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <div
                        className="col-3 py-3"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px'
                          // marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            // onClick={submitHandler}
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            // style={{ width: "120px" }}
                            onClick={reloadPage}
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </Constant>
  );
};
export default SalesReportDetails;

import React, { useContext, useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import './ManageClient.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Modal, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

const ClientsInfo = () => {
  const location = useLocation();
  const [staffLists, setStaffLists] = useState([]);
  const [staffId, setStaffId] = useState('')
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientInfo, setIClientInfo] = useState([]);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDate2, setSelectedDate2] = useState();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const permissions = useSelector((state) => state.permissions);
  // setPermission(useSelector((state) => state.permissions));
  const type = useSelector((state) => state.type);
  console.log(
    'permission..................------->>>>>>',
    permissions,
    'type',
    type
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const transformClientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Organisation' };
    const dataOptions = data.map(({ id, organisation, contact_person_name_0, contact_person_email_0 }) => ({
      value: id.toString(),
      label: `${organisation}`
    }));
    return [selectOption, ...dataOptions];
  };
  const clientOptions = transformClientDataToOptions(clientLists);
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'client_name',
    orderby: 'desc'
  });
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const handleDateChange = (date) => {
    if (selectedDate2 && date >= selectedDate2) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate(date);
    }
  };
  const handleDateChange2 = (date) => {
    if (selectedDate && date <= selectedDate) {
      setSelectedDate2(null);
    } else {
      setSelectedDate2(date);
    }
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  // const StaffChangeHandler = (event) => {
  //   setId(event.target.value);
  // };

  // useEffect(() => {
  //   getBrandList();
  // }, [searchClient, itemOffset, deleteRow]);

  // function to get data from api
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,staffId,
    isArrowObj, pageCount
  ]);

  const getBrandList = () => {
    setLoading(true);
    console.log(">>>>>",staffId);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'listclients?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&client_id=' +
        (id !== '' ? '&client_id=' + id.value : '') +
        "&staff_id=" + 
        staffId +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2 +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,

        config
      )
      .then((value) => {
        if (value && value.data.status == 200) {
          setLoading(false);
          setClientData(value.data.response.data);
          setPageCount(value.data.response.last_page);
          setIClientInfo(value.data.response.data[0].clientInfo);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);


  //staff dropdown
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffsdropdown', config)
      .then(
        (value) =>
          setStaffLists(value.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  //staff option
  const transformStaffDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Staff' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));
    return [selectOption, ...dataOptions];
  };
  const staffOptions = transformStaffDataToOptions(staffLists);

  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  // function for delete row
  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deleteclient/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'clientsdropdown', config)
      .then(
        (value) =>
          setClientLists(value.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return format(parsedDate, 'dd MMM yyyy');
  };
  // BREADSCRUMS API
  const handleClearInput = () => {
    setSearchClient('');
  };
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <>
      <Constant>
        <BreadScrum
          btn_name="Add Client"
          routepath={'addclient'}
          // routepath={'manageclient/' + 0}
          heading={'Manage Client'}
          btn={'Add Clients'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' ||
              permissions?.Manage_Clients?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{
                        minHeight: isCalendarOpen ? '320px' : null,
                        overflow: 'hidden'
                      }}
                    >
                      {/* <div
                        className="search-field d-md-block"
                        style={{ marginBottom: '13px' }}
                      > */}
                      {/* <div className=" col-12"> */}
                      <div className="col-12 p-0 d-flex justify-content-between align-items-center flex-wrap mb-2">
                        <div className="col-lg-2 col-md-6 col-xs-12 datepicker p-0 ">
                          <label>Select Organisation:</label>
                          <Select
                            defaultValue={id}
                            onChange={setId}
                            options={clientOptions}
                            placeholder="Organisation"
                            maxMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '220px',
                                zIndex: 100
                              })
                            }}
                          />
                        </div>
                        <div className="col-lg-2 col-md-6 col-xs-12 datepicker p-0 ">
                          <label>Select Staff:</label>
                          <Select
                            defaultValue={staffId}
                            onChange={(e) => setStaffId(e.value)}
                            options={staffOptions}
                            placeholder="Staff"
                            maxMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '220px',
                                zIndex: 100
                              })
                            }}
                          />
                        </div>


                        <div className="col-lg-2 col-md-6 col-xs-12 datepicker p-0 ">
                          <label className="">From :</label>
                          <DatePicker
                            className="col-12 m-0 form-control"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select a date"
                            onKeyDown={(e) =>
                              e.key === 'Backspace' && e.preventDefault()
                            }
                            maxDate={new Date()}
                            isClearable={true}
                            yearDropdown
                            onCalendarOpen={toggleCalendar}
                            onCalendarClose={toggleCalendar}
                            scrollableYearDropdown
                            showYearDropdown={true}
                          />
                        </div>
                        <div
                          className="col-lg-2 col-md-6 col-xs-12 p-0 datepicker"
                        // style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <label className="">To :</label>
                          <DatePicker
                            className="col-12 m-0 form-control"
                            selected={selectedDate2}
                            onChange={handleDateChange2}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select a date"
                            onKeyDown={(e) =>
                              e.key === 'Backspace' && e.preventDefault()
                            }
                            maxDate={new Date()}
                            isClearable={true}
                            onCalendarOpen={toggleCalendar}
                            onCalendarClose={toggleCalendar}
                            yearDropdown
                            scrollableYearDropdown
                            showYearDropdown={true}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-xs-12 datepicker p-0 ">
                          <label>Search</label>
                          <input
                            // style={{
                            //   height: 30,
                            //   paddingRight: 30
                            // }}
                            onChange={(e) => {
                              setSearchClient(e.target.value);
                              handleKeyPress();
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchClient}
                          />
                          {/* Display the clear icon only when the input field has some value */}
                          {searchClient && (
                            <span
                              className="position-absolute top-50 end-0 fw-semibold"
                              style={{ cursor: 'pointer', marginRight: 10, marginTop: 5 }}
                              onClick={handleClearInput}
                            >
                              ✕
                            </span>
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                      <div className="wrapper">
                        {loading ? (
                          <div
                            className="spinner-container"
                            style={{ marginLeft: '500px' }}
                          >
                            <BounceLoader
                              color={'#123abc'}
                              loading={loading}
                              css={override}
                              size={60}
                            />
                          </div>
                        ) : (
                          <div className="table-wrapper">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            // style={{ fontSize: '14px' }}
                            >
                              <thead>
                                <tr>
                                  {/* <th className="tablehead">Sr. Number</th> */}

                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'organisation' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Organisation
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        style={{ color: 'grey' }}
                                        onClick={() =>
                                          handleArrowClick('client_email', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'client_email' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                      ></i>
                                    </span>
                                    Email
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('client_phone', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'client_phone' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Contact Number
                                  </th>

                                  <th className="tablehead">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('client_name', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'client_name' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Client Name
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('name', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'name' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Sales Staff
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('created_at', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'created_at' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Created At
                                  </th>

                                  <th className="tablehead">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('enabled', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'enabled' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span>
                                    Active/Not-Active
                                  </th>
                                  {type === 'superadmin' ||
                                    permissions?.Manage_Clients?.includes(
                                      'view'
                                    ) ||
                                    permissions?.Manage_Clients?.includes(
                                      'update'
                                    ) ||
                                    permissions?.Manage_Clients?.includes(
                                      'delete'
                                    ) ? (
                                    <th className="tablehead">Action</th>
                                  ) : null}
                                  {/* <th className="tablehead">Sr. Number</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {console.log('clientInfo', clientData)}
                                {clientData.length == 0 ? (
                                  <tr className="text-center">
                                    <td
                                      colSpan="9"
                                      style={{
                                        fontSize: 16,
                                        marginTop: '10px'
                                      }}
                                    >
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {clientData.map((e, index) => {
                                      return (
                                        <tr key={index} className="tablerow ">
                                          <td>
                                            {e.organisation
                                              ? e.organisation
                                              : ''}
                                          </td>
                                          {e.clientInfo[0] && (
                                            <>
                                              {/* <td>{index + 1}</td> */}

                                              <td>
                                                {e.clientInfo[0]
                                                  .contact_person_email_0 || ''}
                                              </td>
                                              <td>
                                                {e.clientInfo[0]
                                                  .contact_person_mobile_0 ||
                                                  ''}
                                              </td>
                                              <td>
                                                {e.clientInfo[0]
                                                  .contact_person_name_0 || ''}
                                              </td>
                                            </>
                                          )}

                                          <td>
                                            {e.staff && e.staff.name
                                              ? e.staff.name
                                              : ''}
                                          </td>
                                          <td>
                                            {e.created_at
                                              ? formatDate(e.created_at)
                                              : ''}
                                          </td>

                                          <td>
                                            {e.enabled === 1
                                              ? 'Active'
                                              : 'Not-Active'}
                                          </td>
                                          {type === 'superadmin' ||
                                            permissions?.Manage_Clients?.includes(
                                              'view'
                                            ) ||
                                            permissions?.Manage_Clients?.includes(
                                              'update'
                                            ) ||
                                            permissions?.Manage_Clients?.includes(
                                              'delete'
                                            ) ? (
                                            <td>
                                              <div className="actionstaff d-flex justify-content-between align-items-center m-auto">
                                                {type === 'superadmin' ||
                                                  permissions?.Manage_Clients?.includes(
                                                    'view'
                                                  ) ? (
                                                  <Link
                                                    to={
                                                      '/clientDetails/' + e.id
                                                    }
                                                    className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                                  ></Link>
                                                ) : null}
                                                {type === 'superadmin' ||
                                                  permissions?.Manage_Clients?.includes(
                                                    'update'
                                                  ) ? (
                                                  <Link
                                                    to={'/manageclient/' + e.id}
                                                    className="nav-icon fas fa-edit pointer_cursor d-block p-1"
                                                  ></Link>
                                                ) : null}
                                                {type === 'superadmin' ||
                                                  permissions?.Manage_Clients?.includes(
                                                    'delete'
                                                  ) ? (
                                                  <i
                                                    onClick={() => {
                                                      handleShow();
                                                      setBrandIdToDelete(e.id);
                                                    }}
                                                    className="nav-icon fas fa-times pointer_cursor d-block p-1"
                                                  />
                                                ) : null}

                                                {/* {type == 'superadmin' ? (
                                                <Link
                                                  to={'/manageclient/' + e.id}
                                                  className="nav-icon fas fa-edit pointer_cursor d-block p-1"
                                                ></Link>
                                              ) : permissions?.Manage_Clients?.inlcudes(
                                                  'update'
                                                ) ? (
                                                <Link
                                                  to={'/manageclient/' + e.id}
                                                  className="nav-icon fas fa-edit pointer_cursor d-block p-1"
                                                ></Link>
                                              ) : null}
                                              {type == 'superadmin' ? (
                                                <i
                                                  onClick={() => {
                                                    handleShow();
                                                    setBrandIdToDelete(e.id);
                                                  }}
                                                  className="nav-icon fas fa-times pointer_cursor d-block p-1"
                                                />
                                              ) : permissions?.Manage_Clients?.inlcudes(
                                                  'delete'
                                                ) ? (
                                                <i
                                                  onClick={() => {
                                                    handleShow();
                                                    setBrandIdToDelete(e.id);
                                                  }}
                                                  className="nav-icon fas fa-times pointer_cursor d-block p-1"
                                                />
                                              ) : null} */}
                                              </div>
                                            </td>
                                          ) : null}
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this Client?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Client Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ClientsInfo;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import { Brand } from '../Admin/Data';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

import axios from 'axios';
const AddProducts = () => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [categoryList, setCategorylist] = useState([]);
  const [brandList, setBrandlist] = useState([]);
  const [error, setError] = useState('');
  const [addRow, setAddRow] = useState([]);
  const [categorId, setCategoryId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [hsnId, setHsnId] = useState('');

  const [measurementId, setmeasurementId] = useState('');
  const [measurementList, setMeasuermentlist] = useState([]);
  const [labelText, setLabelText] = useState('New');
  const [productsListing, setProductsListing] = useState([]);
  const [hsnCode, setHsncode] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  const [hsnLabelText, setHsnLabelText] = useState('New');
  const [basePriceLabelText, setBasePriceLabelText] = useState('New');
  const [brandLabelText, setBrandLabelText] = useState('New');
  const [productId, setproductId] = useState('');
  const [basePriceId, setBasePriceId] = useState([]);
  const [productExistingError, SetProductExistingError] = useState('');
  const [show, setShow] = useState(false);

  // const [myCategory, setMyCategoryListing] = useState([]);

  // console.log(
  //   'sdnnsdnsdlnbrandIdbrandIdbrandIdbrandIdbrandIdbrandId-------->>>',
  //   myCategory
  // );
  const [userData, setUserData] = useState({
    pname: '',
    gst: '18%',
    base_price: '',
    brand: '',
    hsncode: ''
  });
  const [measurementType, setMeasurementType] = useState('centimeter');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);

  console.log('dsasduserDatauserData', userData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };
  // product name textlabel change function
  const toggleLabel = () => {
    if (labelText === 'New') {
      setLabelText('Existing');
      setSelectedParentCategory('');
      setSelectedSubcategory('');
      setSubcategoryOptions([]);
    } else {
      setLabelText('New');
      setSelectedParentCategory('');
      setSelectedSubcategory('');
      setSubcategoryOptions([]);
    }
  };
  // hsn code textlabel change function

  const toggleHsnLabel = () => {
    if (hsnLabelText === 'New') {
      setHsnLabelText('Existing');
    } else {
      setHsnLabelText('New');
    }
  };
  // base price textlabel change function
  const toggleBasePriceLabel = () => {
    if (basePriceLabelText === 'New') {
      setBasePriceLabelText('Existing');
    } else {
      setBasePriceLabelText('New');
    }
  };

  // brand textlabel change fucniton
  const toggleBrandLabel = () => {
    if (brandLabelText === 'New') {
      setBrandLabelText('Existing');
    } else {
      setBrandLabelText('New');
    }
  };
  // fetching category listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'allcategories?',

        config
      )
      .then((value) => setCategorylist(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // api for products listing

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'create-product?', config)
      .then((value) => {
        console.log('value?.datavalue?.data------>>>', value?.data.response);
        if (value.data.status === 200) {
          setProductsListing(value?.data?.response.product_list);
          setHsncode(value.data.response.hns_code);
          setProductCategory(value.data.response.category);
          setBrandlist(value.data.response.brands);
          // setMyCategoryListing(value.data.response.category);
          // setMyCategories(value?.data?.response.category);
          // setMyCategoryListing(value?.data?.response?.brands);
        } else {
          setError(value.data.response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // fetching measurement type list
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'listmeasurement?',

        config
      )
      .then((value) => setMeasuermentlist(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);
  // fetching brand listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'listbrand?',

        config
      )
      .then(
        (value) => {}
        // setBrandlist(value.data.response.data)
      )
      .catch((err) => console.log(err));
  }, []);
  // submithandler to add product

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      product_name:
        labelText === 'Existing' ? userData.pname : productId?.label,
      brand_name: '',
      category_name: categorId.value,
      class_thickness: '',
      hsn_code: '',
      base_price: '',
      gst: userData.gst
    };
    axios
      .post(Url.BASEURL + 'addproduct', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/listproducts');
          toast('Product added Successfully');
          setAddRow(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  // FUNCTION TO ADD PRODUCT

  // const MeasurementChangeHandler = (event) => {
  //   setId(event.target.value);
  // };

  // function to add category
  const CategoryChangeHandler = (event) => {
    setCategoryId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // function to add brand

  const BrandChangeHandler = (event) => {
    setBrandId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // functon to change measurement type
  const measurementChangeHandler = (event) => {
    setmeasurementId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // function for close modal
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    navigate('/listproducts');
  }
  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listproducts');
  };

  console.log('categoryListcategoryList,categoryList', measurementList);

  const transformCategoryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(
      ({ category_id, parent_categories_name, email }) => ({
        value: category_id.toString(),
        label: `${parent_categories_name}`
      })
    );

    return [selectOption, ...dataOptions];
  };

  const options = transformCategoryDataToOptions(categoryList);

  const transformBrandDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    // const select1Option = { value: 'unbranded', label: 'unbranded' };

    const dataOptions = data.map(({ id, bname, email }) => ({
      value: id.toString(),
      label: `${bname}`
    }));

    return [selectOption, ...dataOptions];
  };

  const brandOptions = transformBrandDataToOptions(brandList);

  // react_select for products name

  const transformProductDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, product_name }) => ({
      value: id.toString(),
      label: `${product_name}`
    }));

    return [selectOption, ...dataOptions];
  };
  const productOption = transformProductDataToOptions(productsListing);

  // hsn code dropdown function
  const transformHsncodeToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, code }) => ({
      value: id.toString(),
      label: `${code}`
    }));

    return [selectOption, ...dataOptions];
  };
  const hsncodeOption = transformHsncodeToOptions(hsnCode);
  // console.log('hsnCodehsnCodehsnCode', hsnCode);
  // brand dropdown function
  // const transformBrandToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, brands }) => ({
  //     value: id.toString(),
  //     label: `${brands}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const brandOption = transformBrandToOptions(productCategory);

  // const transformMeasureDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, name }) => ({
  //     value: id.toString(),
  //     label: `${name}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // // function for close modal
  // function openModal() {
  //   setIsOpen(true);
  // }
  // function closeModal() {
  //   navigate('/listproducts');
  // }

  // const measureOptions = transformMeasureDataToOptions(measurementList);

  //function for category dropdown

  // const [selectedParentCategory, setSelectedParentCategory] = useState('');
  // const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  // useEffect(() => {
  //   // Filter subcategories based on the selected parent category
  //   if (selectedParentCategory) {
  //     const selectedCategory = productCategory.find(
  //       (category) => category.id.toString() === selectedParentCategory
  //     );
  //     const subcategoryData = selectedCategory?.sub_category || [];
  //     const subcategoryOptions = transformCategoryToOptions(subcategoryData);
  //     setSubcategoryOptions(subcategoryOptions);
  //   } else {
  //     // If no parent category is selected, clear the subcategory options
  //     setSubcategoryOptions([]);
  //   }
  // }, [selectedParentCategory, productCategory]);

  // const handleParentCategoryChange = (selectedOption) => {
  //   setSelectedParentCategory(selectedOption.value);
  // };
  // const transformCategoryToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, name }) => ({
  //     value: id.toString(),
  //     label: `${name}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const categoryOption = transformCategoryToOptions(productCategory);

  //

  const [selectedParentCategory, setSelectedParentCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [basePrice, setBasePrice] = useState('');
  const [productAlreadyExistModal,setProductAlreadyExistModal]  = useState(false)

  const handleProductChange = (selectedOption) => {
    // Clear the states when a new product is selected
    setSelectedParentCategory('');
    setSelectedSubcategory('');
    setSubcategoryOptions([]);

    // Set the selected product
    setproductId(selectedOption);
  };
  useEffect(() => {
    // Filter subcategories based on the selected parent category
    if (selectedParentCategory) {
      const selectedCategory = productCategory.find(
        (category) => category.id.toString() === selectedParentCategory
      );
      const subcategoryData = selectedCategory?.sub_category || [];
      const subcategoryOptions = transformCategoryToOptions(subcategoryData);
      setSubcategoryOptions(subcategoryOptions);
    } else {
      // If no parent category is selected, clear the subcategory options
      setSubcategoryOptions([]);
      // Also clear the selected subcategory and base price when the parent category changes
      setSelectedSubcategory('');
      setBasePrice('');
    }
  }, [selectedParentCategory, productCategory]);

  useEffect(() => {
    // Update the base price when the selected subcategory changes
    if (selectedSubcategory) {
      // setBasePrice(selectedSubcategory.base_price);
    } else {
      // If no subcategory is selected, clear the base price
      setBasePrice('');
    }
  }, [selectedSubcategory]);

  const handleParentCategoryChange = (selectedOption) => {
    console.log('Selected Parent Category:', selectedOption);
    setSelectedParentCategory(selectedOption.value);
    setSelectedSubcategory('');
  };

  const handleSubcategoryChange = (selectedOption) => {
    console.log('Selected Subcategory:', selectedOption);
    setSelectedSubcategory(selectedOption);
  };

  const transformCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, base_price }) => ({
      value: id.toString(),
      label: `${name}`,
      base_price: base_price ? base_price.price : null
    }));

    return [selectOption, ...dataOptions];
  };

  const categoryOption = transformCategoryToOptions(productCategory);

  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };

  console.log(
    'hsnId',
    hsnId,
    'productId',
    productId,
    'brandId',
    brandId,
    'selectedParentCategory',
    selectedParentCategory,
    'selectedSubcategory',
    selectedSubcategory,
    'basePrice',
    basePrice
  );

  // const transformBasePriceDataToOptions = (data) => {
  //     const selectOption = { value: '', label: 'Select' };
  //     const dataOptions = data.map(({ base_price }) => ({
  //       value: base_price.toString(),
  //       label: `${base_price}`
  //     }));

  //     return [selectOption, ...dataOptions];
  //   };

  //   const basePriceOptions = transformBasePriceDataToOptions(productsListing);

  // const transformBasePriceDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, base_price }) => ({
  //     value: id.toString(),
  //     label: `${base_price}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const basePriceOptions = transformBasePriceDataToOptions(productsListing);

  // api function for base price
  useEffect(() => {
    if (selectedParentCategory && selectedSubcategory && productId) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
      axios
        .get(
          Url.BASEURL +
            'base-price?' +
            'category_id=' +
            selectedParentCategory +
            '&sub_category_id=' +
            selectedSubcategory.value +
            // (id !== '' ? '&client_id=' + id.value : '') +
            '&product_id=' +
            productId.value,

          config
        )
        .then((value) => {
          // if (value && value.data.status == 200) {
          //   console.log(
          //     '------------------------------->>>>>>data',
          //     value.data.response.base_price
          //   );
          console.log(
            'value.data.response.base_price',
            value.data.response.base_price
          );
          if (value.data.response.base_price !== null) {
            setBasePrice(value.data.response.base_price.base_price);
          } else {
            setBasePrice('0');
          }
          // }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedParentCategory, selectedSubcategory, productId]);

  // Api function for add products
  const AddProducts = () => {
    const formData = new FormData();
    formData.append('brand_id', brandId ? brandId.value : userData.brand);
    formData.append(
      'product_name',
      productId ? productId.label : userData.pname
    );
    formData.append('category_id', selectedParentCategory);
    formData.append('sub_category_id', selectedSubcategory.value);
    formData.append('hsn_code', hsnId ? hsnId.label : userData.hsncode);
    formData.append('base_price', basePrice);
    formData.append('gst', userData.gst);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .post(Url.BASEURL + 'store-new-product', formData, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // navigate('/listproducts');
          setShow(true);
          // toast('Product added Successfully');
          // setAddRow(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          closeAddModal();
          setProductAlreadyExistModal(true);
          // setError(value.data.errors);
          SetProductExistingError(value.data.response);

          console.log('errorrrrrr>>>>>', value.data.errors);
        }
      })
      .catch((err) => {console.log(err)
        setProductAlreadyExistModal(true);
      });
  };

  function closeAlreadyExistModal() {
    setProductAlreadyExistModal(false);
  }
  return (
    <Constant>
      <BreadScrum path="/listproducts" />{' '}
      <div className="wrapper">
        <div>
          {/* <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Client Added</Modal.Title>
            </Modal.Header>
            <Modal.Body>Client has been added successfully</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={closeModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal> */}
          <Modal
            show={addModal}
            onHide={closeAddModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Product Add</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Are you sure, You want to add this Product?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={AddProducts}
              >
                Yes
              </Button>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={() => closeAddModal(false)}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* <div>
          <Modal
            show={productAlreadyExistModal}
            onHide={closeAlreadyExistModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>Product already Exist</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={closeAlreadyExistModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div> */}
        <Modal
          show={productAlreadyExistModal}
          onHide={closeAlreadyExistModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
          Product already Exist
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={closeAlreadyExistModal}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  {/* {productExistingError && productExistingError ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '20px',
                        textAlign: 'center'
                      }}
                    >
                      {productExistingError}
                    </div>
                  ) : null} */}
                  {/* {console.log('productexisting', productExistingError)} */}
                  <div className="card-header">
                    <h3 className="card-title">Product Information</h3>
                  </div>

                  {/* form start */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              HSN Code
                            </label>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleHsnLabel}
                            >
                              {hsnLabelText}
                            </div>
                          </div>

                          {hsnLabelText === 'Existing' ? (
                            <input
                              onChange={handleInputChange}
                              value={userData.hsncode}
                              name="hsncode"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter product's HSN code."
                            />
                          ) : (
                            <Select
                              defaultValue={hsnId}
                              onChange={setHsnId}
                              options={hsncodeOption}
                              placeholder="Select HSN code"
                            />
                          )}
                          {error && error.hsn_code ? (
                            <div style={{ color: 'red' }}>{error.hsn_code}</div>
                          ) : null}
                          {/* {error ? (
                              <div
                                style={{
                                  // textAlign: 'center',
                                  // margin: '10px 369px',
                                  // background: '#FFCCCB',
                                  color: 'red'
                                }}
                                className="alert "
                                role="alert"
                              >
                                {error.brand_id}
                              </div>
                            ) : null}{' '} */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Product Name
                            </label>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleLabel}
                            >
                              {labelText}
                            </div>
                          </div>
                          {labelText === 'Existing' ? (
                            <input
                              onChange={handleInputChange}
                              value={userData.pname}
                              name="pname"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter product namessss (e.g.,Steel Bars)."
                            />
                          ) : (
                            <Select
                              defaultValue={productId}
                              // onChange={setproductId}
                              onChange={handleProductChange}
                              options={productOption}
                              placeholder="Select product"
                            />
                          )}
                          {error && error.product_name ? (
                            <div style={{ color: 'red' }}>
                              {error.product_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">GST</label>
                          <input
                            onChange={handleInputChange}
                            maxLength={3}
                            value={userData.gst}
                            name="gst"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter GST (e.g.,18)."
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <label
                            style={{
                              // display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            htmlFor="exampleInputEmail1"
                          >
                            Brand Name
                          </label>
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={toggleBrandLabel}
                          >
                            {/* {brandLabelText} */}
                          </div>
                        </div>
                        {/* {brandLabelText === 'Existing' ? (
                          <input
                            onChange={handleInputChange}
                            value={userData.brand}
                            name="brand"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Brand Name ."
                          />
                        ) : ( */}
                        <Select
                          defaultValue={brandId}
                          onChange={setBrandId}
                          options={brandOptions}
                          placeholder="Select brand"
                        />
                        {error && error.brand_id ? (
                          <div style={{ color: 'red' }}>{error.brand_id}</div>
                        ) : null}
                        {/* )} */}
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Base Price
                            </label>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleBasePriceLabel}
                            >
                              {basePriceLabelText}
                            </div>
                          </div>
                          {basePriceLabelText === 'Existing' ? (
                            <input
                              onChange={handleInputChange}
                              value={userData.base_price}
                              name="base_price"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter price ."
                            />
                          ) : (
                            <Select
                              defaultValue={productId}
                              onChange={setproductId}
                              options={productOption}
                              placeholder="Select price"
                            />
                          )}
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Category</label>
                          {/* <Select
                            // defaultValue={categorId}
                            // onChange={setCategoryId}
                            // options={categoryOption}
                            options={categoryOption}
                            onChange={handleParentCategoryChange}
                            value={
                              selectedParentCategory
                                ? {
                                    value: selectedParentCategory,
                                    label: productCategory.find(
                                      (category) =>
                                        category.id.toString() ===
                                        selectedParentCategory
                                    )?.name
                                  }
                                : null
                            }
                            placeholder="Select Category"
                          /> */}
                          <Select
                            value={
                              selectedParentCategory
                                ? {
                                    value: selectedParentCategory,
                                    label: productCategory.find(
                                      (category) =>
                                        category.id.toString() ===
                                        selectedParentCategory
                                    )?.name
                                  }
                                : null
                            }
                            // value={selectedParentCategory}
                            onChange={handleParentCategoryChange}
                            options={categoryOption}
                            placeholder="Select parent category"
                          />
                          {error && error.category_id ? (
                            <div style={{ color: 'red' }}>
                              {error.category_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Sub Category
                            </label>
                            {/* <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleBrandLabel}
                            >
                              {brandLabelText}
                            </div> */}
                          </div>
                          {/* {brandLabelText === 'Existing' ? (
                            <input
                              onChange={handleInputChange}
                              value={userData.brand}
                              name="brand"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Brand Name ."
                            />
                          ) : ( */}
                          {/* <Select
                            defaultValue={brandId}
                            onChange={setBrandId}
                            options={subcategoryOptions}
                            placeholder="Select Sub Category"
                          /> */}

                          <Select
                            value={selectedSubcategory}
                            onChange={handleSubcategoryChange}
                            options={subcategoryOptions}
                            placeholder="Select subcategory"
                          />
                          {error && error.sub_category_id ? (
                            <div style={{ color: 'red' }}>
                              {error.sub_category_id}
                            </div>
                          ) : null}
                          {/* )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Base Price
                            </label>

                            {/* <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleBasePriceLabel}
                            >
                              {basePriceLabelText}
                            </div> */}
                          </div>
                          {/* {basePriceLabelText === 'Existing' ? (
                            <input
                              onChange={handleInputChange}
                              value={userData.base_price}
                              name="base_price"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter price ."
                            />
                          ) : (
                            <Select
                              defaultValue={basePriceId}
                              onChange={setBasePriceId}
                              options={basePriceOptions}
                              placeholder="Select price"
                            />
                          )} */}

                          {/* <input
        type="text"
        value={basePrice}
        placeholder="Base Price"
        readOnly
      /> */}
                          {console.log(
                            'basePricebasePricebasePricebasePricebasePrice'
                          )}
                          <input
                            // onChange={handleInputChange}
                            // value={basePrice? basePrice : userData.base_price}
                            onChange={handleBasePriceInputChange}
                            value={
                              basePrice == null
                                ? '0'
                                : basePrice !== null
                                ? basePrice
                                : userData.base_price
                            }
                            name="base_price"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter price ."
                          />
                          {error && error.base_price ? (
                            <div style={{ color: 'red' }}>
                              {error.base_price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                  <div className="row">
                    <div
                      className="col-4"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginLeft: '10px',
                        marginBottom: '10px'
                      }}
                    >
                      <div className="">
                        <button
                          onClick={openAddModal}
                          // className="btn btn-primary"
                          className="btn text-white"
                          style={{ backgroundColor: '#1e336a' }}
                        >
                          Add
                        </button>
                      </div>
                      <div className="" style={{ marginLeft: '20px' }}>
                        <button
                          onClick={reloadPage}
                          className="btn text-black"
                          style={{ backgroundColor: '#DCDCDC' }}
                          // className="btn btn-primary"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop={'static'}
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: '#1e336a', color: 'white', border: 'none' }}
        >
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '20px' }}>
          Product Added Successfully
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#1E3F6A', border: 'none' }}
            onClick={() => {
              setShow(false);
              navigate('/listproducts');
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Constant>
  );
};
export default AddProducts;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { departments } from '../Admin/Data';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Url from '../Element/Api';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
const AdminRights = () => {
  const [adminrights, setAdminRights] = useState([]);
  const [id, setId] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);
  const token = useSelector((state) => state.token);
  const [searchDesignation, setSearchDesignation] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchDesignationByName, setSearhDesignationByName] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [searchBrand, setSearchBrand] = useState('');
  const permissions = useSelector((state) => state.permissions);
  const [isArrowObj, setIsArrowObj] = useState({
    column: '',
    orderby: ''
  });
  // setPermission(useSelector((state) => state.permissions));
  const type = useSelector((state) => state.type);
  console.log(
    'permission..................------->>>>>>',
    permissions,
    'type',
    type
  );
  // useEffect(() => {
  //   GetRolesListDropdown();
  //   GetRolesList();
  // }, [searchDesignation, itemOffset, deleteRow, id, searchBrand]);
  useEffect(() => {
    GetRolesListDropdown();
    GetRolesList();
  }, [searchBrand, id, itemOffset, isArrowObj,pageCount]);
  // const GetRolesList = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(
  //       Url.BASEURL +
  //         'getroles?' +
  //         '&search=' +
  //         searchBrand +
  //         '&page=' +
  //         itemOffset +
  //         (id !== '' ? '&role_id=' + id.value : ''),
  //       config
  //     )
  //     .then((value) => {
  //       if (value && value.data.status === 200) {
  //         // toast('Client update Successfully');
  //         console.log('value.data.response==>>', value);
  //         setAdminRights(value.data.response.data);
  //         setPageCount(value.data.response.last_page);
  //         // navigate('/listclients');
  //         // setTimeout(() => {
  //         //
  //         // }, 2000);
  //       } else {
  //         // setError(value.data.errors);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  const GetRolesList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'getroles?' +
          '&search=' +
          searchBrand +
          (id !== '' ? '&role_id=' + id.value : '') +
          '&page=' +
          itemOffset +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value);
          setAdminRights(value.data.response.data);
          setPageCount(value.data.response.last_page);
          // setDepartmentLists(value.data.response.data);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  const GetRolesListDropdown = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'listDepartmentsall?', config)
      .then((value) => {
        // toast('Client update Successfully');
        console.log('value.data.response==>>', value);
        setDropdownData(value.data.response);
      })
      .catch((err) => console.log(err));
  };
  // function for delete row
  const deleteRowHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandIdToDelete
    };
    axios
      .delete(
        Url.BASEURL + 'deleteRoleAndPermissions/' + brandIdToDelete,
        config,
        params
      )
      .then((value) => {
        GetRolesList();
        setDeleteRow(value.data.response);
      })
      .catch((err) => console.log(err));
  };
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);
    setItemOffset(newOffset);
  };
  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhDesignationByName(searchDesignationByName);
  };
  const transformDepartmentDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Department' };
    const dataOptions = data.map(({ id, role_name }) => ({
      value: id.toString(),
      label: `${role_name}`
    }));
    return [selectOption, ...dataOptions];
  };
  const departmentOptions = transformDepartmentDataToOptions(dropdownData);
  const handleClearInput = () => {
    setSearchBrand('');
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  return (
    <>
      <Constant>
        <BreadScrum
          routepath={'add_admin_rights'}
          heading={'Manage Departments'}
          btn={'Add Department & Permissions'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' || permissions?.User_Rights?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div className="mb-3">
                        <div
                          className=""
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            className="d-flex align-items-center h-100 searchbar_parent"
                            action="#"
                          >
                            <div
                              className=" position-relative"
                              style={{
                                borderWidth: 1,
                                borderRadius: 5,
                                border: '1px solid black'
                              }}
                            >
                              <div className="input-group-prepend bg-transparent"></div>
                              <input
                                style={{
                                  height: 30,
                                  paddingRight: 30
                                }}
                                onChange={(e) =>
                                  setSearchBrand(e.target.value) &
                                  handleKeyPress()
                                }
                                type="text"
                                className="form-control bg-transparent border-0"
                                placeholder="Search"
                                value={searchBrand}
                              />
                              {searchBrand && (
                                <span
                                  className="position-absolute top-50 end-0 translate-middle-y"
                                  style={{ cursor: 'pointer', marginRight: 10 }}
                                  onClick={handleClearInput}
                                >
                                  ✕
                                </span>
                              )}
                            </div>
                          </div>
                          <Select
                            defaultValue={id}
                            onChange={setId}
                            options={departmentOptions}
                            placeholder="Select Department"
                            maxMenuHeight="200px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '250px' // Set the width here
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="wrapper">
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              {/* <th style={{ textAlign: 'center' }}>Sr.No</th> */}
                              <th
                                className="tablehead"
                                style={{ display: 'flex' }}
                              >
                                {' '}
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('role_name', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'role_name' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Departments
                              </th>
                              {type === 'superadmin' ||
                              permissions?.User_Rights?.includes('view') ||
                              permissions?.User_Rights?.includes('update') ||
                              permissions?.User_Rights?.includes('delete') ? (
                                <th style={{ textAlign: 'center' }}>Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {adminrights.length == 0 ? (
                              <tr className="text-center">
                                <td
                                  colSpan="9"
                                  style={{ fontSize: 16, marginTop: '10px' }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {adminrights.map((e, index) => {
                                  return (
                                    <tr>
                                      {/* <td style={{ textAlign: 'center' }}>
                                        {index + 1}
                                      </td> */}
                                      <td style={{}}>{e.role_name}</td>
                                      {type === 'superadmin' ||
                                      permissions?.User_Rights?.includes(
                                        'view'
                                      ) ||
                                      permissions?.User_Rights?.includes(
                                        'update'
                                      ) ||
                                      permissions?.User_Rights?.includes(
                                        'delete'
                                      ) ? (
                                        <td style={{}}>
                                          <div className="d-flex justify-content-evenly align-items-center fs-5">
                                            {type === 'superadmin' ||
                                            permissions?.User_Rights?.includes(
                                              'update'
                                            ) ? (
                                              <Link
                                                to={
                                                  '/manage_user_rights/' + e.id
                                                }
                                                className="nav-icon fas fa-edit d-block"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.User_Rights?.includes(
                                              'delete'
                                            ) ? (
                                              <i
                                                onClick={() => {
                                                  handleShow();
                                                  setBrandIdToDelete(e.id);
                                                }}
                                                className="nav-icon fas fa-times pointer_cursor d-block"
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Department</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this Department?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Department</Modal.Title>
          </Modal.Header>
          <Modal.Body>Department Deleted Sucessfully.</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose2}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default AdminRights;

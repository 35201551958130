import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Url from '../Element/Api';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import {
  setToken,
  setPermissions,
  setRole,
  setType,
  setpasswordChangeToken
} from '../Redux/Action/authaction';
const override = css`
display: block;
margin: 0 auto;
`;
const Otp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginResponse, setLoginResponse] = useState([]);
  // const [permission,setPermissions] =useState([]);
  // const [role,setRole] =useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checknew = useSelector((e) => console.log('eeeeeeeee', e));
  // dispatch(permission)
  // console.log('permission..............................',permission);
  const savedEmail = useSelector((state) => state.email);

  const submitHandle = (e) => {
    // navigate('/new_password');
    // if (otp) {
    setLoading(true)

    const params = {
      email: savedEmail,
      otp: otp
    };
    axios
      .post(Url.BASEURL + 'forgot-password/verify-otp-admin', params)
      .then((value) => {
        if (value.data.status === 200) {
          setLoading(false)
          dispatch(setpasswordChangeToken(value.data.data.token));
          navigate('/new_password');
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(err.response.data.message)
      }
      );
    // }
  };

  return (
    <>
      {loading &&
        <div className="spinner-back">
          <div className="spinner-cont">
            <BounceLoader color={'#123abc'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }
      <div className="hold-transition login-page">
        <img
          src="dist/img/symang_logo_za.png"
          alt="AdminLTE Logo"
          // className="brand-image img-circle elevation-3"
          style={{
            width: '224px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            // marginLeft: '133px',
            marginBottom: '20px'
          }}
        //   style={{ opacity: '.8' }}
        />{' '}
        <div className="login-box">
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Complete Authentication: Enter OTP</p>

              <div>
                <div className="input-group mb-1">
                  <input
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^0-9]/g,
                      ''
                    ))
                    }
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter OTP"
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>

                </div>
                {error ? (
                  <div
                    className='mt-1 ms-1 fw-normal mb-3 text-danger'
                  // role="alert"
                  >
                    {error}
                  </div>
                ) : null}

                <div className="row">
                  {/* /.col */}
                  <div className="col-6">
                    <p className="mb-1">
                      <Link to="/">Back to Login</Link>
                    </p>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={submitHandle}
                      // type="submit"
                      className="btn btn-primary btn-block"
                    >
                      Sumbit
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </div>
              {/* /.social-auth-links */}
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    </>

  );
};
export default Otp;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Url from '../Element/Api';
import { Button, Modal } from 'react-bootstrap';
const ManageCategory = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState({});
  const token = useSelector((state) => state.token);
  const [error, setError] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [updateCategory, setUpdatecategory] = useState('');
  const [id, setId] = useState('');
  const [isChecked, setIsChecked] = useState('');
  const [userData, setUserData] = useState({
    pname: '',
    category: ''
  });
  const { parentCategoryId } = useParams();
  

  let { categoryId, subCategoryId } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);

  console.log('dsasd---->>>', categoryId, subCategoryId);
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log('e.target',e.target);
  //   // setCategoryData({
  //   //   ...categoryData,
  //   //   [name]: value
  //   // });
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategoryData({ ...categoryData, [name]: value });
    console.log('formState------------>>>>>>', value);
  };

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'allcategory', config)
      .then((value) => {
        setCategory(value.data.response);
        // setTimeout(() => {
        // }, 2000);
      })
      .catch((err) => console.log(err));
  }, []);

  // function to edit category

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'editcategory/' + categoryId + '/' + subCategoryId,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value.data.response);
          setIsChecked(value.data.response[0].enabled);

          setCategoryData(value.data.response[0]);

          setId(value.data.response[0].parent_category);
          console.log('ischecke', isChecked);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  console.log('value.data.categoryData==>>', categoryData);
  // funtion to change parent category name
  const CategoryChangeHandler = (event) => {
    setId(event.target.value);
  };
  const submithandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json'
      }
    };

    const params = {
      parent_category: categoryId,
      name: categoryData.name,
      enabled: isChecked
    };

    axios
      .put(Url.BASEURL + 'updatecategory/' + subCategoryId, params, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value.data.response);
          setUpdatecategory(value.data.response);
          openModal();
          setId(value.data.response.parent_category);
          // navigate('/listcategory/0');

          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };

  // function for set pre checked if the active status is checked
  const handleCheckboxChange = (event) => {
    console.log('event', event.target);
    setIsChecked(isChecked === 1 ? 0 : 1);
  };

  // function for reload page
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listcategory/0');
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listcategory/0');
  }
  return (
    <Constant>
      <BreadScrum path="/listcategory/0" />{' '}
      <div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>UPDATE</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Updated successfully
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={closeModal}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="wrapper">
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Category</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body col-8">
                      {categoryId != 0 ? (
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Parent Category
                          </label>
                          <input
                            onChange={handleInputChange}
                            value={
                              categoryData && categoryData.parent
                                ? categoryData.parent.name
                                : ''
                            }
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Category Name"
                            disabled
                          />
                        </div>
                      ) : (
                        <span></span>
                      )}
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="required"
                        >
                          Category Name
                        </label>
                        <input
                          onChange={handleInputChange}
                          value={categoryData.name ? categoryData.name : ''}
                          name="name"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Category Name"
                        />
                        {error && error.name ? (
                          <div style={{ color: 'red' }}>{error.name}</div>
                        ) : null}
                      </div>

                      <div className="form-group active_status_flex m-0 align-items-center pl-1">
                        <input
                          type="checkbox"
                          checked={isChecked && isChecked == 1 ? 1 : 0}
                          onChange={handleCheckboxChange}
                        />

                        <label
                          htmlFor="exampleInputEmail1"
                          className="m-0 pl-3"
                        >
                          Active Status
                        </label>
                      </div>
                      <div className="row mt-2">
                        <div
                          className="col-4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '10px',
                            marginBottom: '10px'
                          }}
                        >
                          <div className="">
                            <button
                              onClick={submithandler}
                              // className="btn btn-primary"
                              className="btn text-white"
                              style={{ backgroundColor: '#1e336a' }}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="" style={{ marginLeft: '20px' }}>
                            <button
                              onClick={reloadPage}
                              // className="btn btn-primary"
                              className="btn text-black"
                              style={{ backgroundColor: '#DCDCDC' }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default ManageCategory;

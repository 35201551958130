import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { staffData } from '../Admin/Data';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { setStatus } from '../Redux/Action/authaction';
import { BounceLoader } from 'react-spinners';
import { Modal, Button } from 'react-bootstrap';
import './ManageBrands.css';
import Select from 'react-select';

const BrandsInfo = () => {
  const [brandData, setBrandData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchBrandByName, setSearhBrandByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const token = useSelector((state) => state.token);
  const [deleteRow, setDeleteRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brandDataDropdown, setBrandDataDropdown] = useState([]);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [id, setId] = useState('');
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'bname',
    orderby: 'asc'
  });
  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..................------->>>>>>', permissions);
  useEffect(() => {
    getBrandListDropdown();
    getBrandList();
  }, [searchBrand, itemOffset, deleteRow, id,isArrowObj,pageCount]);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    console.log('delterow', typeof id);
    axios
      .get(
        Url.BASEURL +
          'listbrand?' +
          'search=' +
          searchBrand +
          '&page=' +
          itemOffset +
          (id !== '' ? '&brand_id=' + id.value : '')+
           '&orderby=' +
          isArrowObj.column+
          '&sortby=' +
          isArrowObj.orderby,
        // '&brand_id=' +
        // '',

        config
      )
      .then(
        (value) =>
          setBrandData(value.data.response.data) &
          console.log('staffdataaaaaaa', value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  const getBrandListDropdown = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'brand-list?', config)
      .then((value) => {
        console.log('value.datavalue.data,', value.data);
        setBrandDataDropdown(value.data.response);
      })
      .catch((err) => console.log(err));
  };

  // const transformClientDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, contact_person_name_0 }) => ({
  //     value: id.toString(),
  //     label: `${contact_person_name_0}`
  //   }));
  //   return [selectOption, ...dataOptions];
  // };

  // const clientOptions = transformClientDataToOptions(brandDataDropdown);

  useEffect(() => {
    if (staffData) {
      setResponse(brandData);
    }
  }, [staffData, searchBrand]);

  // function for pagination

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhBrandByName(searchBrandByName);
  };

  // function for delete row

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deletebrand/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  const transformBrandDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Brand' };
    const dataOptions = data.map(({ id, bname }) => ({
      value: id.toString(),
      label: `${bname}`
    }));

    return [selectOption, ...dataOptions];
  };
  const brandOptions = transformBrandDataToOptions(brandDataDropdown);

  console.log('brandDatabrandData', id);
  const transformDesignationDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, bname }) => ({
      value: id.toString(),
      label: `${bname}`
    }));

    return [selectOption, ...dataOptions];
  };

  const designationOptions = transformDesignationDataToOptions(brandData);

  const handleClearInput = () => {
    setSearchBrand('');
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  return (
    <>
      <Constant>
        <BreadScrum
          routepath={'addbrand'}
          path="/dashboard"
          heading={'Manage Brand'}
          btn={'Add Brand'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' || permissions?.Manage_Brands?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div
                        className="row"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div
                          className="search-field d-md-block col-6"
                          style={{ marginBottom: '13px' }}
                        >
                          <div
                            className="d-flex align-items-center h-100 searchbar_parent"
                            action="#"
                          >
                            <div
                              className=" position-relative"
                              style={{
                                borderWidth: 1,
                                borderRadius: 5,
                                border: '1px solid black'
                              }}
                            >
                              <div className="input-group-prepend bg-transparent"></div>
                              <input
                                style={{
                                  height: 30,
                                  paddingRight: 30
                                }}
                                onChange={(e) =>
                                  setSearchBrand(e.target.value) &
                                  handleKeyPress()
                                }
                                type="text"
                                className="form-control bg-transparent border-0"
                                placeholder="Search"
                                value={searchBrand}
                              />
                              {searchBrand && (
                                <span
                                  className="position-absolute top-50 end-0 translate-middle-y"
                                  style={{ cursor: 'pointer', marginRight: 10 }}
                                  onClick={handleClearInput}
                                >
                                  ✕
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <Select
                          defaultValue={id}
                          onChange={setId}
                          options={brandOptions}
                          placeholder="Select Brand"
                          maxMenuHeight="170px" // Set the minimum height here
                          // minMenuHeight="180px"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              width: '250px' // Set the width here
                            })
                          }}
                        />
                      </div>
                      <div className="wrapper">
                        {loading ? (
                          <div
                            className="spinner-container"
                            style={{ marginLeft: '500px' }}
                          >
                            <BounceLoader
                              color={'#123abc'}
                              loading={loading}
                              css={override}
                              size={60}
                            />
                          </div>
                        ) : (
                          <table
                            id="example2"
                            className="table table-bordered table-hover"
                            style={{ fontSize: '14px' }}
                          >
                            <thead>
                              <tr>
                              <th className="tablehead">
                                {' '}
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('bname', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'bname' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                               Brand Name
                              </th>
                              <th className="tablehead">
                                {' '}
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('enabled', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'enabled' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                              Active/Not-Active
                              </th>
                                {type === 'superadmin' ||
                                permissions?.Manage_Brands?.includes('view') ||
                                permissions?.Manage_Brands?.includes(
                                  'update'
                                ) ||
                                permissions?.Manage_Brands?.includes(
                                  'delete'
                                ) ? (
                                  <th
                                    className="tablehead"
                                    style={{ textAlign: 'center' }}
                                  >
                                    Action
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {brandData.map((e) => {
                                return (
                                  <tr className="tablerow">
                                    <td>{e.bname}</td>
                                    <td>
                                      {e.enabled === 1
                                        ? 'Active'
                                        : 'Not-Active'}
                                    </td>
                                    {type === 'superadmin' ||
                                    permissions?.Manage_Brands?.includes(
                                      'view'
                                    ) ||
                                    permissions?.Manage_Brands?.includes(
                                      'update'
                                    ) ||
                                    permissions?.Manage_Brands?.includes(
                                      'delete'
                                    ) ? (
                                      <td>
                                        <div className="col-12 action_flex justify-content-around">
                                          {type === 'superadmin' ||
                                          permissions?.Manage_Brands?.includes(
                                            'view'
                                          ) ? (
                                            <Link
                                              to={'/viewbrand/' + e.id}
                                              className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                            ></Link>
                                          ) : null}
                                          {type === 'superadmin' ||
                                          permissions?.Manage_Brands?.includes(
                                            'update'
                                          ) ? (
                                            <Link
                                              to={'/managebrand/' + e.id}
                                              className="nav-icon fas fa-edit"
                                            ></Link>
                                          ) : null}
                                          {type === 'superadmin' ||
                                          permissions?.Manage_Brands?.includes(
                                            'delete'
                                          ) ? (
                                            <i
                                              onClick={() => {
                                                handleShow();
                                                setBrandIdToDelete(e.id);
                                              }}
                                              className="nav-icon fas fa-times pointer_curso"
                                            />
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this Brand?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Brand Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default BrandsInfo;

export const clientsData = [
  {
    id: '1',
    name: 'Harish Yadav',
    email: 'harish@gmail.com',
    contactNumber: '89675644534',
    organisation: 'Yadav Constructions',
    salesStaff: 'Rahul',
    date: '05-06-2023'
  },
  {
    id: '1',
    name: 'Suresh Kumar',
    email: 'suresh@gmail.com',
    contactNumber: '89675644534',
    organisation: 'Kumar Builders',
    salesStaff: 'Rahul',
    date: '08-05-2023'
  },
  {
    id: '2',
    name: 'Mahesh Kumar',
    email: 'mahesh@gmail.com',
    contactNumber: '6756453456',
    organisation: 'Kumar builders',
    salesStaff: 'Bhupinder',
    date: '04-05-2023'
  },
  {
    name: 'Raghav Sood',
    email: 'raghav@gmail.com',
    contactNumber: '7867564534',
    organisation: 'XYZ Builders',
    salesStaff: 'Ankur',
    date: '11-07-2023'
  },
  {
    name: 'Deepak Mishra',
    email: 'deepak@gmail.com',
    contactNumber: '8867564534',
    organisation: 'Mishra Steels&Co.',
    salesStaff: 'Ankur',
    date: '08-06-2023'
  },
  {
    name: 'Balveer Singh',
    email: 'balveer@gmail.com',
    contactNumber: '8867564534',
    organisation: 'Singh Constructions',
    salesStaff: 'Ankur',
    date: '12-07-2023'
  }
];
export const staffData = [
  {
    name: 'Ankur',
    contactNumber: '7867564567',
    designation: 'Sales Team Member',
    teamManager: 'Chanchal',

    activeStatus: 'Active',
    joiningYear: '2023',
    email: 'ankur@gmail.com'
  },
  {
    name: 'Bhupinder',
    email: 'bhupinder@gmail.com',
    contactNumber: '7875456756',
    designation: 'Marketing',
    teamManager: 'Nitin Handa',
    activeStatus: 'Active',
    joiningYear: '2023'
  },
  {
    name: 'Vikas',
    email: 'vikas@gmail.com',
    contactNumber: '5675456756',
    designation: 'Sales ',
    teamManager: 'Nitin Handa',
    activeStatus: 'Not-Active',
    joiningYear: '2022'
  },
  {
    name: 'Nitin Handa',
    email: 'nitin@gmail.com',
    contactNumber: '7675456756',
    designation: 'Sales Head',
    teamManager: '-',
    activeStatus: 'Active',
    joiningYear: '2017'
  },
  {
    name: 'Chanchal',
    email: 'tanveer@gmail.com',
    contactNumber: '5675456756',
    designation: 'Junior Sales Head ',
    teamManager: '-',
    activeStatus: 'Active',
    joiningYear: '2020'
  },
  {
    name: 'Rahul Kumar',
    email: 'rahul@gmail.com',
    contactNumber: '5675456756',
    designation: 'Sales Team member',
    teamManager: 'Nitin Handa',
    activeStatus: 'Active',
    joiningYear: '2022'
  }
];
export const productsData = [
  {
    name: 'MS PIPE 15 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '80',
    size_type: 'NB',
    product_size: '15 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 15 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '500',
    size_type: 'NB',
    product_size: '5 NB Heavy',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 20 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '20 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 20 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'NB',
    product_size: '20 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 20 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '700',
    size_type: 'NB',
    product_size: '20 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 25 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '560',
    size_type: 'NB',
    product_size: '25 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 25 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '600',
    size_type: 'NB',
    product_size: '25 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 25 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '370',
    size_type: 'NB',
    product_size: '25 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 32 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '32 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 32 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '32 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 32 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '32 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 40 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '40 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 40 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '40 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 40 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '40 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 50 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '50 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 50 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '50 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 50 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '50 NB HEVAY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 65 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '65 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 65 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '65 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 65 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '65 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 80 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '80 NB LIGHT',
    brand: 'MS PIPE',

    category: 'Pipe'
  },
  {
    name: 'MS PIPE 80 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '20 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 80 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '80 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 100 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '100 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 100 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '100 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 100 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '300',
    size_type: 'NB',
    product_size: '100 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 125 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '125 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 125 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'NB',
    product_size: '125 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 125 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '125 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 150 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '150 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 150 NB MEDIUM',
    Pricing_option: 'Per Unit',
    Product_price: '700',
    size_type: 'NB',
    product_size: '150 NB MEDIUM',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 150 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'NB',
    product_size: '150 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 200 NB LIGHT',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'NB',
    product_size: '200 NB LIGHT',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS PIPE 200 NB HEAVY',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'NB',
    product_size: '200 NB HEAVY',
    brand: 'MS PIPE',
    category: 'Pipe'
  },
  {
    name: 'MS ANGLE 25 X 25 X 3.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'MM',
    product_size: '25 X 25 X 3.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 25 X 25 X 4.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'MM',
    product_size: '25 X 25 X 4.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 25 X 25 X 5.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '700',
    size_type: 'MM',
    product_size: '25 X 25 X 5.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 25 X 25 X 6.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'MM',
    product_size: '25 X 25 X 6.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 30 X 30 X 3.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '800',
    size_type: 'MM',
    product_size: '30 X 30 X 3.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 32 X 32 X 2.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'MM',
    product_size: '32 X 32 X 2.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 32 X 32 X 3.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'MM',
    product_size: '32 X 32 X 3.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 35 X 35 X 3.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'MM',
    product_size: '35 X 35 X 3.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 35 X 35 X 4.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'MM',
    product_size: '35 X 35 X 4.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 35 X 35 X 5.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'MM',
    product_size: '35 X 35 X 5.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 40 X 40 X 3.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '500',
    size_type: 'MM',
    product_size: '40 X 40 X 3.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 40 X 40 X 4.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '400',
    size_type: 'MM',
    product_size: '40 X 40 X 4.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 40 X 40 X 5.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '200',
    size_type: 'MM',
    product_size: '40 X 40 X 5.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  },
  {
    name: 'MS ANGLE 40 X 40 X 6.00 MM',
    Pricing_option: 'Per Unit',
    Product_price: '100',
    size_type: 'MM',
    product_size: '40 X 40 X 6.00',
    brand: 'MS ANGLE',
    category: 'Angle'
  }
];
// export const categoryListing=[{
// }]
export const productCategoryData = [
  {
    name: 'Jindal Panther Fe 550D TMT',
    brand: 'Jindal',
    parentCataegory: 'Rebars >',
    subCategory: 'Steel'
  },
  {
    name: 'Kamdhenu Nxt ',
    parentCataegory: 'Rebars >',
    subCategory: 'Steel',
    brand: 'Kamdhenu'
  },
  {
    name: 'JSW Neosteel Fe 550D',
    parentCataegory: 'Rebars >',
    subCategory: 'Steel'
  },
  {
    name: 'Rebars',
    parentCataegory: 'Steel'
    // subCataegory: 'Rebars'
  },
  {
    name: 'Steel',
    parentCataegory: '-'
  },
  {
    name: 'UltraTech Cements ',
    parentCataegory: 'Cement'
  },
  {
    name: 'cement',
    parentCataegory: '-'
  },
  {
    name: 'Water Pipes',
    parentCataegory: 'Pipes >',
    subCategory: 'plastic'
  },
  {
    name: 'Pvc Pipes',
    parentCataegory: 'Water Pipes >',
    subCategory: '  Pvc Pipes >',
    Sub_category: 'Plastic'
  },
  {
    name: 'WaterPipes ',
    parentCataegory: 'Plastic >',
    subCategory: 'Pipes'
  },
  {
    name: 'Plastic',
    parentCataegory: '-'
  },
  {
    name: 'Ashirvad pipes Pvt Ltd',
    parentCataegory: 'Pipes >',
    subCategory: 'plastic'
  },
  {
    name: 'Ambuja Cements ',
    parentCataegory: 'Cement'
  }
];
export const Brand = [
  {
    name: 'Jindal Panther Fe 550D TMT',
    activeStatus: 'Active'
  },
  {
    name: 'Ambuja Cement',
    activeStatus: 'Active'
  },
  {
    name: 'Kamdhenu Nxt',
    activeStatus: 'Active'
  },
  {
    name: 'JSW Neosteel Fe 550D',
    activeStatus: 'Not-Active'
  },
  {
    name: 'UltraTech Cements'
  },
  {
    name: 'Ashirvad pipes Pvt Ltd'
  },
  {
    name: 'UltraTech Cements'
  }
];
export const departments = [
  {
    sr_no: '1',
    name: 'HR Department'
  },
  {
    sr_no: '2',
    name: 'Accounts Department'
  },
  {
    sr_no: '3',
    name: 'Sales Department'
  },
  {
    sr_no: '4',
    name: 'Marketing Department'
  }
];
export const AdminUsersInfo = [
  {
    sr_no: '1',
    userName: 'Rajesh',
    email: 'rajesh@gmai.com',
    password: '1234567',
    department: 'Marketing Dept.'
  },
  {
    sr_no: '2',
    userName: 'Arminder',
    email: 'arminder@gmai.com',
    password: '12347',
    department: 'Sales Dept.'
  },
  {
    sr_no: '3',
    userName: 'Sourabh',
    email: 'sourabh@gmai.com',
    password: '123567',
    department: 'Accounts Dept.'
  },
  {
    sr_no: '4',
    userName: 'Davinder Singh',
    email: 'davinder@gmai.com',
    password: '1234567',
    department: 'Sales Dept.'
  },
  {
    sr_no: '5',
    userName: 'Nikhil',
    email: 'nikhil@gmai;.com',
    password: '123467',
    department: 'Marketing Dept.'
  }
];

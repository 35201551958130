import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { departments, userData } from '../Admin/Data';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

const AdminUsers = () => {
  const [userData, setUserData] = useState([]);
  console.log('userDatauserData', userData);
  const [response, setResponse] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [searchUserByName, setSearhUserByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [role, setrole] = useState({});
  const token = useSelector((state) => state.token);
  const [deapartment, setDepartment] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log('rolerole', role);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const permissions = useSelector((state) => state.permissions);
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'name',
    orderby: 'desc'
  });
  // setPermission(useSelector((state) => state.permissions));
  const type = useSelector((state) => state.type);
  //getting all department
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'getroles', config)
      .then((value) => setDepartment(value.data.response.data))
      .catch((err) => console.log(err));
    // console.log('teammanagerdata', teamManagers[0].designation.designation);
  }, []);

  // function for delete row

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deletestaff/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBrandList();
  }, [searchUser, itemOffset, deleteRow,  isArrowObj,pageCount]);

  const getBrandList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'listsubadmin?' +
          'search=' +
          searchUser +
          '&page=' +
          itemOffset +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,

        config
      )
      .then(
        (value) =>
          setUserData(value.data.response.data) &
          console.log('asdbadbaskjdbjsabdjkasdb', value.data.response.data) &
          setrole(value.data.response.data.role_and_permissions) &
          // console.log('userdataaaaaaa', value.data.response.data) &
          // console.log('rolename', value.data.response.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  };
  // useEffect(() => {
  //   if (userData) {
  //     setResponse(userData);
  //   }
  // }, [userData, searchUser, searchUser, itemOffset]);

  // const filteredData = userData.filter((item) => item.sub_admin === 1);
  // console.log('filterdat----------', filteredData);
  // function for pagination

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    // setSearhUserByName(searchUserByName);
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  return (
    <>
      <Constant>
        <BreadScrum
          btn_name="Add Admin User"
          // routepath={'add_admin_user'}
          heading={'Manage Users'}
          // btn={'Add Admin User'}
          // bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div
                        className="search-field d-none d-md-block"
                        style={{ marginBottom: '13px' }}
                      >
                        <div
                          className="d-flex align-items-center h-100 searchbar_parent"
                          action="#"
                        >
                          <div
                            className="input-group merchant_search_bar border_radius searchbar_size"
                            style={{
                              borderWidth: 1,
                              borderRadius: 5,
                              marginRight: 17
                            }}
                          >
                            <div className="input-group-prepend bg-transparent"></div>
                            <input
                              style={{
                                height: 30
                              }}
                              onChange={(e) =>
                                setSearchUser(e.target.value) & handleKeyPress()
                              }
                              type="text"
                              className="form-control bg-transparent border-0"
                              placeholder="Search"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="wrapper">
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              {/* <th>Sr.No</th> */}
                              {/* <th>Name</th> */}
                              <th
                                style={{ display: 'flex' }}
                                className="tablehead"
                              >
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('name', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'name' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Name
                              </th>{' '}
                              <th>
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('email', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'email' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Email
                              </th>{' '}
                              <th>
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('department_id', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'department_id' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                department
                              </th>{' '}
                              {type === 'superadmin' ||
                              permissions?.User_Rights?.includes('view') ||
                              permissions?.User_Rights?.includes('update') ||
                              permissions?.User_Rights?.includes('delete') ? (
                                <th className="text-center">Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {userData.length == 0 ? (
                              <tr className="text-center">
                                <td
                                  colSpan="9"
                                  style={{
                                    fontSize: 16,
                                    marginTop: '10px'
                                  }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {userData?.map((e, index) => {
                                  // const role = deapartment.find(
                                  //   (p) => p.id === e.department_id
                                  // );
                                  // console.log(
                                  //   '>>>>>>>>>>>>>>>>>>>>>filedata',
                                  //   role
                                  // );

                                  return (
                                    <tr key={++index}>
                                      {/* <td>{++index}</td> */}
                                      <td>{e?.name}</td>
                                      <td>{e?.email}</td>
                                      <td>
                                        {e?.role_and_permissions?.role_name
                                          ? e?.role_and_permissions?.role_name
                                          : '-'}
                                      </td>
                                      {type === 'superadmin' ||
                                      permissions?.User_Rights?.includes(
                                        'view'
                                      ) ||
                                      permissions?.User_Rights?.includes(
                                        'update'
                                      ) ||
                                      permissions?.User_Rights?.includes(
                                        'delete'
                                      ) ? (
                                        <td>
                                          <div className="col-12 action_flex">
                                            {type === 'superadmin' ||
                                            permissions?.User_Rights?.includes(
                                              'view'
                                            ) ? (
                                              <Link
                                                to={'/staffDetails/' + e.id}
                                                className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.User_Rights?.includes(
                                              'update'
                                            ) ? (
                                              <Link
                                                to={'/managestaff/' + e.id}
                                                className="nav-icon fas fa-edit"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.User_Rights?.includes(
                                              'delete'
                                            ) ? (
                                              <i
                                                onClick={() => {
                                                  handleShow();
                                                  setBrandIdToDelete(e.id);
                                                }}
                                                className="nav-icon fas fa-times"
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this User?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            User Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default AdminUsers;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Url from '../Element/Api';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import './Auth.css'


import {
  setToken,
  setPermissions,
  setRole,
  setType,
  setStoreEmail
} from '../Redux/Action/authaction';
const override = css`
display: block;
margin: 0 auto;
`;
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)
  const [loginResponse, setLoginResponse] = useState([]);
  // const [permission,setPermissions] =useState([]);
  // const [role,setRole] =useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandle = (e) => {
    dispatch(setStoreEmail(email));
    setLoading(true)
    const params = {
      email: email
    };
    axios
      .post(Url.BASEURL + 'forgot-password', params)
      .then((value) => {
        console.log('value.data.statusvalue.data.status', value.data.status);
        if (value.data.status === 200) {
          setLoading(false)
          navigate('/otp');
        } else {
          setLoading(false)
          setError('please enter valid credentials');
        }
      })
      .catch((err) => {
        setLoading(false)
        console.error('------------------>>>', err.response.data.message);
        setError(err.response.data.message);
      });

    // else {
    //   setError('please enter email');
    // }
  };

  // useEffect(() => {
  //   if (loginResponse.status === 200) {
  //     dispatch(setToken(loginResponse.token));
  //     // console.log('tokewn', token);
  //     console.log('password:', password);
  //     navigate('/dashboard');
  //     localStorage.setItem('token', loginResponse.token);
  //     console.log('tokennnnnn', dispatch(setToken(loginResponse.token)));
  //     localStorage.setItem('toast', true);
  //   } else {
  //     setError('please enter valid credentials');
  //   }
  // }, [loginResponse]);

  return (
    <>
      {loading &&
        <div className="spinner-back">
          <div className="spinner-cont">
            <BounceLoader color={'#123abc'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }
      <div className="hold-transition login-page">
        <img
          src="dist/img/symang_logo_za.png"
          alt="AdminLTE Logo"
          // className="brand-image img-circle elevation-3"
          style={{
            width: '224px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            // marginLeft: '133px',
            marginBottom: '20px'
          }}
        //   style={{ opacity: '.8' }}
        />{' '}

        <div className="login-box">
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">
                Enter your registered email to get OTP
              </p>

              <div>
                <div className="input-group ">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError('');
                    }}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                {error ? (
                  <div
                    style={{
                      fontSize: '13px',

                      color: 'red',
                      marginBottom: '23px'
                    }}
                    className='mt-1 ms-2 fw-normal'>
                    {error}
                  </div>
                ) : null}

                <div
                  className="row"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/* /.col */}
                  <div className="col-6">
                    <p className="mb-1">
                      <Link to="/">Back to Login</Link>
                    </p>
                  </div>
                  <div className="col-6">
                    <button
                      style={{ marginTop: '12px' }}
                      onClick={submitHandle}
                      // type="submit"
                      className="btn btn-primary btn-block"
                    >
                      Get OTP
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </div>
              {/* /.social-auth-links */}
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;

import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import rootReducer from '../Reducer/AuthReducer';
import storage from 'redux-persist-indexeddb-storage';
const authPersistConfig = {
  key: 'root',
  storage: storage('db')
};
const persistedReducer = persistReducer(authPersistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);
export { store, persistor };

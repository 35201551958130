import * as Yup from "yup";
//pasword schema
export const passwordSchema = Yup.object({
    newPassword: Yup.string()
        .required('Required')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
            'Must have at least 1 uppercase, 1 lowercase, 1 number, 1 special character, and be 8+ characters long'
        ),
    confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
// import { staffData } from '../Admin/Data';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
import { Modal, Button } from 'react-bootstrap';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Select from 'react-select';
import { Link } from 'react-router-dom';

export const StaffModal = () => {
  let { staffId } = useParams();
  const navigate = useNavigate();
  const [teamManagerId, setTeamManagerId] = useState('');
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [staffData, setStaffData] = useState([]);
  const [designationId, setdesignationId] = useState('');
  const [response, setResponse] = useState([]);
  const [response2, setResponse2] = useState([]);
  const [teamManagers, setTeamManagers] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [isChecked, setIsChecked] = useState(0);
  const [isManagerChecked, setIsManagerChecked] = useState(0);
  const [error, setError] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);
  const [alternate_name, setAlternateName] = useState('');
  const [updateStaffData, setUpdateStaffData] = useState([]);
  const [password, setPassword] = useState('');
  const [isChecked4, setIsChecked4] = useState(0);
  const [isChecked3, setIsChecked3] = useState(0);
  const [isChecked2, setIsChecked2] = useState(0);
  const [isChecked5, setIsChecked5] = useState(0);
  const [departmentId, setDepartmentId] = useState('');
  const [department, setDepartment] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const token = useSelector((state) => state.token);
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  // console.log(imageData.path);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'alldesignations', config)
      .then((value) => setDesignations(value.data.response))
      .catch((err) => console.log(err));
    console.log('teammanagerdata', designations);
  }, []);
  //getting all department
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'getroles', config)
      .then((value) => setDepartment(value.data.response.data))
      .catch((err) => console.log(err));
    // console.log('teammanagerdata', teamManagers[0].designation.designation);
  }, []);

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   const params = {
  //     id: staffId
  //   };
  //   axios
  //     .get(Url.BASEURL + 'staffmanagerlist',config)
  //     .then((value) => setTeamManagers(value.data.response))
  //     .catch((err) => console.log(err));
  //   // console.log('teammanagerdata', teamManagers[0].designation.designation);
  // }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .post(Url.BASEURL + `editstaffmanagerlist/` + staffId, {}, config)
      .then((value) => setTeamManagers(value.data.response.teamMembers))
      .catch((err) => console.log(err));
    // console.log('teammanagerdata', teamManagers[0].designation.designation);
  }, []);
  useEffect(() => {
    if (teamManagers) {
      setResponse(teamManagers);
    }
  }, [teamManagers]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'editstaff/' + staffId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          setStaffData(value.data.response);
          setId(value.data.response.team_manager);
          setId2(value.data.response.designation);
          setIsChecked(value.data.response.enabled);
          setIsChecked2(value.data.response.manager_status);
          setIsChecked4(value.data.response.isCheckedAddress);
          setIsChecked3(value.data.response.isCheckedNumber);
          setIsChecked5(value.data.response.sub_admin);
          setDepartmentId(value.data.response.department_id);
          setImageUrl(
            value.data.response.profile_image.path ||
              value.data.response.profile_image
          );
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, [staffId]);

  console.log('>>>>>>>>>>>>>>>>>', id);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setStaffData({
      ...staffData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  // function for team members dropdown data
  const transformTeamToOptionsData = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));
    return [selectOption, ...dataOptions];
  };
  const teamData = transformTeamToOptionsData(teamManagers);

  // function for sub-admin dropdown data
  const transformSubAdminToOptionsData = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, role_name, email }) => ({
      value: id.toString(),
      label: `${role_name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const subAdminData = transformSubAdminToOptionsData(department);

  // function for designation dropdown data
  const transformDesignationToOptionsData = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, designation, email }) => ({
      value: id.toString(),
      label: `${designation} `
    }));
    return [selectOption, ...dataOptions];
  };
  const designaitonData = transformDesignationToOptionsData(designations);

  // function to get selected Manager

  const managerChangeHandler = (selectedOption) => {
    setId(selectedOption.value);
  };
  // function to get selected designation

  const designationChangeHandler = (selectedOption) => {
    setId2(selectedOption.value);
  };
  // getting allteamanagers data throuh axios
  // state for image upload
  // const [file, setFile] = useState(null)
  const [fileDataUrl, setFileDataUrl] = useState('');
  console.log('-----data', fileDataUrl);

  const [show, setShow] = useState(false);

  //function for department handler
  const departmentChangeHandler = (selectedOption) => {
    // setDepartmentId(event.target.value);
    setDepartmentId(selectedOption.value);
  };

  // fucntion to store staff data
  console.log('======>>>>>>', fileDataUrl);

  const convertImageToBase64 = (file, callback) => {
    if (!file) {
      console.error('No file provided.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Url = event.target.result;
      callback(base64Url);
      setImageUrl(base64Url);
      // submitHandler()
    };

    reader.readAsDataURL(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    convertImageToBase64(file, (base64Url) => {
      console.log('Base64 URL:', base64Url);
      // Now you can use the base64Url as needed, such as setting it in the component state
      // this.setState({ imageBase64Url: base64Url });
    });
  };
  const submitHandler = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      name: staffData.name,
      email: staffData.email,
      contact_no: staffData.contact_no,
      whatsapp_no: isChecked3 ? staffData.contact_no : staffData.whatsapp_no,
      alternate_no: staffData.alternate_no,
      dob: staffData.dob,
      join_date: staffData.join_date,
      team_manager: id,
      designation: id2,
      aadhaar_number: staffData.aadhaar_number,
      pan_number: staffData.pan_number,
      enabled: isChecked,
      password: password,
      manager_status: isChecked2,
      alternate_name: staffData.alternate_name,
      current_address: isChecked4
        ? staffData.permanent_address
        : staffData.current_address,
      permanent_address: staffData.permanent_address,
      profile_image: imageUrl,
      sub_admin: isChecked5,
      department_id: departmentId,
      isCheckedNumber: isChecked3,
      isCheckedAddress: isChecked4
    };
    axios.defaults.headers.put['Content-Type'] =
      'application/json;charset=utf-8';
    axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
    axios
      .put(Url.BASEURL + 'updatestaff/' + staffId, params, config)
      .then((value) => {
        setLoading(false);
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/liststaff');
          // toast('Client added Successfully');
          setUpdateStaffData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCheckboxChange = (event) => {
    console.log('event', event.target);
    setIsChecked(isChecked === 1 ? 0 : 1);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/liststaff');
  }

  const handleCheckboxChange4 = (event) => {
    setIsChecked4(event.target.checked ? 1 : 0);
  };
  //function for making number same
  const handleCheckboxChange3 = (event) => {
    setIsChecked3(event.target.checked ? 1 : 0);
  };

  // function for manager selection
  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked ? 1 : 0);
  };
  const handleCheckboxChange5 = (event) => {
    setIsChecked5(event.target.checked ? 1 : 0);
  };
  // function to reload page
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/liststaff');
  };
  const clearDate = () => {
    setStaffData({
      ...staffData,
      join_date: ''
    });
  };
  // Get the current date in the format YYYY-MM-DD
  const currentDate = new Date().toISOString().split('T')[0];
  return (
    <Constant>
      <BreadScrum path="/liststaff" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Update Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Staff Updated Successfully
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {loading && (
          <div className="spinner-overlay">
            <BounceLoader
              color={'#123abc'}
              loading={loading}
              css={override}
              size={60}
            />
          </div>
        )}
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Staff Information</h3>
                    <Link
                      to={'/liststaff'}
                      className="nav-icon fa-solid fa-x pointer_cursor d-block p-1 float-end"
                    ></Link>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <p className="h5 m-0 px-1 py-2">Personal Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="required"
                                >
                                  Name
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                  }
                                  value={staffData.name}
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter name"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.name ? (
                                  <div style={{ color: 'red' }}>
                                    {error.name}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail2"
                                  //className="required"
                                >
                                  Pan Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     staffData.pan_number.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onChange={handleInputChange}
                                  value={staffData.pan_number}
                                  name="pan_number"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Enter Pan Number"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.pan_number ? (
                                  <div style={{ color: 'red' }}>
                                    {error.pan_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail3"
                                 // className="required"
                                >
                                  Aadhaar Number
                                </label>
                                <input
                                  maxLength={12}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={staffData.aadhaar_number}
                                  name="aadhaar_number"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Enter Aadhaar Number"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.aadhaar_number ? (
                                  <div style={{ color: 'red' }}>
                                    {error.aadhaar_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail4"
                                  className="required"
                                >
                                  Date Of Birth
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  name="dob"
                                  value={staffData.dob}
                                  type="date"
                                  className="form-control"
                                  id="exampleInputEmail4"
                                  max={currentDate}
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.dob ? (
                                  <div style={{ color: 'red' }}>
                                    {error.dob}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail5"
                                  className="required"
                                >
                                  Permanent Address
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={staffData.permanent_address}
                                  name="permanent_address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail5"
                                  placeholder="Enter Address"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.permanent_address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.permanent_address}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail6"
                                  className="required"
                                >
                                  Current Address
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={
                                    isChecked4
                                      ? staffData.permanent_address
                                      : staffData.current_address
                                  }
                                  name="current_address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail6"
                                  placeholder="Enter Address"
                                  disabled={isChecked4}
                                  style={{color:'GrayText'}}

                                />
                                {error && error.current_address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.current_address}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center ">
                              <div className="col-2">
                                <div className="profile_image_profile m-auto">
                                  <img
                                    src={imageUrl}
                                    class="rounded-circle shadow-4 "
                                    alt="Avatar"
                                  />
                                </div>
                              </div>
                              <div className="col-10">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail7">
                                    Profile Image{' '}
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        paddingLeft: '10px'
                                      }}
                                    >
                                      (Only JPG, JPEG,GIF and PNG files are
                                      allowed)
                                    </span>
                                  </label>
                                  <input
                                    onChange={handleFileInputChange}
                                    // name="profileImage_url"
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .gif"
                                    className="form-control"
                                    id="exampleInputEmail7"
                                    disabled
                                    style={{color:'GrayText'}}

                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group active_status_flex  align-items-center m-0 pt-2">
                                <input
                                  type="checkbox"
                                  checked={isChecked4}
                                  // onChange={handleCheckboxChange4}
                                  id="exampleInputEmail8"
                                  // disabled
                                />
                                <label
                                  htmlFor="exampleInputEmail8"
                                  className="m-0 px-2"
                                >
                                  Current Address Same as Permanent Address
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Contact Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputPassword9"
                                  className="required"
                                >
                                  Email
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={staffData.email}
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  id="exampleInputPassword9"
                                  placeholder="Enter Email"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.email ? (
                                  <div style={{ color: 'red' }}>
                                    {error.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail10"
                                  className="required"
                                >
                                  Contact Number
                                </label>
                                <input
                                  //
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={staffData.contact_no}
                                  name="contact_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail10"
                                  placeholder="Enter Contact Number"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.contact_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.contact_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail11"
                                  className="required"
                                >
                                  WhatsApp Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     staffData.whatsapp_no.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={
                                    isChecked3
                                      ? staffData.contact_no
                                      : staffData.whatsapp_no
                                  }
                                  type="text"
                                  name="whatsapp_no"
                                  className="form-control"
                                  id="exampleInputEmail11"
                                  placeholder="Enter whatsapp number"
                                  disabled={isChecked3}
                                  style={{color:'GrayText'}}

                                />
                                {error &&
                                (error.whatsapp_no || error.contact_no) ? (
                                  <div style={{ color: 'red' }}>
                                    {error.whatsapp_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group active_status_flex  align-items-center m-0">
                                <input
                                  type="checkbox"
                                  checked={isChecked3}
                                  // onChange={handleCheckboxChange3}
                                  id="exampleInputEmail2"
                                  // disabled
                                />
                                <label
                                  htmlFor="exampleInputEmail12"
                                  className="m-0 px-2"
                                >
                                  Whatsapp Number Same as Contact Number
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Employee Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail13"
                                  className="required"
                                >
                                  Joining Date
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  name="join_date"
                                  value={staffData.join_date}
                                  type="date"
                                  className="form-control"
                                  id="exampleInputEmail13"
                                  placeholder="Enter Joining Year"
                                  max={currentDate}
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.join_date ? (
                                  <div style={{ color: 'red' }}>
                                    {error.join_date}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail14"
                                  className="required"
                                >
                                  Team Manager
                                </label>
                                <Select
                                  value={teamData.find((e) => e.value == id)}
                                  onChange={managerChangeHandler}
                                  options={teamData}
                                  id="exampleInputEmail4"
                                  isDisabled
                                />

                                {error && error.team_manager ? (
                                  <div style={{ color: 'red' }}>
                                    {error.team_manager}
                                  </div>
                                ) : null}
                              </div>{' '}
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail15"
                                  className={
                                    isChecked5 === 1 ? 'required' : null
                                  }
                                >
                                  Sub-Admin's Department
                                </label>
                                <Select
                                  value={subAdminData.find(
                                    (e) => e.value == departmentId
                                  )}
                                  onChange={departmentChangeHandler}
                                  options={subAdminData}
                                  isDisabled
                                  id="exampleInputEmail5"
                                />
                                {error && error.team_manager ? (
                                  <div style={{ color: 'red' }}>
                                    {error.team_manager}
                                  </div>
                                ) : null}
                              </div>{' '}
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail16"
                                  className="required"
                                >
                                  Designations
                                </label>
                                <Select
                                  value={designaitonData.find(
                                    (e) => e.value == id2
                                  )}
                                  onChange={designationChangeHandler}
                                  options={designaitonData}
                                  id="exampleInputEmail6"
                                  isDisabled
                                />

                                {error && error.designation ? (
                                  <div style={{ color: 'red' }}>
                                    {error.designation}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group active_status_flex m-0 align-items-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked2}
                                  // onChange={handleCheckboxChange2}
                                  id="exampleInputEmail17"
                                  // disabled
                                />

                                <label
                                  htmlFor="exampleInputEmail17"
                                  className="p-2 m-0"
                                >
                                  Is Manager
                                </label>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group active_status_flex m-0 align-items-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked5}
                                  // onChange={handleCheckboxChange5}
                                  id="exampleInputEmail18"
                                  // disabled
                                />
                                <label
                                  htmlFor="exampleInputEmail18"
                                  className="m-0 p-2"
                                >
                                  Is Sub-Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Additional Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail19">
                                  Alternate Name
                                </label>
                                <input
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={staffData.alternate_name}
                                  name="alternate_name"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail19"
                                  placeholder="Enter Alternate Name"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.alternate_name ? (
                                  <div style={{ color: 'red' }}>
                                    {error.alternate_name}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail20">
                                  Emergency Contact No. From Family
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     staffData.alternate_no.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={staffData.alternate_no}
                                  name="alternate_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail20"
                                  placeholder="Enter Emergency Number"
                                  disabled
                                  style={{color:'GrayText'}}

                                />
                                {error && error.alternate_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.alternate_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">Password</label>
                                  <input
                                    onChange={handleInputChange}
                                    value={staffData.password}
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Enter password"
                                  />
                                  {error && error.password ? (
                                    <div style={{ color: 'red' }}>
                                      {error.password}
                                    </div>
                                  ) : null}
                                </div>
                              </div> */}
                            <div className="col-4">
                              <div className="form-group active_status_flex m-0 align-items-center justify-content-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  // onChange={handleCheckboxChange}
                                  id="exampleInputEmail21"
                                  // disabled
                                />
                                <label
                                  htmlFor="exampleInputEmail21"
                                  className="m-0 p-2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default StaffModal;

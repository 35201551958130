import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { staffData } from '../Admin/Data';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

const DesignationsInfo = () => {
  const [designationData, setDesignationData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchDesignation, setSearchDesignation] = useState('');
  const [searchDesignationByName, setSearhDesignationByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const token = useSelector((state) => state.token);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [id, setId] = useState('');
  const [designationLists, setDesignationLists] = useState([]);
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'designation',
    orderby: 'asc'
  });

  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..................------->>>>>>', permissions);
  useEffect(() => {
    getBrandList();
  }, [searchDesignation, itemOffset, id, isArrowObj,pageCount]);
  console.log('delterowdelterow', id);
  const getBrandList = () => {
    // setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'listdesignation?' +
          '&search=' +
          searchDesignation +
          (id !== '' ? '&designation_id=' + id.value : '') +
          '&page=' +
          itemOffset +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setDesignationData(value.data.response.data) &
          console.log('staffdataaaaaaa', value.data.response.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'alldesignations', config)
      .then(
        (value) =>
          setDesignationLists(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (staffData) {
      setResponse(designationData);
    }
  }, [staffData, searchDesignation]);

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(
        Url.BASEURL + 'deletedesignation/' + brandIdToDelete,
        config,
        params
      )
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };

  // function for pagination

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhDesignationByName(searchDesignationByName);
  };
  const transformDesignationDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Designation' };
    const dataOptions = data.map(({ id, designation }) => ({
      value: id.toString(),
      label: `${designation}`
    }));

    return [selectOption, ...dataOptions];
  };

  const designationOptions =
    transformDesignationDataToOptions(designationLists);

  const handleClearInput = () => {
    setSearchDesignation('');
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  return (
    <>
      <Constant>
        <BreadScrum
          routepath={'adddesignation'}
          heading={'Manage Designation'}
          btn={'Add Designation'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' || permissions?.Designation?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div
                        className="search-field d-none d-md-block"
                        style={{ marginBottom: '13px' }}
                      >
                        <div className="mb-3">
                          <div
                            className=""
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              className="d-flex align-items-center h-100 searchbar_parent"
                              action="#"
                            >
                              <div
                                // className="input-group merchant_search_bar border_radius searchbar_size"
                                // style={{
                                //   borderWidth: 1,
                                //   borderRadius: 5,
                                //   marginRight: 17
                                // }}
                                className=" position-relative"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  border: '1px solid black'
                                }}
                              >
                                <div className="input-group-prepend bg-transparent"></div>
                                <input
                                  style={{
                                    height: 30,
                                    paddingRight: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchDesignation(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                  value={searchDesignation}
                                />
                                {searchDesignation && (
                                  <span
                                    className="position-absolute top-50 end-0 translate-middle-y"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: 10
                                    }}
                                    onClick={handleClearInput}
                                  >
                                    ✕
                                  </span>
                                )}
                              </div>
                            </div>
                            <Select
                              defaultValue={id}
                              onChange={setId}
                              options={designationOptions}
                              placeholder="Select Designation"
                              maxMenuHeight="180px" // Set the minimum height here
                              // minMenuHeight="180px"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  width: '250px' // Set the width here
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="wrapper">
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ display: 'flex' }}
                                className="tablehead"
                              >
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('designation', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'designation' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                Designation Name
                              </th>
                              <th className="text-center">Active/Not-Active</th>
                              {type === 'superadmin' ||
                              permissions?.Designation?.includes('view') ||
                              permissions?.Designation?.includes('update') ||
                              permissions?.Designation?.includes('delete') ? (
                                <th style={{ textAlign: 'center' }}>Action</th>
                              ) : null}
                            </tr>
                          </thead>

                          <tbody>
                            {designationData.length == 0 ? (
                              <tr>
                                <td
                                  colSpan="9"
                                  style={{
                                    fontSize: 16,
                                    marginTop: '10px',textAlign:'center'
                                  
                                  }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {designationData.map((e, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {e.designation}
                                      </td>
                                      <td className="text-center">
                                        {e.enable === 1
                                          ? 'Active'
                                          : 'Not-Active'}
                                      </td>
                                      {type === 'superadmin' ||
                                      permissions?.Designation?.includes(
                                        'view'
                                      ) ||
                                      permissions?.Designation?.includes(
                                        'update'
                                      ) ||
                                      permissions?.Designation?.includes(
                                        'delete'
                                      ) ? (
                                        <td>
                                          <div className="col-12 action_flex justify-content-around">
                                            {type === 'superadmin' ||
                                            permissions?.Designation?.includes(
                                              'view'
                                            ) ? (
                                              <Link
                                                to={'/viewdesignation/' + e.id}
                                                className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.Designation?.includes(
                                              'update'
                                            ) ? (
                                              <Link
                                                to={
                                                  '/managedesignation/' + e.id
                                                }
                                                className="nav-icon fas fa-edit"
                                              ></Link>
                                            ) : null}
                                            {type === 'superadmin' ||
                                            permissions?.Designation?.includes(
                                              'delete'
                                            ) ? (
                                              <i
                                                onClick={() => {
                                                  handleShow();
                                                  setBrandIdToDelete(e.id);
                                                }}
                                                className="nav-icon fas fa-times"
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Designation</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this Designation?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Designation</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Designation Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default DesignationsInfo;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const setToken = (token) => ({
  type: 'SET_TOKEN',
  payload: {
    token
  }
});
export const delToken = (token) => ({
  type: 'DEL_TOKEN'
});
export const setStatus = (status) => ({
  type: 'SET_STATUS',
  payload: {
    status
  }
});
export const setParentCategory = (category) => ({
  type: 'SET_CATEGORY',
  payload: {
    category
  }
});
export const setPermissions = (permissions) => ({
  type: 'SET_PERMISSIONS',
  payload: {
    permissions
  }
});
export const setType = (type) => ({
  type: 'SET_ROLE',
  payload: {
    type
  }
});
export const delPassword = (password) => ({
  type: 'DEL_PASSWORD'
});
export const setStoreEmail = (email) => ({
  type: 'SET_EMAIL',
  payload: {
    email
  }
});
export const setpasswordChangeToken = (passwordtoken) => ({
  type: 'SET_PASSWORD_TOKEN',
  payload: {
    passwordtoken
  }
});

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
import { Modal, Button } from 'react-bootstrap';
const ManageDesignations = () => {
  const navigate = useNavigate();
  const [designationData, setDesignationData] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState('');
  let { desgnationId } = useParams();
  console.log('---------------------------------', desgnationId);

  const [updateDesignationData, setUpdateDesignationData] = useState([]);
  const token = useSelector((state) => state.token);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'editdesignation/' + desgnationId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value);
          setDesignationData(value.data.response);
          setIsChecked(value.data.response.enable);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setDesignationData({
      ...designationData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  // function for active update
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // fucntion to store staff data

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      designation: designationData.designation,
      enable: isChecked
    };
    axios.defaults.headers.put['Content-Type'] =
      'application/json;charset=utf-8';
    axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
    axios
      .put(Url.BASEURL + 'updatedesignation/' + desgnationId, params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/listdesignations');
          // toast('Client added Successfully');
          // setUpdateDesignationData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listdesignation');
  }
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listdesignation');
  };

  return (
    <Constant>
      {/* <BreadScrum routePath={'managedesignation'} heading={'Update Staff'} /> */}
      <BreadScrum path="/listdesignation" />{' '}

      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton style={{ backgroundColor: '#1e336a', color: 'white', border: 'none' }}>
              <Modal.Title>Update Designation</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize:'20px'}}>
              Designation Updated successfully
            </Modal.Body>
            <Modal.Footer>
              <Button style={{backgroundColor:'#1E3F6A',border: 'none'}} onClick={closeModal}>
                Ok
              </Button>

            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Designation Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xs-12 col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Name</label>
                            <input
                              onChange={handleInputChange}
                              value={designationData.designation}
                              name="designation"
                              type="text"
                              className="form-control"
                              id="exampleInputName"
                              placeholder="Enter name"
                            />
                            {error && error.designation ? (
                              <div style={{ color: 'red' }}>{error.designation}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group active_status_flex align-items-center">
                            <div className="row">
                              <div className="col-6">
                                {/* <div className="form-group active_status_flex"> */}
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                {/* </div> */}
                              </div>
                            </div>
                            <label htmlFor="exampleInputEmail1" className='m-0 pl-2'>Active Status</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={submitHandler}
                            // className="btn btn-primary"
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }} onClick={reloadPage}>Cancel</button>
                        </div>
                      </div>
                    </div>

                    {/* /.card-body */}
                    {/* <div className="card-footer">
                      <button
                        onClick={submitHandler}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};

export default ManageDesignations;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';

const AddIndustry = () => {
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState();
  const token = useSelector((state) => state.token);
  const [addModal, setAddModal] = useState(false);

  // function for select staff

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  // api function to get staff id
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'liststaff', config)
      .then((value) => setStaffId(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (staffId) {
      setResponse(staffId);
    }
  }, [staffId]);
  // function for store clients info

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      industry_type: userData.name,
      enabled: isChecked
    };
    axios
      .post(Url.BASEURL + 'addindustry', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
        } else {
          closeAddModal();
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  // state for add user data
  const [userData, setUserData] = useState({
    name: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('USERDATA>>>......', userData);
  };

  // function for selct/unselect active status of client

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listindustry');
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listindustry');
  }

  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }
  return (
    <Constant>
      <BreadScrum path="/listindustries" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Add Industry</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Industry added successfully
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addModal}
            onHide={closeAddModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Industry Add</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Are you sure, You want to add this Industry?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={submitHandler}
              >
                Yes
              </Button>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={() => closeAddModal(false)}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Industry Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="row">
                        <div className=" col-xs-12 col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Industry Name
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.name}
                              name="name"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Industry name"
                            />
                            {error && error.industry_type ? (
                              <div style={{ color: 'red' }}>
                                {error.industry_type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="col-md-6">
                            <div className="form-group active_status_flex align-items-center">
                              {/* <div className="col-1"> */}
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                              {/* </div> */}
                              <label
                                htmlFor="exampleInputEmail1"
                                className="m-0 pl-2"
                              >
                                Active Status
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={openAddModal}
                            // className="btn btn-primary"
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            // className="btn btn-primary"
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </Constant>
  );
};
export default AddIndustry;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Url from '../Element/Api';
import './BreadScrum.css';
import { useLocation } from 'react-router-dom';
const BreadScrum = (props) => {
  const [scrumvalue, setScrumValue] = useState([]);
  const location = useLocation();
  useEffect(() => {
    axios
      .get(Url.BASEURL + 'breadcrumbs' + location.pathname)
      .then((value) => {
        setScrumValue(value.data.breadcrumbs);
        console.log('breadscrum', value.data.breadcrumbs);
      })

      .catch((err) => console.log(err));
  }, [location]);

  console.log('scrumvalue', scrumvalue);
  // const titlesWithSlash = scrumvalue.map((e) => e.title).join(' / ');
  // console.log('title', titlesWithSlash);

  return (
    <div>
      <div style={{ paddingTop: '31px' }}>
        <div className="container-fluid">
          <div className="row mb-2">
            <div
              className="col-sm-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  {scrumvalue.map((e, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {index !== 0 && (
                        <span style={{ margin: '0 5px', marginTop: '11px' }}>
                          /
                        </span>
                      )}
                      {scrumvalue.length - 1 <= index ? (
                        <Link
                          // to={e.url}
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          <h2 style={{ margin: '0', paddingTop: '14px' }}>
                            {e.title}
                          </h2>
                        </Link>
                      ) : (
                        <Link
                          to={e.url}
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          <p
                            style={{
                              margin: '0',
                              paddingTop: '14px',
                              color: 'black'
                            }}
                          >
                            {e.title}
                          </p>
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {props.disabled ? (
                null
                // <Link>
                //   <button
                //     type="button"
                //     className="btn btn"
                //     style={{
                //       backgroundColor: props.bgColor,
                //       color: props.text_color
                //     }}
                //     disabled
                //   >
                //     {props.btn}
                //   </button>
                // </Link>
              ) : (
                <Link to={'/' + props.routepath}>
                  <button
                    type="button"
                    className="btn btn"
                    style={{
                      backgroundColor: props.bgColor,
                      color: props.text_color
                    }}
                  >
                    {props.btn}
                  </button>
                </Link>
              )}
            </div>
            {/* /.col */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BreadScrum;

import { useRef, useEffect, useState } from 'react';

import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Url from '../Element/Api';
import axios from 'axios';
import {
  setToken,
  setPermissions,
  setRole,
  setType
} from '../Redux/Action/authaction';
const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginResponse, setLoginResponse] = useState([]);
  // const [permission,setPermissions] =useState([]);
  // const [role,setRole] =useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checknew = useSelector((e) => console.log('eeeeeeeee', e));
  const emailInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // dispatch(permission)
  // console.log('permission..............................',permission);
  const submitHandle = (e) => {
    const params = {
      email: email,
      password: password
    };
    axios
      .post(Url.BASEURL + 'login', params)
      .then((value) => {
        if (value.data.status === 200) {
          // console.log('tokewn', value.data.token,value.data.permissions);
          setLoginResponse(value.data);
          dispatch(setPermissions(value.data.permissions));
          dispatch(setToken(value.data.token));
          dispatch(setType(value.data.type));
          navigate('/dashboard');
          // console.log('password:', loginResponse.token);
          localStorage.setItem('token', loginResponse.token);
          // console.log('tokennnnnn', dispatch(setToken(loginResponse.token)));
          localStorage.setItem('toast', true);
        } else {
          setError('please enter valid credentials');
        }
      })

      .catch((err) => console.log(err));
    localStorage.setItem('loginResponse', loginResponse);
    localStorage.setItem('toast', true);
  };

  // useEffect(() => {
  //   if (loginResponse.status === 200) {
  //     dispatch(setToken(loginResponse.token));
  //     // console.log('tokewn', token);
  //     console.log('password:', password);
  //     navigate('/dashboard');
  //     localStorage.setItem('token', loginResponse.token);
  //     console.log('tokennnnnn', dispatch(setToken(loginResponse.token)));
  //     localStorage.setItem('toast', true);
  //   } else {
  //     setError('please enter valid credentials');
  //   }
  // }, [loginResponse]);

  // funciton for hit api on press of enter key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitHandle();
    }
  };
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  return (
    <div className="hold-transition login-page">
      <img
        src="dist/img/symang_logo_za.png"
        alt="AdminLTE Logo"
        // className="brand-image img-circle elevation-3"
        style={{
          width: '224px',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          // marginLeft: '133px',
          marginBottom: '20px'
        }}
        //   style={{ opacity: '.8' }}
      />{' '}
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            {error ? (
              <div
                style={{
                  textAlign: 'center',
                  margin: '10px 0px',
                  background: '#FFCCCB',
                  color: 'red'
                }}
                className="alert "
                role="alert"
              >
                {error}
              </div>
            ) : null}
            <div>
              <div className="input-group mb-3">
                <input
                  ref={emailInputRef}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                  >
                    <span
                      className={`fas ${
                        showPassword ? 'fa-eye-slash' : 'fa-eye'
                      }`}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label style={{ marginLeft: '10px' }} htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                </div>
                {/* /.col */}
                <div className="col-4">
                  <button
                    onClick={submitHandle}
                    // type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Sign In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </div>
            {/* /.social-auth-links */}
            <p className="mb-1">
              <Link to="/forgot_password">I forgot my password</Link>
            </p>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  );
};
export default UserLogin;

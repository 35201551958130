import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import { Brand } from '../Admin/Data';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import axios from 'axios';
const ProductModal = () => {
  let { productsId } = useParams();

  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [categoryList, setCategorylist] = useState([]);
  const [brandList, setBrandlist] = useState([]);
  const [error, setError] = useState('');
  const [addRow, setAddRow] = useState([]);
  const [categorId, setCategoryId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [hsnId, setHsnId] = useState('');

  const [measurementId, setmeasurementId] = useState('');
  const [measurementList, setMeasuermentlist] = useState([]);
  const [labelText, setLabelText] = useState('Existing');
  const [productsListing, setProductsListing] = useState([]);
  const [hsnCode, setHsncode] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  const [hsnLabelText, setHsnLabelText] = useState('Existing');
  const [basePriceLabelText, setBasePriceLabelText] = useState('Existing');
  const [brandLabelText, setBrandLabelText] = useState('Existing');
  const [productId, setproductId] = useState('');
  const [basePriceId, setBasePriceId] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [basePrice, setBasePrice] = useState('');
  const [show, setShow] = useState(false);

  // const [myCategory, setMyCategoryListing] = useState([]);

  // console.log(
  //   'sdnnsdnsdlnbrandIdbrandIdbrandIdbrandIdbrandIdbrandId-------->>>',
  //   myCategory
  // );
  const [userData, setUserData] = useState({
    product_name: '',
    gst: '18%',
    base_price: '',
    brand: '',
    hsn_code: ''
  });
  const [measurementType, setMeasurementType] = useState('centimeter');
  const [modalIsOpen, setIsOpen] = useState(false);

  console.log('dsasduserDatauserData', userData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };
  // product name textlabel change function
  const toggleLabel = () => {
    if (labelText === 'New') {
      setLabelText('Existing');
      // setSelectedParentCategory('');
      // setSelectedSubcategory('');
      // setSubcategoryOptions([]);
    } else {
      setLabelText('New');
      // setSelectedParentCategory('');
      // setSelectedSubcategory('');
      // setSubcategoryOptions([]);
    }
  };
  // hsn code textlabel change function

  const toggleHsnLabel = () => {
    if (hsnLabelText === 'New') {
      setHsnLabelText('Existing');
    } else {
      setHsnLabelText('New');
    }
  };
  // base price textlabel change function
  const toggleBasePriceLabel = () => {
    if (basePriceLabelText === 'New') {
      setBasePriceLabelText('Existing');
    } else {
      setBasePriceLabelText('New');
    }
  };

  // brand textlabel change fucniton
  const toggleBrandLabel = () => {
    if (brandLabelText === 'New') {
      setBrandLabelText('Existing');
    } else {
      setBrandLabelText('New');
    }
  };
  // fetching category listing
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };

  //   axios
  //     .get(
  //       Url.BASEURL + 'allcategories?',

  //       config
  //     )
  //     .then((value) => setCategorylist(value.data.response))
  //     .catch((err) => console.log(err));
  // }, []);

  // api for products listing

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    //https://apirest.symang.in/api/edit-product/11

    axios
      .get(Url.BASEURL + 'edit-product/' + productsId, config)
      .then((value) => {
        console.log('check krte hai 111111');
        console.log('value?.datavalue?.data------>>>', value?.data.response);
        if (value.data) {
          setUserData(value?.data.response.product);
          setproductId(value?.data.response.product.product_name);
          setBrandId(value?.data.response.product.brand_id);
          setBasePrice(value?.data.response.product.base_price);
          // setproductId(value?.data.response.product.id);
          setSelectedParentCategory(value?.data.response.product.category_id);
          setSelectedSubcategory(value?.data.response.product.sub_category_id);
          setProductsListing(value?.data?.response.product_list);
          setHsnId(value?.data.response.product.hsn_code);
          setHsncode(value.data.response.hns_code);
          setProductCategory(value.data.response.category);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // fetching measurement type list
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };

  //   axios
  //     .get(
  //       Url.BASEURL + 'listmeasurement?',

  //       config
  //     )
  //     .then((value) => setMeasuermentlist(value.data.response.data))
  //     .catch((err) => console.log(err));
  // }, []);
  // fetching brand listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'listbrand?',

        config
      )
      .then((value) => setBrandlist(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);
  // submithandler to add product

  // const submitHandler = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   const params = {
  //     product_name:
  //       labelText === 'Existing' ? userData.pname : productId?.label,
  //     brand_name: '',
  //     category_name: categorId.value,
  //     class_thickness: '',
  //     hsn_code: '',
  //     base_price: '',
  //     gst: userData.gst
  //   };
  //   axios
  //     .post(Url.BASEURL + 'addproduct', params, config)
  //     .then((value) => {
  //       if (value && value.data.status == 200) {
  //         openModal();
  //         // navigate('/listproducts');
  //         toast('Product added Successfully');
  //         setAddRow(value.response);
  //         // setTimeout(() => {
  //         // }, 2000);
  //       } else {
  //         setError(value.data.errors);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // FUNCTION TO ADD PRODUCT

  // const MeasurementChangeHandler = (event) => {
  //   setId(event.target.value);
  // };

  // function to add category
  const CategoryChangeHandler = (event) => {
    setCategoryId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // function to add brand

  const BrandChangeHandler = (event) => {
    setBrandId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // functon to change measurement type
  const measurementChangeHandler = (event) => {
    setmeasurementId(event.target.value);
    console.log('idddddddd', event.target.value);
  };
  // function for close modal
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    navigate('/listproducts');
  }
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listproducts');
  };

  console.log('categoryListcategoryList,categoryList', measurementList);

  const transformCategoryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(
      ({ category_id, parent_categories_name, email }) => ({
        value: category_id.toString(),
        label: `${parent_categories_name}`
      })
    );

    return [selectOption, ...dataOptions];
  };

  const options = transformCategoryDataToOptions(categoryList);

  const transformBrandDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    // const select1Option = { value: 'unbranded', label: 'unbranded' };

    const dataOptions = data.map(({ id, bname, email }) => ({
      value: id.toString(),
      label: `${bname}`
    }));

    return [selectOption, ...dataOptions];
  };

  const brandOptions = transformBrandDataToOptions(brandList);

  // react_select for products name

  const transformProductDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, product_name }) => ({
      value: id.toString(),
      label: `${product_name}`
    }));

    return [selectOption, ...dataOptions];
  };
  const productOption = transformProductDataToOptions(productsListing);

  // hsn code dropdown function
  const transformHsncodeToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, code }) => ({
      value: id.toString(),
      label: `${code}`
    }));

    return [selectOption, ...dataOptions];
  };
  const hsncodeOption = transformHsncodeToOptions(hsnCode);
  console.log('hsnCodehsnCodehsnCode', hsnCode);
  // brand dropdown function
  // const transformBrandToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, brands }) => ({
  //     value: id.toString(),
  //     label: `${brands}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const brandOption = transformBrandToOptions(productCategory);

  // const transformMeasureDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, name }) => ({
  //     value: id.toString(),
  //     label: `${name}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // // function for close modal
  // function openModal() {
  //   setIsOpen(true);
  // }
  // function closeModal() {
  //   navigate('/listproducts');
  // }

  // const measureOptions = transformMeasureDataToOptions(measurementList);

  //function for category dropdown

  // const [selectedParentCategory, setSelectedParentCategory] = useState('');
  // const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  // useEffect(() => {
  //   // Filter subcategories based on the selected parent category
  //   if (selectedParentCategory) {
  //     const selectedCategory = productCategory.find(
  //       (category) => category.id.toString() === selectedParentCategory
  //     );
  //     const subcategoryData = selectedCategory?.sub_category || [];
  //     const subcategoryOptions = transformCategoryToOptions(subcategoryData);
  //     setSubcategoryOptions(subcategoryOptions);
  //   } else {
  //     // If no parent category is selected, clear the subcategory options
  //     setSubcategoryOptions([]);
  //   }
  // }, [selectedParentCategory, productCategory]);

  // const handleParentCategoryChange = (selectedOption) => {
  //   setSelectedParentCategory(selectedOption.value);
  // };
  // const transformCategoryToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, name }) => ({
  //     value: id.toString(),
  //     label: `${name}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const categoryOption = transformCategoryToOptions(productCategory);

  //

  const handleProductChange = (selectedOption) => {
    // Clear the states when a new product is selected
    setSelectedParentCategory('');
    setSelectedSubcategory('');
    setSubcategoryOptions([]);

    // Set the selected product
    setproductId(selectedOption);
  };
  useEffect(() => {
    console.log('check krte hain 22222', selectedParentCategory);
    // Filter subcategories based on the selected parent category
    if (selectedParentCategory) {
      const selectedCategory = productCategory.find(
        (category) => category.id.toString() == selectedParentCategory
      );
      console.log(
        'selectedParentCategory',
        selectedParentCategory,
        'selectedCategory',
        selectedCategory
      );
      const subcategoryData = selectedCategory?.sub_category || [];
      const subcategoryOptions = transformCategoryToOptions(subcategoryData);
      setSubcategoryOptions(subcategoryOptions);
    } else {
      // If no parent category is selected, clear the subcategory options
      setSubcategoryOptions([]);
      // Also clear the selected subcategory and base price when the parent category changes
      setSelectedSubcategory('');
      setBasePrice('');
    }
  }, [selectedParentCategory, productCategory]);

  useEffect(() => {
    // Update the base price when the selected subcategory changes
    if (selectedSubcategory) {
      // setBasePrice(selectedSubcategory.base_price);
    } else {
      // If no subcategory is selected, clear the base price
      setBasePrice('');
    }
  }, [selectedSubcategory]);

  const handleParentCategoryChange = (selectedOption) => {
    console.log('Selected Parent Category:', selectedOption);
    setSelectedParentCategory(selectedOption.value);
    setSelectedSubcategory('');
  };

  const handleSubcategoryChange = (selectedOption) => {
    console.log('Selected Subcategory:', selectedOption);
    setSelectedSubcategory(selectedOption.value);
  };

  const transformCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, base_price }) => ({
      value: id.toString(),
      label: `${name}`,
      base_price: base_price ? base_price.price : null
    }));

    return [selectOption, ...dataOptions];
  };

  const categoryOption = transformCategoryToOptions(productCategory);

  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };

  console.log(
    'hsnId',
    hsnId,
    'productId',
    productId,
    'brandId',
    brandId,
    'selectedParentCategory',
    selectedParentCategory,
    'selectedSubcategory',
    selectedSubcategory,
    'basePrice',
    basePrice
  );

  // const transformBasePriceDataToOptions = (data) => {
  //     const selectOption = { value: '', label: 'Select' };
  //     const dataOptions = data.map(({ base_price }) => ({
  //       value: base_price.toString(),
  //       label: `${base_price}`
  //     }));

  //     return [selectOption, ...dataOptions];
  //   };

  //   const basePriceOptions = transformBasePriceDataToOptions(productsListing);

  // const transformBasePriceDataToOptions = (data) => {
  //   const selectOption = { value: '', label: 'Select' };
  //   const dataOptions = data.map(({ id, base_price }) => ({
  //     value: id.toString(),
  //     label: `${base_price}`
  //   }));

  //   return [selectOption, ...dataOptions];
  // };
  // const basePriceOptions = transformBasePriceDataToOptions(productsListing);

  // Api function for add products
  // const AddProducts = () => {
  //   const formData = new FormData();
  //   formData.append('brand_id', brandId ? brandId.value : userData.brand);
  //   formData.append(
  //     'product_name',
  //     productId ? productId.label : userData.pname
  //   );
  //   formData.append('category_id', selectedParentCategory);
  //   formData.append('sub_category_id', selectedSubcategory.value);
  //   formData.append('hsn_code', hsnId ? hsnId.label : userData.hsncode);
  //   formData.append('base_price', basePrice);
  //   formData.append('gst', userData.gst);

  //   const config = {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization: `Bearer ${token}`
  //     }
  //   };

  //   axios
  //     .post(Url.BASEURL + 'store-new-product', formData, config)
  //     .then((value) => {
  //       if (value && value.data.status === 200) {
  //         // navigate('/listproducts');
  //         toast('Product added Successfully');
  //         // setAddRow(value.response);
  //         // setTimeout(() => {
  //         // }, 2000);
  //       } else {
  //         setError(value.data.errors);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
  //   //https://apirest.symang.in/api/base-price?category_id=13&sub_category_id=15&product_id=11
  //   axios
  //     .get(
  //       Url.BASEURL +
  //         'base-price?' +
  //         'category_id=' +
  //         selectedParentCategory +
  //         '&sub_category_id=' +
  //         selectedSubcategory +
  //         // (id !== '' ? '&client_id=' + id.value : '') +
  //         '&product_id=' +
  //         productId
  //         ? productId
  //         : userData.product_name,
  //       config
  //     )
  //     .then((value) => {
  //       if (value && value.data.status == 200) {
  //         console.log(
  //           '------------------------------->>>>>>data',
  //           value.data.response.base_price
  //         );
  //         if (value.data.response.base_price != null) {
  //           setBasePrice(value.data.response.base_price.price);
  //         } else {
  //           setBasePrice('0');
  //         }
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedParentCategory, selectedSubcategory, productId]);

  useEffect(() => {
    if (selectedParentCategory && selectedSubcategory && productId) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
      axios
        .get(
          Url.BASEURL +
            'base-price?' +
            'category_id=' +
            selectedParentCategory +
            '&sub_category_id=' +
            selectedSubcategory +
            // (id !== '' ? '&client_id=' + id.value : '') +
            '&product_id=' +
            productId.value,

          config
        )
        .then((value) => {
          if (value && value.data.status == 200) {
            console.log(
              '------------------------------->>>>>>data',
              value.data.response.base_price
            );
            if (value.data.response.base_price != null) {
              setBasePrice(value.data.response.base_price.price);
            } else {
              setBasePrice('0');
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedParentCategory, selectedSubcategory, productId]);
  console.log('userData.gst,userData.gst ', userData.gst);

  const UpdateProducts = () => {
    const formData = new FormData();
    formData.append('brand_id', brandId ? brandId : userData.brand);
    formData.append(
      'product_name',
      productId ? userData.product_name : productId
      // productId ? productId : userData.product_name
    );
    formData.append('category_id', selectedParentCategory);
    formData.append('sub_category_id', selectedSubcategory);
    formData.append('hsn_code', hsnId ? hsnId : userData.hsn_code);
    formData.append('base_price', basePrice ? basePrice : userData.base_price);
    formData.append('gst', userData.gst);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .post(Url.BASEURL + 'update-new-product/' + productsId, formData, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // navigate('/listproducts');
          setShow(true);

          // toast('Product added Successfully');
          // setAddRow(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  const HandleBrand = (selectedOption) => {
    console.log('000000000selectedOption', selectedOption);
    setBrandId(selectedOption.value);
    console.log('selectedOption', selectedOption);

    // Your additional logic here
  };
  return (
    <Constant>
      <BreadScrum path="/listproducts" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Client Added</Modal.Title>
            </Modal.Header>
            <Modal.Body>Client has been added successfully</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={closeModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Product Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              HSN Code
                            </label>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleHsnLabel}
                            ></div>
                          </div>
                          {hsnLabelText === 'Existing' ? (
                            <input
                              disabled
                              onChange={handleInputChange}
                              value={userData.hsn_code}
                              name="hsn_code"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter product's HSN code."
                              // className={`form-control ${userData.hsn_code && 'disabled-text'}`} // Conditionally apply class
                              style={{ color: 'GrayText' }}
                            />
                          ) : (
                            <Select
                              defaultValue={hsnId}
                              // value={hsncodeOption.find((e) => e.code == hsnId)}
                              onChange={setHsnId}
                              options={hsncodeOption}
                              placeholder="Select HSN code"
                              isDisabled
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Product Name
                            </label>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={toggleLabel}
                            ></div>
                          </div>
                          {labelText === 'Existing' ? (
                            <input
                              disabled
                              onChange={handleInputChange}
                              value={userData.product_name}
                              name="product_name"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter product namessss (e.g.,Steel Bars)."
                              style={{color:'GrayText'}}

                            />
                          ) : (
                            <Select
                              defaultValue={productId}
                              // value={productOption.find(
                              //   (e) => e.value == productId
                              // )}
                              // onChange={setproductId}
                              onChange={handleProductChange}
                              options={productOption}
                              placeholder="Select product"
                              isDisabled
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">GST</label>
                          <input
                            disabled
                            onChange={handleInputChange}
                            maxLength={3}
                            value={userData.gst}
                            name="gst"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter GST (e.g.,18)."
                            style={{color:'GrayText'}}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <label
                            style={{
                              // display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            htmlFor="exampleInputEmail1"
                          >
                            Brand Name
                          </label>
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={toggleBrandLabel}
                          >
                            {/* {brandLabelText} */}
                          </div>
                        </div>
                        {/* {brandLabelText === 'Existing' ? (
                          <input
                            onChange={handleInputChange}
                            value={userData.brand}
                            name="brand"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Brand Name ."
                          />
                        ) : ( */}
                        {console.log(
                          'brandOptions.find((e) => e.value == brandId',
                          brandOptions.find((e) => e.value == brandId),
                          'brandId',
                          brandId
                        )}
                        <Select
                          isDisabled
                          // defaultValue={brandId}
                          value={brandOptions.find((e) => e.value == brandId)}
                          onChange={setBrandId}
                          options={brandOptions}
                          placeholder="Select brand"
                        />
                        {/* )} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Category</label>

                          <Select
                            isDisabled
                            value={categoryOption.find(
                              (e) => e.value == selectedParentCategory
                            )}
                            // value={selectedParentCategory}
                            onChange={handleParentCategoryChange}
                            options={categoryOption}
                            placeholder="Select parent category"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Sub Category
                            </label>
                          </div>

                          <Select
                            isDisabled
                            value={subcategoryOptions.find(
                              (e) => e.value == selectedSubcategory
                            )}
                            onChange={handleSubcategoryChange}
                            options={subcategoryOptions}
                            placeholder="Select subcategory"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                              htmlFor="exampleInputEmail1"
                            >
                              Base Price
                            </label>
                          </div>

                          <input
                            // onChange={handleInputChange}
                            // value={basePrice? basePrice : userData.base_price}
                            onChange={handleBasePriceInputChange}
                            disabled
                            value={
                              basePrice !== null
                                ? basePrice
                                : userData.base_price
                            }
                            name="base_price"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter price ."
                            style={{color:'GrayText'}}

                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                  <div className="row">
                    <div
                      className="col-4"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginLeft: '10px',
                        marginBottom: '10px'
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop={'static'}
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: '#1e336a', color: 'white', border: 'none' }}
        >
          <Modal.Title>Upadate Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '20px' }}>
          Product Updated Successfully
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#1E3F6A', border: 'none' }}
            onClick={() => {
              setShow(false);
              navigate('/listproducts');
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Constant>
  );
};
export default ProductModal;

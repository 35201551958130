import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import Select from 'react-select';
import axios from 'axios';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ManageSubCategory = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const token = useSelector((state) => state.token);
    const [subCategories, setSubCategories] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [subCategory, setSubCategory] = useState('')
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [error, setError] = useState({})



    // function to check uncheck active status

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked ? 1 : 0);
    };

    function openAddModal() {
        setAddModal(true);
    }
    function closeAddModal() {
        setAddModal(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        navigate('/listsubcategory');
    }
    // function for reload page
    const reloadPage = () => {
        navigate(`/listsubcategory`);
    };
    //API for parent categoris
    useEffect(() => {
        getCategoryList();
        getSubCategory();
    }, [])
    const getCategoryList = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.get(Url.BASEURL + 'parentcategories', config)

            if (response.data.status === 200) {
                setSubCategories(response.data.response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getSubCategory = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.get(Url.BASEURL + 'edit-sub-category/' + id, config)

            if (response.data.status === 200) {
                setSubCategory(response.data.response.subcategoryName[0].name)
                setCategoryId(response.data.response.subcategoryName[0].parent_category)
                setIsChecked(response.data.response.subcategoryName[0].enabled)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //maping subcategories in value and label format
    const transformSubCategoriesToOptions = (data) => {
        const dataOptions = data.map(event => ({
            value: event.id.toString(),
            label: event.name
        }));
        return [...dataOptions];
    };

    const subCategoriesOptions = transformSubCategoriesToOptions(subCategories);


    //Api for add sub categories
    const submitHandler = async () => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Methods': '*'
                }
            };
            const params = {
                parent_category: categoryId,
                name: subCategory,
                enabled: isChecked
            };
            const response = await axios.put(Url.BASEURL + 'update-sub-category/' + id, params, config)
            if (response.data.status === 200) {
                setLoading(false)
                openModal();
                navigate('')
            } else {
                setError(response.data.response)
                setLoading(false);
                closeAddModal();
            }

        } catch (err) {

            console.log(err);
        }
    }
    return (
        <Constant>
            <BreadScrum path={`/editsubcategory/${id}`} />
            <div className="wrapper">
                <Modal
                    show={modalIsOpen}
                    onHide={closeModal}
                    backdrop={'static'}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Sub-Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Sub-Category Updated successfully</Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={closeModal}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={addModal}
                    onHide={closeAddModal}
                    backdrop={'static'}
                    keyboard={false}
                    centered
                >
                    <Modal.Header
                        closeButton
                        style={{
                            backgroundColor: '#1e336a',
                            color: 'white',
                            border: 'none'
                        }}
                    >
                        <Modal.Title>Update Sub-Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: '20px' }}>
                        Are you sure, You want to update this Sub-Category?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                            onClick={submitHandler}
                        >
                            Yes
                        </Button>
                        <Button
                            style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                            onClick={() => closeAddModal(false)}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <section className="content section_padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Sub-Category Information</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    <div>
                                        <div className="card-body">
                                            <div className="row px-4 mb-3">
                                                <div className="col-6 form-group p-0 pe-1">
                                                    <label
                                                        htmlFor="exampleInputEmail1"
                                                        className="required"
                                                    >
                                                        Sub-Category Name
                                                    </label>
                                                    <input
                                                        onChange={(e) => setSubCategory(e.target.value)}
                                                        value={subCategory}
                                                        name="subcategory"
                                                        type="text"
                                                        className="form-control rounded-1"
                                                        id="exampleInputEmail1"
                                                        placeholder="Enter Sub-Category Name"
                                                    />
                                                    {error && error.name ? (
                                                        <div style={{ color: 'red' }}>{error.name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-6 form-group p-0 ps-1">
                                                    <label
                                                        htmlFor="exampleInputEmail1"
                                                        className="required"
                                                    >
                                                        Select Parent Category
                                                    </label>
                                                    <Select
                                                        value={subCategoriesOptions.find((subcat) => subcat.value == categoryId)}
                                                        onChange={(e) => setCategoryId(e.value)}
                                                        options={subCategoriesOptions}
                                                        placeholder="Select Parent Category"
                                                        maxMenuHeight="180px"

                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                width: '220px',
                                                                zIndex: 100
                                                            })
                                                        }}
                                                    />
                                                    {error && error.parent_category ? (
                                                        <div style={{ color: 'red' }}>{error.parent_category}</div>
                                                    ) : null}
                                                </div>

                                                <div className="form-group active_status_flex m-0 align-items-center pl-1">

                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />

                                                    <label
                                                        htmlFor="exampleInputEmail1"
                                                        className="m-0 pl-3"
                                                    >
                                                        Active Status
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row px-4">
                                                <div
                                                    className="col-4 p-0"
                                                    style={{
                                                        display: 'flex',


                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    <div className="">
                                                        <button
                                                            onClick={openAddModal}
                                                            className="btn text-white px-4"
                                                            style={{ backgroundColor: '#1e336a' }}
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                    <div className="" style={{ marginLeft: '20px' }}>
                                                        <button

                                                            onClick={reloadPage}
                                                            className="btn text-black px-4"
                                                            style={{ backgroundColor: '#DCDCDC' }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Constant>
    )
}

export default ManageSubCategory
import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { userData } from '../Admin/Data';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
const ManageAdminUsers = () => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const [teamManagerId, setTeamManagerId] = useState('');
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [userData, setUserData] = useState([]);
  const [designationId, setdesignationId] = useState('');
  const [response, setResponse] = useState([]);
  const [response2, setResponse2] = useState([]);
  const [teamManagers, setTeamManagers] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState('');
  const [updateUserData, setUpdateUserData] = useState([]);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'getroles', config)
      .then((value) => setDesignations(value.data.response))
      .catch((err) => console.log(err));
    console.log('teammanagerdata', designations);
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: userId
    };
    axios
      .get(Url.BASEURL + 'edituser/' + userId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log(
            'value.data.response==>>',
            value.data.response.admin_right
          );
          setUserData(value.data.response);
          setId(value.data.response.team_manager);
          setId2(value.data.response.admin_right);
          setIsChecked(value.data.response.enabled);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  // function to get selected Manager

  const UserChangeHandler = (event) => {
    setId(event.target.value);
  };
  // function to get selected designation

  // getting allteamanagers data throuh axios

  // function for active update
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // fucntion to store user data

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      name: userData.name,
      email: userData.email,
      enabled: isChecked,
      admin_right: id2,
      type:'admin',
    };
    axios.defaults.headers.put['Content-Type'] =
      'application/json;charset=utf-8';
    axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
    axios
      .put(Url.BASEURL + 'updateuser/' + userId, params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          navigate('/listadminusers');
          // toast('Client added Successfully');
          setUpdateUserData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listadminusers');
  };
  const designationChangeHandler = (event) => {
    setId2(event.target.value);
  };
  return (
    <Constant>
      <BreadScrum routePath={'manageadminuser'} heading={'Update Admin User'} />{' '}
      <div className="wrapper">
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Update Admin User</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">User Name</label>
                        <input
                          onChange={handleInputChange}
                          value={userData.name}
                          name="name"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter User Name"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          onChange={handleInputChange}
                          value={userData.email}
                          name="email"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <input
                          onChange={handleInputChange}
                          value={userData.pname}
                          name="pname"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Select Department
                          </label>

                          <select
                            id="select2"
                            value={id2}
                            className=" form-control"
                            onChange={designationChangeHandler}
                          >
                            {designations.map((e) => {
                              return (
                                <option value={e.id}>{e.role_name}</option>
                              );
                            })}
                            {console.log}
                          </select>
                          {error && error.staff_id ? (
                            <div style={{ color: 'red' }}>{error.staff_id}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group active_status_flex">
                        <label htmlFor="exampleInputEmail1">
                          Active Status
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group active_status_flex">
                              <input type="checkbox" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={submitHandler}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            className="btn btn-primary"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default ManageAdminUsers;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const StaffDailyTaskReport = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // January is 0
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const currentYear = currentDate.getFullYear();
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const token = useSelector((state) => state.token);

  useEffect(() => {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL +
        'list-new-month-dailyStatusReport?' +
        'month=' +
        selectedMonth +
        '&' +
        'year=2024',
        config
      )
      .then((value) => setData(value.data))
      .catch((err) => console.log(err));
  }, [selectedMonth, selectedYear]);

  // Example: Get the total number of days in February 2024
  // const getDaysInMonth = (year, month) => {
  //   return new Date(year, month + 1, 0).getDate();
  // };
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const year = 2024;
  const month = 1; // Note: Months in JavaScript are zero-indexed, so 1 represents February
  // const totalDays = getDaysInMonth(year, month);

  // // Generate an array of table headings
  // const tableHeadings = Array.from(
  //   { length: totalDays },
  //   (_, index) => index + 1
  // );
  const totalDays = getDaysInMonth(selectedYear, selectedMonth);
  const tableHeadings = Array.from(
    { length: totalDays },
    (_, index) => index + 1
  );
  console.log('totalDaystotalDays');
  // array for mnonths of the year
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
  ];

  console.log(months);
  const startYear = 2000;
  const endYear = 2040;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  // Now you can use the `day` variable globally

  // Event handler for month change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event) => {
    // setSelectedMonth(event.target.value);
    setSelectedYear(parseInt(event.target.value, 10));
  };
  return (
    <div>
      <Constant>
        <BreadScrum
          btn_name="Add Staff"
          // routepath={'addstaff'}
          heading={'Manage Attendance'}
          //   btn={'Add Staff'}
          // bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '}
        <div className="">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body ">
                <div className="row">
                  <div className="col-md-6">
                    <p className="card-title mb-0 text-left mx-2 mt-1">
                      Manage Daily Status report
                    </p>
                  </div>
                  <div className="col-md-6 text-right d-flex justify-content-end">
                    <div className="card-title mx-2 mt-1"></div>
                    <select
                      className="col-md-4 filter form-control form-control-sm actdeact_prod mx-2"
                      id="months"
                      style={{ width: '25%' }}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>

                    {/* <div className="card-title mx-2 mt-1"></div>
                    <select
                      className="col-md-4 filter form-control form-control-sm actdeact_prod mx-2"
                      id="months"
                      style={{ width: '25%' }}
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="">Select Year</option>
                      {years.map((e) => (
                        // console.log('yearrr',e)
                        <option key={e.id} value={e.id}>
                          {e.label}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <form action="#" method="get">
                        <div className="table-responsive">
                          <table style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th className="text-left">Employee Name</th>
                                <th className="text-left">Employee Email</th>

                                {tableHeadings.map((day) => (
                                  // console.log('check---------->>>',day)
                                  <th key={day}>{day}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((employee) => (
                                <tr key={employee.id}>
                                  <td>{employee.name}</td>
                                  <td>{employee.email}</td>

                                  {tableHeadings.map((day) => (
                                    <td>
                                      <Link
                                        to={`/get_report/${employee.id}?date=${day}&month=${selectedMonth}&year=2024`}
                                        // to={'/get_report/' + employee.id}
                                        key={`${employee.id}-${day}`}
                                        className={
                                          employee.status[day] === 'Yes'
                                            ? 'green'
                                            : 'red'
                                        }
                                      >
                                        {employee.status[day]}
                                      </Link>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 grid-margin stretch-card">
                            <div className="template-demo"></div>
                          </div>
                        </div>
                        <div id="butns" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default StaffDailyTaskReport;

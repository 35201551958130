import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import StaffModal from './Components/ManageStaff/StaffModal';
// import Constant from './Components/Admin/AdminLayout';
import { DashBoard } from './Components/DashBoard/DashBoard';
import ClientsInfo from './Components/ManageClient/ClientsInfo';
// import BreadScrum from './Components/Admin/BreadScrum';
import AddClient from './Components/ManageClient/AddClient';
import StaffInfo from './Components/ManageStaff/StaffInfo';
import ProductsInfo from './Components/ManageProducts/ProductsInfo';
import CategoryInfo from './Components/ManageCategories/CategoriesInfo';
import AddProducts from './Components/ManageProducts/AddProducts';
import AddStaff from './Components/ManageStaff/AddStaff';
import AddCategory from './Components/ManageCategories/AddCategory';
import UserLogin from './Components/Auth/UserLogin';
import UpdateCategory from './Components/ManageCategories/UpdateCategory';
import UpdateClients from './Components/ManageClient/UpdateClients';
import UpdateProducts from './Components/ManageProducts/UpdateProducts';
import UpdateStaff from './Components/ManageStaff/UpdateStaff';
import BrandsListing from './Components/ManageBrands/BrandsInfo';
import AddBrand from './Components/ManageBrands/AddBrand';
import ManageBrands from './Components/ManageBrands/ManageBrands';
import ManageClients from './Components/ManageClient/ManageClients';
import ManageCategory from './Components/ManageCategories/ManageCategory';
import ManageProducts from './Components/ManageProducts/ManageProducts';
import AdminRights from './Components/AdminRights/AdminRights';
import ManageAdminRights from './Components/AdminRights/ManageAdminRights';
import AddAdminRights from './Components/AdminRights/AddAdminRights';
import AdminUsers from './Components/AdminUsers/AdminUsers';
import AddAdminUser from './Components/AdminUsers/AddAdminUser';
import Test from './Components/test';
import ManageStaff from './Components/ManageStaff/ManageStaff';
import ManageDesignation from './Components/ManageDesignation/ManageDesignation';
import AddDesignation from './Components/ManageDesignation/AddDesignation';
import DesignationsInfo from './Components/ManageDesignation/DesignationsInfo';
import ManageAdminUsers from './Components/AdminUsers/ManageAdminUsers';
import IndustryInfo from './Components/ManageIndustry/industryInfo';
import ManageIndustry from './Components/ManageIndustry/ManageIndustry';
import AddIndustry from './Components/ManageIndustry/addIndustry';
import GuestGuard from './Components/Element/GuestGuard';
import SalesReport from './Components/SalesReport/SalesReport';
import ManageLead from './Components/ManageLead/ManageLeads';
import ManageDesignations from './Components/ManageDesignation/ManageDesignation';
import AddEditClient from './Components/AddEditClient/AddEditClient';
import React, { useEffect, useState } from 'react';
import ForgotPassword from './Components/Auth/ForgotPassword';
import Otp from './Components/Auth/Otp';
import ManageAttendance from './Components/ManageAttendance/Attendance';
import SetNewPassword from './Components/Auth/SetNewPassword';
import ClientModal from './Components/ManageClient/ClientModal';
import ProductModal from './Components/ManageProducts/ProductModal';
import StaffDailyTaskReport from './Components/StaffDailyStatusReport/StaffdailytaskReport';
import GetStaffReport from './Components/StaffDailyStatusReport/GetStaffReport';
import ViewCategory from './Components/ManageCategories/ViewCategory';
import ViewBrand from './Components/ManageBrands/ViewBrand';
import ViewDesignation from './Components/ManageDesignation/ViewDesignation';
import ViewIndustry from './Components/ManageIndustry/ViewIndustry';
import GetAttendanceReport from './Components/ManageAttendance/GetAttendanceReport';
import SalesReportDetails from './Components/SalesReport/SalesReportDetails';
import StaffRequests from './Components/StaffRequest/StaffRequests';
import Subcategoriesinfo from './Components/subcategories/Subcategoriesinfo';
import AddSubCategory from './Components/subcategories/AddSubCategory';
import SubcategoryView from './Components/subcategories/SubcategoryView';
import ManageSubCategory from './Components/subcategories/ManageSubCategory';

function App() {
  //state for route management
  // const [nestedPermissions, setNestedPermission] = useState([])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            index={true}
            path="/"
            element={
              <GuestGuard>
                <UserLogin />{' '}
              </GuestGuard>
            }
          />
          {''}
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/listclients" element={<ClientsInfo />} />
          <Route path="/addclient" element={<AddClient />} />
          <Route path="/liststaff" element={<StaffInfo />} />
          <Route path="/listproducts" element={<ProductsInfo />} />
          <Route path="/sales_report" element={<SalesReport />} />
          <Route
            path="/listcategory/:parentCategoryId"
            element={<CategoryInfo />}
          />
          <Route
            path="/sales_report_details"
            element={<SalesReportDetails />}
          />
          <Route
            path="/sales_report_details/:leadId"
            element={<SalesReportDetails />}
          />
          <Route
            path="/sales_report_details/:leadId/:staffId"
            element={<SalesReportDetails />}
          />
          {/* <Route
            path="/listcategory/:parentCategoryId/:categoryId"
            element={<CategoryInfo />}
          /> */}
          <Route path="/addproduct" element={<AddProducts />} />
          <Route path="/addclient" element={<AddClient />} />
          <Route path="/addstaff" element={<AddStaff />} />
          <Route
            path="/managecategory/:categoryId/:subCategoryId"
            element={<ManageCategory />}
          />
          <Route path="/manageclients" element={<UpdateClients />} />
          <Route path="/manageproducts" element={<UpdateProducts />} />
          <Route path="/managestafff" element={<UpdateStaff />} />
          <Route
            path="/addcategory/:parentCategoryId"
            element={<AddCategory />}
          />
          {/* <Route
          path="/addcategory/:parentCategoryId/:subCategoryId"
          element={<AddCategory />}
        /> */}
          <Route path="/listbrands" element={<BrandsListing />} />
          <Route path="/addbrand" element={<AddBrand />} />
          <Route path="/managebrand/:brandId" element={<ManageBrands />} />
          <Route path="/viewbrand/:brandId" element={<ViewBrand />} />

          <Route path="/manageproducts" element={<ManageProducts />} />
          <Route path="/manageclient" element={<ManageClients />} />
          <Route path="/manageclient/:clientId" element={<ManageClients />} />
          <Route path="/manage_user_rights" element={<AdminRights />} />
          <Route path="/manage_user _rights" element={<ManageAdminRights />} />
          <Route
            path="/manage_user_rights/:adminRightId"
            element={<ManageAdminRights />}
          />
          <Route path="/add_admin_rights" element={<AddAdminRights />} />
          <Route path="/adminusers" element={<AdminUsers />} />
          <Route path="/add_admin_user" element={<AddAdminUser />} />
          <Route path="/managestaff" element={<ManageStaff />} />
          <Route path="/managestaff/:staffId" element={<ManageStaff />} />
          <Route path="/listdesignation" element={<DesignationsInfo />} />
          <Route path="/managecategory" element={<ManageCategory />} />
          <Route
            path="/managecategory/:categoryId"
            element={<ManageCategory />}
          />
          <Route path="/viewcategory" element={<ViewCategory />} />
          <Route
            path="/viewcategory/:categoryId/:subCategoryId"
            // path="/viewcategory/:categoryId"
            element={<ViewCategory />}
          />
          <Route path="/staffDetails/:staffId" element={<StaffModal />} />

          <Route
            path="/managedesignation/:desgnationId"
            element={<ManageDesignations />}
          />
          <Route path="/managedesignation" element={<ManageDesignations />} />
          <Route
            path="/viewdesignation/:desgnationId"
            element={<ViewDesignation />}
          />
          <Route path="/viewdesignation" element={<ViewDesignation />} />
          <Route path="/adddesignation" element={<AddDesignation />} />
          <Route path="/test" element={<Test />} />
          <Route path="/listadminusers" element={<AdminUsers />} />
          <Route
            path="/manageadminuser/:userId"
            element={<ManageAdminUsers />}
          />
          <Route path="/listindustry" element={<IndustryInfo />} />
          <Route
            path="/manageindustry/:industryId"
            element={<ManageIndustry />}
          />
          <Route path="/viewindustry/:industryId" element={<ViewIndustry />} />
          <Route path="/addindustry" element={<AddIndustry />} />
          <Route path="/manageproduct" element={<ManageProducts />} />
          <Route path="/manageleads" element={<ManageLead />} />
          <Route path="/manageleads/:current_report" element={<ManageLead />} />
          <Route path="/test" element={<AddEditClient />} />
          <Route path="/test/:clientsId" element={<AddEditClient />} />
          <Route
            path="/manageproduct/:productsId"
            element={<ManageProducts />}
          />
          <Route path="/productDetail/:productsId" element={<ProductModal />} />

          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/listattendance" element={<ManageAttendance />} />
          <Route path="/new_password" element={<SetNewPassword />} />
          <Route path="/clientDetails" element={<ClientModal />} />
          <Route path="/clientDetails/:clientId" element={<ClientModal />} />
          <Route path="/listreport" element={<StaffDailyTaskReport />} />
          <Route path="/staff_request" element={<StaffRequests />} />

          <Route path="/otp" element={<Otp />} />
          <Route path="/get_report" element={<GetStaffReport />} />
          <Route path="/get_report/:getstaffId" element={<GetStaffReport />} />
          <Route
            path="/get_attendance_report"
            element={<GetAttendanceReport />}
          />
          <Route
            path="/get_attendance_report/:getstaffId"
            element={<GetAttendanceReport />}
          />

          {/* subcategories */}

          <Route path="/listsubcategory" element={<Subcategoriesinfo />} />
          <Route path="/addsubcategory" element={<AddSubCategory />} />
          <Route path="/subcategoryDetail/:id" element={<SubcategoryView />} />
          <Route path="/editsubcategory/:id" element={<ManageSubCategory />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
// import './ManageStaff.css';
import Select from 'react-select';

const StaffInfo = () => {
  const [staffData, setStaffData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [searchClient, setSearchClient] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);
  const [staffLists, setStaffLists] = useState([]);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const modalClose = () => setShowAcceptModal(false);
  const modalShow = () => setShowAcceptModal(true);
  const modalRejectClose = () => setShowRejectModal(false);
  const modalRejectShow = () => setShowRejectModal(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [statusId, setStatusId] = useState('');
  const [statusKey, setStatusKey] = useState('')
  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..................------->>>>>>', permissions);
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = String(todayDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month because month is zero-indexed
  const date = String(todayDate.getDate()).padStart(2, '0');

  // Form the formatted date string in 'YYYY-MM-DD' format
  const formattedNewDate = `${year}-${month}-${date}`;
  console.log('formattedDateformattedDate', formattedNewDate)
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'name',
    orderby: 'asc'
  });

  const handleDateChange = (date) => {
    // If selectedDate2 is set and is before the new selected date, reset both dates
    if (selectedDate2 && date >= selectedDate2) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate(date);
    }
  };

  const handleDateChange2 = (date) => {
    // If selectedDate is set and is after the new selected date, reset both dates
    if (selectedDate && date <= selectedDate) {
      // setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate2(date);
    }
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  const token = useSelector((state) => state.token);
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    isArrowObj,
    id, statusId, pageCount
  ]);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'list-staffEditRequest?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&staff_id=' +
        (id !== '' ? '&staff_id=' + id.value : '') +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2 +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setStaffData(value.data.response.data) &
          console.log('staffdataaaaaaa', value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (staffData) {
      setResponse(staffData);
    }
  }, [staffData, searchClient]);

  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhStaffByName(searchStaffByName);
  };

  // function for delete row
  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deletestaff/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };

  const RequestAcceptReject = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      request_status: statusKey,
      request_date_accpeted: formattedNewDate
    };

    axios
      .put(
        Url.BASEURL + 'updateStatus-staffEditRequest/' + statusId,
        params,
        config
      )
      .then((value) => {
        getBrandList()
        modalClose();
        modalRejectClose();
        // setDeleteRow(value.data.response);
        // getBrandList();
      })
      .catch((err) => {
        console.log(err);
        getBrandList()
        modalClose();
        modalRejectClose();
      });
  };

  // const StaffChangeHandler = (event) => {
  //   setId(event.target.value);
  // };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffmanagerlist', config)
      .then(
        (value) =>
          setStaffLists(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  // to format date
  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1.
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    }
    return '';
  };
  const transformClientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));
    return [selectOption, ...dataOptions];
  };
  const staffOptions = transformClientDataToOptions(staffLists);
  const handleClearInput = () => {
    setSearchClient('');
  };
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };



  return (
    <>
      <Constant>
        <BreadScrum
          btn_name="Add Staff"
          //   routepath={'addstaff'}
          heading={'Manage Staff'}
          btn={'Add Staff'}
          //   bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '}
        <div>
          <div>
            <Modal
              show={showAcceptModal}
              onHide={modalClose}
              backdrop={'static'}
              keyboard={false}
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  backgroundColor: '#1e336a',
                  color: 'white',
                  border: 'none'
                }}
              >
                <Modal.Title>Request Accept</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '20px' }}>
                Are you sure, You want to Accept this Request?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                  onClick={RequestAcceptReject}
                >
                  Yes
                </Button>
                <Button
                  style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                  onClick={() => modalClose(false)}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <Modal
              show={showRejectModal}
              onHide={modalRejectClose}
              backdrop={'static'}
              keyboard={false}
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  backgroundColor: '#1e336a',
                  color: 'white',
                  border: 'none'
                }}
              >
                <Modal.Title>Request Reject</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '20px' }}>
                Are you sure, You want to Reject this Request?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                  onClick={RequestAcceptReject}
                >
                  Yes
                </Button>
                <Button
                  style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                  onClick={() => modalRejectClose(false)}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{
                        overflow: 'hidden',
                        minHeight: isCalendarOpen ? '320px' : null
                      }}
                    >
                      <div
                        className="search-field d-none d-md-block"
                        style={{ marginBottom: '13px' }}
                      >
                        {/* <div className="">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <div
                                className=" position-relative"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  border: '1px solid black'
                                }}
                              >
                                <input
                                  style={{
                                    height: 30,
                                    paddingRight: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchClient(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                  value={searchClient}
                                />
                                {searchClient && (
                                  <span
                                    className="position-absolute top-50 end-0 translate-middle-y"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: 10
                                    }}
                                    onClick={handleClearInput}
                                  >
                                    ✕
                                  </span>
                                )}
                              </div>
                            </div>

                            <div>
                              <label className="p-2 m-0">From :</label>
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                onKeyDown={(e) =>
                                  e.key === 'Backspace' && e.preventDefault()
                                }
                                maxDate={new Date()}
                                isClearable={true}
                                onCalendarOpen={toggleCalendar}
                                onCalendarClose={toggleCalendar}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                            <div
                            // style={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <label className="p-2 m-0">To :</label>
                              <DatePicker
                                selected={selectedDate2}
                                onChange={handleDateChange2}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                onKeyDown={(e) =>
                                  e.key === 'Backspace' && e.preventDefault()
                                }
                                maxDate={new Date()}
                                isClearable={true}
                                onCalendarOpen={toggleCalendar}
                                onCalendarClose={toggleCalendar}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                            <Select
                              style={{ width: '200px' }}
                              defaultValue={id}
                              onChange={setId}
                              options={staffOptions}
                              placeholder="Select Staff"
                              maxMenuHeight="200px" // Set the minimum height here
                              // minMenuHeight="180px"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  width: '250px' // Set the width here
                                })
                              }}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="wrapper">
                        {loading ? (
                          <div
                            className="spinner-container"
                            style={{ marginLeft: '500px' }}
                          >
                            <BounceLoader
                              color={'#123abc'}
                              loading={loading}
                              css={override}
                              size={60}
                            />
                          </div>
                        ) : (
                          <table
                            id="example2"
                            className="table table-bordered table-hover"
                            style={{ fontSize: '14px' }}
                          >
                            <thead>
                              <tr>
                                {/* <th>Sr. No</th> */}
                                {/* <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i style={{ color: 'grey' }}></i>
                                  </span>
                                  Sr No
                                </th> */}
                                <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i style={{ color: 'grey' }}></i>
                                  </span>
                                  Date
                                </th>
                                <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i
                                      onClick={() =>
                                        handleArrowClick('name', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                          isArrowObj.column == 'name' &&
                                          isArrowObj.orderby == 'desc'
                                          ? 'fa fa-arrow-down'
                                          : 'fa fa-arrow-up'
                                      }
                                      style={{ color: 'grey' }}
                                    ></i>
                                  </span>
                                  Staff Name
                                </th>
                                <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i
                                      onClick={() =>
                                        handleArrowClick('email', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                          isArrowObj.column == 'email' &&
                                          isArrowObj.orderby == 'desc'
                                          ? 'fa fa-arrow-down'
                                          : 'fa fa-arrow-up'
                                      }
                                      style={{ color: 'grey' }}
                                    ></i>
                                  </span>
                                  Staff Email
                                </th>
                                <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i
                                      onClick={() =>
                                        handleArrowClick('contact_no', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                          isArrowObj.column == 'contact_no' &&
                                          isArrowObj.orderby == 'desc'
                                          ? 'fa fa-arrow-down'
                                          : 'fa fa-arrow-up'
                                      }
                                      style={{ color: 'grey' }}
                                    ></i>
                                  </span>
                                  Title
                                </th>
                                <th className="tableheadstaff">
                                  <span style={{ marginRight: '3px' }}>
                                    <i
                                      onClick={() =>
                                        handleArrowClick('designation', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                          isArrowObj.column == 'designation' &&
                                          isArrowObj.orderby == 'desc'
                                          ? 'fa fa-arrow-down'
                                          : 'fa fa-arrow-up'
                                      }
                                      style={{ color: 'grey' }}
                                    ></i>
                                  </span>
                                  Description
                                </th>

                                <th className="tableheadstaff">Status</th>
                                {/* <th className="tableheadstaff">Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {staffData.length == 0 ? (
                                <tr className="text-center">
                                  <td
                                    colSpan="9"
                                    style={{ fontSize: 16, marginTop: '10px' }}
                                  >
                                    No Record found
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  {staffData.map((e, index) => {
                                    // console.log('------>>>check',e)
                                    return (
                                      <tr className="tablerow">
                                        {/* <td>{index + 1}</td> */}
                                        {/* <td>{index + 1}</td> */}
                                        <td>
                                          {e.request_date ? e.request_date : ''}
                                        </td>
                                        <td>
                                        {e.staffdetails?.name ?? ''}
                                        </td>
                                        <td>
                                        {e.staffdetails?.name ?? ''}
                                        </td>
                                        <td>
                                          {e.request_title
                                            ? e.request_title
                                            : '-'}
                                        </td>
                                        <td>
                                          {e.request_desc
                                            ? e.request_desc
                                            : '-'}
                                        </td>

                                        {/* <td>
                                          {e.request_status == 2
                                            ? 'Rejected'
                                            : e.request_status == 1
                                            ? 'Accepted'
                                            : 'Pending'}
                                        </td> */}
                                        <td>
                                          <div
                                            className=""
                                            style={{
                                              flexDirection: 'row',
                                              alignSelf: 'center'
                                            }}
                                          >
                                            {e.request_status == 1 ? (
                                              //   <button
                                              //     onClick={() => {
                                              //       setStatusId(e.id);
                                              //       modalShow();
                                              //     }}
                                              //     // className="btn btn-primary"
                                              //     className="btn text-white"
                                              //     style={{
                                              //       backgroundColor: '#50CD89'
                                              //     }}
                                              //   >

                                              <div style={{ color: 'Green', fontWeight: '500' }}>Accepted</div>
                                              //   </button>
                                            ) : e.request_status == 2 ? (
                                              //   <button
                                              //     onClick={() => {
                                              //       setStatusId(e.id);
                                              //       modalRejectShow();
                                              //     }}
                                              //     className="btn text-white"
                                              //     style={{
                                              //       backgroundColor: 'red',
                                              //       marginLeft: '10px'
                                              //     }}
                                              //   >
                                              <div style={{ color: 'red', fontWeight: '500' }}>Rejected</div>
                                              //   </button>
                                            ) : (
                                              <>
                                                <button
                                                  onClick={() => {
                                                    setStatusId(e.id);
                                                    setStatusKey('1')
                                                    modalShow();
                                                  }}
                                                  // className="btn btn-primary"
                                                  className="btn text-white"
                                                  style={{
                                                    backgroundColor: '#50CD89'
                                                  }}
                                                >
                                                  Accept
                                                </button>
                                                <button
                                                  onClick={() => {
                                                    setStatusId(e.id);
                                                    setStatusKey('2')
                                                    modalRejectShow();
                                                  }}
                                                  className="btn text-white"
                                                  style={{
                                                    backgroundColor: 'red',
                                                    marginLeft: '10px'
                                                  }}
                                                >
                                                  Reject
                                                </button>
                                              </>
                                            )}

                                            {/* <button
                                              onClick={() => {
                                                setStatusId(e.id);
                                                modalRejectShow();
                                              }}
                                              className="btn text-white"
                                              style={{
                                                backgroundColor: 'red',
                                                marginLeft: '10px'
                                              }}
                                            >
                                              Reject
                                            </button> */}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        )}
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete the Staff?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Staff Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default StaffInfo;

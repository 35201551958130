import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
import { Modal, Button } from 'react-bootstrap';
const ManageBrands = () => {
  let { brandId } = useParams();
  const navigate = useNavigate();
  const [brandData, setBrandData] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);

  const [updateBrandData, setUpdateBrandData] = useState([]);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .get(Url.BASEURL + 'editbrand/' + brandId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value);
          setBrandData(value.data.response);
          setIsChecked(value.data.response.enabled);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setBrandData({
      ...brandData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  // function for active update
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // fucntion to store staff data

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      bname: brandData.bname,
      enabled: isChecked
    };
    axios.defaults.headers.put['Content-Type'] =
      'application/json;charset=utf-8';
    axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
    axios
      .put(Url.BASEURL + 'updatebrand/' + brandId, params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/listbrands');
          // toast('Client added Successfully');
          setUpdateBrandData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listbrands');
  }

  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listbrands');
  };
  return (
    <Constant>
      <BreadScrum path="/listbrands" />{' '}
      <div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>UPDATE BRAND</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Brand Updated successfully
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={closeModal}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="wrapper">
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Brand Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xs-12 col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Name</label>
                            <input
                              onChange={handleInputChange}
                              value={brandData.bname}
                              name="bname"
                              type="text"
                              className="form-control"
                              id="exampleInputName"
                              placeholder="Enter name"
                            />
                            {error && error.bname ? (
                              <div style={{ color: 'red' }}>{error.bname}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group active_status_flex align-items-center m-0">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              htmlFor="exampleInputEmail1"
                              className="m-0 me-4 pl-2"
                            >
                              Active Status
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div
                          className="col-4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                            // marginLeft: '10px'
                          }}
                        >
                          <div className="">
                            <button
                              onClick={submitHandler}
                              // className="btn btn-primary"
                              className="btn text-white"
                              style={{ backgroundColor: '#1e336a' }}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="" style={{ marginLeft: '20px' }}>
                            <button
                              onClick={reloadPage}
                              // className="btn btn-primary"
                              className="btn text-black"
                              style={{ backgroundColor: '#DCDCDC' }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};

export default ManageBrands;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// import './AdminRights.css';
let viewArray = [];
let myObject = {};
const AddAdminRight = () => {
  const navigate = useNavigate();
  const [isViewChecked, setIsViewChecked] = useState([]);
  const [isAddChecked, setIsAddChecked] = useState([]);
  const [isDeleteChecked, setIsDeleteChecked] = useState([]);
  const [isUpdateChecked, setIsUpdateChecked] = useState([]);
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(0);
  const token = useSelector((state) => state.token);
  const [addModal, setAddModal] = useState(false);

  const [adminRights, setAdmiRights] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedActions, setSelectedActions] = useState({});

  const items = [
    // {
    //   id: '0',
    //   sidebarItems: 'Dashboard',
    //   sideItems: 'Dashboard'
    // },
    {
      id: '1',
      sidebarItems: 'Manage Clients',
      sideItems: 'Manage_Clients'
    },
    {
      id: '2',
      sidebarItems: 'Manage Staff',
      sideItems: 'Manage_Staff'
    },
    {
      id: '3',
      sidebarItems: 'Manage Products',
      sideItems: 'Manage_Products'
    },
    {
      id: '4',
      sidebarItems: 'Manage Categories',
      sideItems: 'Manage_Categories'
    },
    {
      id: '5',
      sidebarItems: 'Manage Sub-Categories',
      sideItems: 'Manage_Sub_Categories'
    },
    {
      id: '6',
      sidebarItems: 'Manage Brands',
      sideItems: 'Manage_Brands'
    },
    // {
    //   id: '6',
    //   sidebarItems: 'User Rights',
    //   sideItems: 'User_Rights'
    // },
    {
      id: '7',
      sidebarItems: 'Designation',
      sideItems: 'Designation'
    },
    {
      id: '8',
      sidebarItems: 'Sales Report',
      sideItems: 'Sales_Report'
    },
    {
      id: '9',
      sidebarItems: 'Manage Industry',
      sideItems: 'Manage_Industry'
    },

    {
      id: '10',
      sidebarItems: 'Manage Staff Attendance',
      sideItems: 'Manage_Staff_Attendance'
    },
    {
      id: '11',
      sidebarItems: 'Manage Staff Daily Task Report',
      sideItems: 'Manage_Staff_Daily_Task_Report'
    },
    {
      id: '12',
      sidebarItems: 'Manage Staff Requests',
      sideItems: 'Manage_Staff_Requests'
    }
  ];

  // function for active update
  const handleCheckbox = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  // const handleViewCheckbox = (id, name, index) => {
  //   let viewData = {
  //     id: id,
  //     name: name,
  //     method: 'view'
  //   };
  //   const values = [...isViewChecked];
  //   if (isViewChecked.length > 0) {
  //     var current = values.filter((view) => {
  //       if (id !== 'undefined' && view && view.id !== 'undefined') {
  //         return view.id !== id;
  //       }
  //     });
  //     console.log(current);
  //     if (isViewChecked.length > current.length) {
  //       setIsViewChecked(current);
  //     } else {
  //       // Make a copy of the array
  //       values[id] = viewData;
  //       setIsViewChecked(values);
  //     }
  //   } else {
  //     values[id] = viewData;
  //     setIsViewChecked(values);
  //   }
  // };

  // const handleAddCheckbox = (id, name, index) => {
  //   let addData = {
  //     id: id,
  //     name: name,
  //     method: 'add'
  //   };
  //   const values = [...isAddChecked];
  //   if (isAddChecked.length > 0) {
  //     var current = values.filter((add) => {
  //       if (id !== 'undefined' && add && add.id !== 'undefined') {
  //         return add.id !== id;
  //       }
  //     });
  //     if (isAddChecked.length > current.length) {
  //       setIsAddChecked(current);
  //     } else {
  //       values[id] = addData;
  //       setIsAddChecked(values);
  //     }
  //   } else {
  //     values[id] = addData;
  //     setIsAddChecked(values);
  //   }
  // };

  // const handleUpdateCheckbox = (id, name, index) => {
  //   let updateData = {
  //     id: id,
  //     name: name,
  //     method: 'update'
  //   };
  //   const values = [...isUpdateChecked];
  //   if (isUpdateChecked.length > 0) {
  //     var current = values.filter((update) => {
  //       if (id !== 'undefined' && update && update.id !== 'undefined') {
  //         return update.id !== id;
  //       }
  //     });
  //     if (isUpdateChecked.length > current.length) {
  //       setIsUpdateChecked(current);
  //     } else {
  //       values[id] = updateData;
  //       setIsUpdateChecked(values);
  //     }
  //   } else {
  //     values[id] = updateData;
  //     setIsUpdateChecked(values);
  //   }
  // };

  // const handleDeleteCheckbox = (id, name, index) => {
  //   let deleteData = {
  //     id: id,
  //     name: name,
  //     method: 'delete'
  //   };
  //   const values = [...isDeleteChecked];
  //   if (isDeleteChecked.length > 0) {
  //     var current = values.filter((dele) => {
  //       if (id !== 'undefined' && dele && dele.id !== 'undefined') {
  //         return dele.id !== id;
  //       }
  //     });
  //     if (isDeleteChecked.length > current.length) {
  //       setIsDeleteChecked(current);
  //     } else {
  //       values[id] = deleteData;
  //       setIsDeleteChecked(values);
  //     }
  //   } else {
  //     values[id] = deleteData;
  //     setIsDeleteChecked(values);
  //   }
  // };

  // const getBrandList = () => {
  //   var permissionArray = {};
  //   for (let i = 0; i < items.length; i++) {
  //     let labelName = items[i].sidebarItems;
  //     let viewData =
  //       isViewChecked[i] !== undefined &&
  //       isViewChecked[i].id !== undefined &&
  //       isViewChecked[i].id === items[i].id
  //         ? 'view'
  //         : '';
  //     let addData =
  //       isAddChecked[i] !== undefined &&
  //       isAddChecked[i].id !== undefined &&
  //       isAddChecked[i].id === items[i].id
  //         ? 'add'
  //         : '';
  //     let updateData =
  //       isUpdateChecked[i] !== undefined &&
  //       isUpdateChecked[i].id !== undefined &&
  //       isUpdateChecked[i].id === items[i].id
  //         ? 'update'
  //         : '';
  //     let deleteData =
  //       isDeleteChecked[i] !== undefined &&
  //       isDeleteChecked[i].id !== undefined &&
  //       isDeleteChecked[i].id === items[i].id
  //         ? 'delete'
  //         : '';
  //     let labelValue = [viewData, addData, updateData, deleteData];
  //     permissionArray[labelName] = labelValue;
  //   }
  //   setLoading(true);
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };

  //   const params = {
  //     role_name: role,
  //     permissions: permissionArray
  //   };

  //   axios
  //     .post(Url.BASEURL + 'addroleandpermissions', params, config)
  //     .then((value) => {
  //       if (value && value.data.status == 200) {
  //         navigate('/adminrights');
  //         setAdmiRights(value.data.response.data);
  //         // setTimeout(() => {
  //         // }, 2000);
  //       } else {
  //         setError(value.data.errors);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const handleCheckboxChange = (id, name, method) => {
    setCheckedPermissions((prevPermissions) => {
      const existingIndex = prevPermissions.findIndex(
        (item) => item.id === id && item.method === method
      );

      if (existingIndex !== -1) {
        // Checkbox is checked, uncheck it
        return prevPermissions.filter(
          (item) => !(item.id === id && item.method === method)
        );
      } else {
        // Checkbox is unchecked, check it
        return [...prevPermissions, { id, name, method }];
      }
    });
  };

  const submitPermissions = () => {
    const permissionArray = {};

    for (let i = 0; i < items.length; i++) {
      // const labelName = items[i].sidebarItems;
      const labelName = items[i].sideItems;
      const labelMethods = ['view', 'add', 'update', 'delete'];

      const labelValue = labelMethods.map((method) =>
        checkedPermissions.some(
          (permission) =>
            permission.id === items[i].id && permission.method === method
        )
          ? method
          : ''
      );

      permissionArray[labelName] = labelValue;
    }

    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      role_name: role,
      enabled: isChecked,
      permissions: permissionArray
    };

    axios
      .post(Url.BASEURL + 'addroleandpermissions', params, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          openModal();
          // navigate('/adminrights');
          setAdmiRights(value.data.response.data);
        } else {
          setError(value.data.errors);
          closeAddModal();
        }
      })
      .catch((err) => console.log(err));
  };
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/manage_user_rights');
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/manage_user_rights');
  }
  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }
  return (
    <Constant>
      <BreadScrum path="/adminrights" />{' '}
      <div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Department</Modal.Title>
          </Modal.Header>
          <Modal.Body>Department added successfully</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={closeModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={addModal}
          onHide={closeAddModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Department Add</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure, You want to add this Department?
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={submitPermissions}
            >
              Yes
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => closeAddModal()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div
                    className="card-body table-responsive"
                  // style={{ overflow: 'hidden' }}
                  >
                    <div className="">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="form-group">
                            <label
                              htmlFor="exampleInputEmail1"
                              style={{ padding: '15px' }}
                            >
                              Admin Right Label
                            </label>
                            <input
                              onChange={(e) => setRole(e.target.value)}
                              // style={{ width: '603px', marginLeft: '10px' }}
                              // onChange={handleInputChange}
                              // value={userData.pname}
                              name="pname"
                              type="text"
                              className="form-control col-8 ml-3"
                              id="exampleInputEmail1"
                              placeholder="Enter Dept."
                            />
                            {error && error.role_name ? (
                              <div className="px-3" style={{ color: 'red' }}>
                                {error.role_name}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-6">
                            <div className="form-group active_status_flex m-0 align-items-center pl-3">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckbox}
                              />
                              <label
                                htmlFor="exampleInputEmail1"
                                className="m-0 p-2"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                          <hr></hr>
                          {/* /.card-header */}
                          <div className="card-body p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th style={{ width: 10 }}>#</th>
                                  <th>Section</th>
                                  <th className="checkbox_alignment">View</th>
                                  <th className="checkbox_alignment">Add</th>
                                  <th className="checkbox_alignment">Update</th>
                                  <th
                                    className="checkbox_alignment"
                                    style={{ width: 40 }}
                                  >
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((e, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}.</td>

                                      {/* <td>{e.id}.</td> */}
                                      <td>{e.sidebarItems}</td>
                                      {/* {console.log(
                                        'dashboard',
                                        e.sidebarItems,
                                        e.id
                                      )} */}
                                      <td className="checkbox_alignment">
                                        {/* <label> */}
                                        <input
                                          // className='d-block m-auto'
                                          type="checkbox"
                                          checked={checkedPermissions.some(
                                            (permission) =>
                                              permission.id === e.id &&
                                              permission.method === 'view'
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              e.id,
                                              e.sidebarItems,
                                              'view'
                                            )
                                          }
                                        />

                                        {/* </label> */}
                                      </td>
                                      <td className="checkbox_alignment">
                                        {/* <input
                                          type="checkbox"
                                          value={
                                            isAddChecked[e.id] !== undefined &&
                                            isAddChecked[e.id].id !==
                                              undefined &&
                                            isAddChecked[e.id].id === e.id
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleAddCheckbox(
                                              e.id,
                                              e.sidebarItems,
                                              index
                                            )
                                          }
                                        /> */}
                                        {/* <label> */}
                                        <input
                                          type="checkbox"
                                          checked={checkedPermissions.some(
                                            (permission) =>
                                              permission.id === e.id &&
                                              permission.method === 'add'
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              e.id,
                                              e.sidebarItems,
                                              'add'
                                            )
                                          }
                                        />

                                        {/* </label> */}
                                      </td>
                                      <td className="checkbox_alignment">
                                        {/* <label> */}
                                        <input
                                          type="checkbox"
                                          checked={checkedPermissions.some(
                                            (permission) =>
                                              permission.id === e.id &&
                                              permission.method === 'update'
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              e.id,
                                              e.sidebarItems,
                                              'update'
                                            )
                                          }
                                        />
                                        {/* </label> */}
                                      </td>
                                      <td className="checkbox_alignment">
                                        {/* <label> */}
                                        <input
                                          type="checkbox"
                                          checked={checkedPermissions.some(
                                            (permission) =>
                                              permission.id === e.id &&
                                              permission.method === 'delete'
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              e.id,
                                              e.sidebarItems,
                                              'delete'
                                            )
                                          }
                                        />

                                        {/* </label> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                        {/* /.card */}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={openAddModal}
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                            onClick={reloadPage}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </Constant>
  );
};
export default AddAdminRight;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Select from 'react-select';

const SalesReport = () => {
  const [salesReport, setSalesReport] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchStaff, setSearchStaff] = useState('');
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [clientId, setClientId] = useState('');
  const [currentReport, setCurrentReport] = useState('currrent_report');
  const [totalPriceAterDiscount, setTotalPriceAterDiscount] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedYear, SetSelectedYear] = useState([]);
  const [stage, setStage] = useState([]);

  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [allStages, setAllStages] = useState([]);
  let { current_report } = useParams();

  useEffect(() => {
    console.log('current_report', current_report);
  });

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL + 'leadstages?',

        config
      )
      .then((value) => setAllStages(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'name',
    orderby: 'asc'
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  const token = useSelector((state) => state.token);
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    isArrowObj,
    id,
    clientId,
    selectedMonth,
    selectedYear,
    stage,
    pageCount
  ]);
  // console.log('selectedMonthselectedMonth', selectedYear);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'product-sale-report-global?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        (id !== '' ? '&staff_id=' + id.value : '') +
        // '&staffid=' +
        // id +
        (clientId !== '' ? '&client_id=' + clientId.value : '') +
        (selectedMonth.length !== 0 ? '&month=' + selectedMonth.value : '') +
        (selectedYear.length !== 0 ? '&year=' + selectedYear.value : '') +
        (stage.length !== 0 ? '&stage=' + stage.value : '') +
        // '&clientid=' +
        // clientId +
        '&orderby=',

        config
      )
      .then(
        (value) =>
          setLoading(false) &
          setSalesReport(value.data.response.leads.data) &
          setTotalPriceAterDiscount(
            value.data.response.total.total_price_after_discount
          ) &
          setPageCount(value.data.response.leads.last_page)
      )
      .catch((err) => console.log(err));
  };

  console.log('delterow', deleteRow);

  useEffect(() => {
    if (salesReport) {
      setResponse(salesReport);
    }
  }, [salesReport, searchClient]);

  useEffect((e) => {
    console.log('salesreport', salesReport);
  });

  // function for pagination

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearhStaffByName(searchStaffByName);
  };
  // function for delete row

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deletestaff/' + brandId, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        // getBrandList();
      })
      .catch((err) => console.log(err));
  };
  // function for sort data

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  const ClientChangeHandler = (event) => {
    setClientId(event.target.value);
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffsdropdown', config)
      .then((value) => setStaffData(value.data))
      .catch((err) => console.log(err));
  }, []);

  //   function for clientsdata
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'clientsdropdown', config)
      .then(
        (value) => setClientLists(value.data)
        // setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  const transformStaffDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Staff' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name}`
    }));

    return [selectOption, ...dataOptions];
  };

  const staffOptions = transformStaffDataToOptions(staffData);
  console.log('clientLists', clientLists);

  const transformClientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Client' };
    const dataOptions = data.map(({ id, contact_person_name_0 }) => ({
      value: id.toString(),
      label: `${contact_person_name_0}`
    }));

    return [selectOption, ...dataOptions];
  };

  const clientOptions = transformClientDataToOptions(clientLists);
  const MonthsOptions = [
    { value: '0', label: 'Select Month' },
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '12', label: 'November' },
    { value: '12', label: 'December' }
  ];

  const startYear = 2000;
  const endYear = 2040;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  console.log('checkyear');

  const transformStagesDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select Stage' };
    const dataOptions = data.map(({ id, stage }) => ({
      value: id.toString(),
      label: `${stage}`
    }));

    return [selectOption, ...dataOptions];
  };

  const stagesOptions = transformStagesDataToOptions(allStages);
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  return (
    <Constant>
      <BreadScrum path="/dashboard" />{' '}
      <div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div
                    className="card-body table-responsive"
                    style={{
                      overflow: 'hidden',
                      minHeight: isCalendarOpen ? '350px' : null
                    }}
                  >
                    <div
                      className="search-field d-none d-md-block"
                      style={{ marginBottom: '13px' }}
                    >
                      <div className="">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div
                            className="search-field d-none d-md-block"
                            style={{ marginBottom: '13px' }}
                          >
                            <div
                              style={{ marginTop: '19px' }}
                              className="d-flex align-items-center h-100 searchbar_parent"
                              action="#"
                            >
                              <div
                                className="input-group merchant_search_bar border_radius searchbar_size"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5
                                }}
                              >
                                <div className="input-group-prepend bg-transparent"></div>
                                <input
                                  style={{
                                    height: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchClient(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="MM"
                              placeholderText="Select a date"
                              // isClearable={true}
                              onCalendarOpen={toggleCalendar}
                              onCalendarClose={toggleCalendar}
                              yearDropdown
                              scrollableYearDropdown
                              showYearDropdown={true}
                            /> */}
                          <Select
                            defaultValue={clientId}
                            onChange={setClientId}
                            options={clientOptions}
                            placeholder="Select Client"
                            maxMenuHeight="180px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '200px', // Set the width here
                                marginTop: '30px'
                                // height:'10px'
                              })
                            }}
                          />
                          <Select
                            defaultValue={id}
                            onChange={setId}
                            options={staffOptions}
                            placeholder="Select Staff"
                            maxMenuHeight="180px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '200px', // Set the width here
                                marginTop: '30px'
                                // height:'10px'
                              })
                            }}
                          />
                          {/* <select
                            onChange={StaffChangeHandler}
                            id="select1"
                            class="ct_listing border_radius ct_listing_float ct_listing_margin "
                            style={{
                              borderWidth: '1px',
                              borderRadius: '5px',
                              width: '190px',
                              marginBottom: '9px',
                              borderColor: '0.5px solid black',
                              marginTop: '43px',

                              height: '30px'
                            }}
                          >
                            {staffData.map((e) => {
                              return <option value={e.id}>{e.name}</option>;
                            })}
                            <option value="">Select Staff</option>
                            {console.log('id', id)}
                          </select> */}

                          {/* <select clientOptions
                            onChange={ClientChangeHandler}
                            id="select1"
                            class="ct_listing border_radius ct_listing_float ct_listing_margin "
                            style={{
                              borderWidth: '1px',
                              borderRadius: '5px',
                              width: '190px',
                              marginBottom: '9px',
                              borderColor: '0.5px solid black',
                              marginTop: '43px',

                              height: '30px'
                            }}
                          >
                            {clientLists.map((e) => {
                              return (
                                <option value={e.id}>{e.owner_name}</option>
                              );
                            })}
                            <option value="">Select Client</option>
                            {console.log('id', id)}
                          </select> */}

                          <Select
                            defaultValue={selectedMonth}
                            onChange={setSelectedMonth}
                            options={MonthsOptions}
                            placeholder="Select Month"
                            maxMenuHeight="180px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '200px', // Set the width here
                                marginTop: '30px'
                                // height:'10px'
                              })
                            }}
                          />

                          {/* <DatePicker
                              selected={selectedDate2}
                              onChange={handleDateChange2}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Select a date"
                              // isClearable={true}
                              onCalendarOpen={toggleCalendar}
                              onCalendarClose={toggleCalendar}
                              yearDropdown
                              scrollableYearDropdown
                              showYearDropdown={true}
                            /> */}
                          <Select
                            defaultValue={selectedYear}
                            onChange={SetSelectedYear}
                            options={years}
                            placeholder="Select Year"
                            maxMenuHeight="180px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '200px', // Set the width here
                                marginTop: '30px'
                                // height:'10px'
                              })
                            }}
                          />

                          <Select
                            defaultValue={stage}
                            onChange={setStage}
                            options={stagesOptions}
                            placeholder="Select Stage"
                            maxMenuHeight="180px" // Set the minimum height here
                            // minMenuHeight="180px"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: '200px', // Set the width here
                                marginTop: '30px'
                                // height:'10px'
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="wrapper">
                      <div className="table-wrapper" style={{overflowY:"scroll"}}>
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                          style={{
                            borderCollapse: 'collapse',
                            fontSize: '14px'
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <span style={{ marginRight: '3px' }}>
                                  {/* <i
                                    onClick={() =>
                                      handleArrowClick('name', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'name' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i> */}
                                </span>
                                Date
                              </th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('email', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'email' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Organisation{' '}
                              </th>
                              <th>Staff Name</th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('email', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'email' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Staff Email
                              </th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('contact_no', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'contact_no' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Client Name
                              </th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('designation', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'designation' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Client Email
                              </th>
                              <th>Lead Name</th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('clientname', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'clientname' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Lead Value
                              </th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('clientname', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'clientname' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Stage
                              </th>
                              <th>
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('clientname', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'clientname' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Action
                              </th>
                              <th>
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    // onClick={() =>
                                    //   handleArrowClick('clientname', 'asc')
                                    // }
                                    // class={
                                    //   isArrowObj &&
                                    //   isArrowObj.column == 'clientname' &&
                                    //   isArrowObj.orderby == 'desc'
                                    //     ? 'fa fa-arrow-down'
                                    //     : 'fa fa-arrow-up'
                                    // }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span>
                                PDF
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {salesReport.length == 0 ? (
                              <tr className="text-center">
                                <td
                                  colSpan="9"
                                  style={{
                                    fontSize: 16,
                                    marginTop: '10px'
                                  }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {salesReport.map((e) => {
                                  console.log('eeeeeeee', e.lead_organisation);
                                  return (
                                    <tr>
                                      <td>
                                        {e?.created_at ? e?.created_at : ''}
                                      </td>
                                      <td>{e?.lead_organisation}</td>
                                      <td>{e?.staff_details?.name}</td>
                                      <td>{e?.staff_details?.email}</td>
                                      {/* {e?.client_data?.clientInfo.map(
                                        (element) => {
                                          return (
                                            <> */}
                                      <td>
                                        {
                                          e?.client_data?.clientInfo[0]
                                            ?.contact_person_name_0
                                        }
                                      </td>

                                      <td>
                                        {
                                          e?.client_data?.clientInfo[0]
                                            ?.contact_person_email_0
                                        }
                                      </td>
                                      {/* </>
                                          );
                                        }
                                      )} */}
                                      <td>
                                        {e?.description ? e?.description : ''}
                                      </td>
                                      <td>
                                        {e?.leadproducts_sum_total_price}
                                        {/* {totalPriceAterDiscount} */}
                                      </td>
                                      <td>
                                        {e?.leadstage.stage
                                          ? e?.leadstage.stage
                                          : ''}
                                      </td>
                                      <td>
                                        <Link
                                          to={
                                            '/sales_report_details/' +
                                            e?.id +
                                            '/' +
                                            e?.staff_id
                                          }
                                          className="nav-icon fas fa-edit pointer_cursor d-block p-1"
                                        ></Link>
                                      </td>
                                      <td className=''>
                                        <div className="dropdown col-12 text-center">
                                          <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-file"></i>
                                          </a>

                                          <ul className="dropdown-menu py-1 px-2" style={{ minWidth: "120px" }}>

                                            <li className='py-1'>
                                              <Link
                                                target="_blank"
                                                to={e?.quotationpdflatest?.url}
                                                className="d-flex justify-content-between align-items-center"
                                              >
                                                Quotation <i class="fa-solid fa-file"></i>
                                              </Link>

                                            </li>
                                            {e.quotationpdflatest !== null && e.intend_pdf !== 0 ? (
                                              <li className='py-1'>
                                                <Link
                                                  target="_blank"
                                                  to={e?.intendpdflatest?.url}
                                                  // style={{ color: "gray" }}
                                                  className="d-flex justify-content-between align-items-center"
                                                >
                                                  Sales Order <i className="fa-solid fa-file"></i>
                                                </Link>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="active"
                        onPageChange={handlePageClick}
                      />
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </Constant>
  );
};
export default SalesReport;

import React, { useContext, useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Modal, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const GetAttendanceReport = () => {
  let { getstaffId } = useParams();
  const { search } = useLocation(); // Extracting query parameters
  // const { ids } = useParams(); // Extracting employee ID from URL

  const queryParams = new URLSearchParams(search);
  const reportDate = queryParams.get('date');
  const reportMonth = queryParams.get('month');
  const reportYear = queryParams.get('year');
  console.log(
    'getstaffId---->>>',
    getstaffId,
    'reportDate',
    reportDate,
    'reportMonth',
    reportMonth,
    'reportYear',
    reportYear
  );
  const location = useLocation();
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientInfo, setIClientInfo] = useState([]);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDate2, setSelectedDate2] = useState();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const transformClientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, contact_person_name_0 }) => ({
      value: id.toString(),
      label: `${contact_person_name_0}`
    }));
    return [selectOption, ...dataOptions];
  };
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'owner_name',
    orderby: 'asc'
  });
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,
    isArrowObj
  ]);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'list-new-month-attendance-details?' +
        'day=' +
        reportDate +
        '&month=' +
        reportMonth +
        '&year=' +
        reportYear +
        '&staff_id=' +
        getstaffId
        ,
        config
      )
      .then((value) => {
        if (value && value.data.status == 200) {
          setLoading(false);
          setClientData(value.data.data);
          console.log('data', value.data.data.staff_id);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  //   useEffect(() => {
  //     if (clientData) {
  //       setResponse(clientData);
  //     }
  //   }, [clientData, searchClient, deleteRow]);

  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  // function for delete row
  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deleteclient/' + brandIdToDelete, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
      })
      .catch((err) => console.log(err));
  };

  //   useEffect(() => {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     };
  //     axios
  //       .get(Url.BASEURL + 'clientsdropdown', config)
  //       .then(
  //         (value) =>
  //           setClientLists(value.data) &
  //           setPageCount(value.data.response.last_page)
  //       )
  //       .catch((err) => console.log(err));
  //   }, []);

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return format(parsedDate, 'dd MMM yyyy');
  };
  // BREADSCRUMS API
  const handleClearInput = () => {
    setSearchClient('');
  };
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  return (
    <>
      <Constant>
        <BreadScrum
          btn_name="Add Client"
          // routepath={'addclient'}
          // routepath={'manageclient/' + 0}
          heading={'Manage Client'}
          btn={'Add Clients'}
          // bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '}
        <div>
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{
                        minHeight: isCalendarOpen ? '320px' : null,
                        overflow: 'hidden'
                      }}
                    >
                      {/* <div
                        className="search-field d-md-block"
                        style={{ marginBottom: '13px' }}
                      >
                        <div className=" col-12">
                          <div className="col-12 d-flex justify-content-between align-items-center flex-wrap">
                            <Select
                              defaultValue={id}
                              onChange={setId}
                              options={clientOptions}
                              placeholder="Select Client"
                              maxMenuHeight="180px"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  width: '200px',
                                  zIndex: 100
                                })
                              }}
                            />

                            <div className="col-lg-3 col-md-6 col-xs-12 datepicker p-0 ">
                              <label className="p-2 m-0">From :</label>
                              <DatePicker
                                className="col-12"
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                onKeyDown={(e) =>
                                  e.key === 'Backspace' && e.preventDefault()
                                }
                                maxDate={new Date()}
                                isClearable={true}
                                yearDropdown
                                onCalendarOpen={toggleCalendar}
                                onCalendarClose={toggleCalendar}
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                            <div
                              className="col-lg-3 col-md-6 col-xs-12 p-0 datepicker"
                              // style={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <label className="p-2 m-0">To :</label>
                              <DatePicker
                                className="col-12"
                                selected={selectedDate2}
                                onChange={handleDateChange2}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                onKeyDown={(e) =>
                                  e.key === 'Backspace' && e.preventDefault()
                                }
                                maxDate={new Date()}
                                isClearable={true}
                                onCalendarOpen={toggleCalendar}
                                onCalendarClose={toggleCalendar}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                         

                            <div
                              className=" position-relative"
                              style={{
                                borderWidth: 1,
                                borderRadius: 5,
                                border: '1px solid black'
                              }}
                            >
                           
                              <input
                                style={{
                                  height: 30,
                                  paddingRight: 30
                                }}
                                onChange={(e) => {
                                  setSearchClient(e.target.value);
                                  handleKeyPress();
                                }}
                                type="text"
                                className="form-control bg-transparent border-0"
                                placeholder="Search"
                                value={searchClient}
                              />

                              {searchClient && (
                                <span
                                  className="position-absolute top-50 end-0 translate-middle-y"
                                  style={{ cursor: 'pointer', marginRight: 10 }}
                                  onClick={handleClearInput}
                                >
                                  ✕
                                </span>
                              )}

                            </div>
                       
                          </div>
                        </div>
                      </div> */}
                      <div className="wrapper">
                        {loading ? (
                          <div
                            className="spinner-container"
                            style={{ marginLeft: '500px' }}
                          >
                            <BounceLoader
                              color={'#123abc'}
                              loading={loading}
                              css={override}
                              size={60}
                            />
                          </div>
                        ) : (
                          <div className="table-wrapper">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            // style={{ fontSize: '14px' }}
                            >
                              <thead>
                                <tr>
                                  {/* <th className="tablehead">Sr. Number</th> */}

                                  <th className="tablehead">
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('owner_name', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'owner_name' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span> */}
                                    Name
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        style={{ color: 'grey' }}
                                        onClick={() =>
                                          handleArrowClick('owner_email', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'owner_email' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                      ></i>
                                    </span> */}
                                    Email
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('owner_email', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'owner_email' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span> */}
                                    Attendance Date
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span> */}
                                    Attendance Time
                                  </th>
                                  <th className="tablehead">
                                    {' '}
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span> */}
                                    Attendance Place
                                  </th>
                                  <th className="tablehead">SignOff Time</th>
                                  <th className="tablehead">
                                    {' '}
                                    {/* <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('name', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'name' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa fa-arrow-down'
                                            : 'fa fa-arrow-up'
                                        }
                                        style={{ color: 'grey' }}
                                      ></i>
                                    </span> */}
                                    Status
                                  </th>

                                  {/* <th className="tablehead">Sr. Number</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {console.log('clientInfo', clientData)}
                                <tr className="tablerow ">
                                  <td>{clientData?.name}</td>
                                  <td>{clientData?.email}</td>

                                  <td>{clientData?.data?.attendance_date}</td>

                                  <td>{clientData?.data?.time}</td>
                                  <td>
                                    {clientData?.data?.formatted_address
                                      ? clientData?.data.formatted_address
                                      : '-'}
                                  </td>
                                  <td>{clientData?.attendance_time !== null ? clientData?.attendance_time : '-'}</td>
                                  <td>
                                    {clientData?.data?.status
                                      ? clientData?.data?.status
                                      : '-'}
                                  </td>
                                </tr>
                                {/* {clientData.length == 0 ? (
                                  <tr className="text-center">
                                    <td
                                      colSpan="9"
                                      style={{
                                        fontSize: 16,
                                        marginTop: '10px'
                                      }}
                                    >
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {clientData.map((e, index) => {
                                      return (
                                        <tr key={index} className="tablerow ">
                                          <td>{e.client_name}</td>
                                          <td>{e.task}</td>

                                          <td>{e.report_date}</td>

                                          <td>{e.report_time}</td>
                                          <td>{e.remarks}</td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )} */}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {/* <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                        /> */}
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure you want to delete this Client?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Delete Client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Client Deleted Sucessfully.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default GetAttendanceReport;

import React, { useContext, useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';

const ManageLead = () => {
  // const token = useSelector((state) => state.auth.token);
  // console.log('tokeennnclient', token);
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [winRecords, setWinRecords] = useState([]);
  const [globalDate, setGlobalDate] = useState('');
  console.log('sadsfdgffh', token);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [stageId, setStageId] = useState('4');
  const [currentDate, setCurrentDate] = useState('');
  const [stageId2, setStageId2] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'title',
    orderby: 'asc'
  });
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  let { current_report } = useParams();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  console.log(
    '------>>>>>>>>>>>>>',
    formattedDate,
    'selectedDate',
    selectedDate
  );
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  const StageChangeHandler = (event) => {
    setStageId(event.target.value);
  };

  const StagechangeHadler2 = (e) => {
    setStageId2(e.target.value);
  };

  // function to get data from api

  useEffect(() => {
    // Define your API functions here (getBrandList and getBrandList2)

    if (current_report === 'current_report') {
      // alert('api 22')
      getBrandList2(); // If the path is "current_report", call the second API function
    } else {
      // alert('ififififif')
      getBrandList(); // Otherwise, call the first API function
    }
  }, [
    current_report,
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    isArrowObj,
    id,
    globalDate,
    currentDate,
    stageId,
    stageId2
  ]);
  console.log('delterow', deleteRow);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    console.log('loadinggggtrue', loading);
    axios
      .get(
        Url.BASEURL +
          'adminallleads?' +
          'search=' +
          searchClient +
          '&page=' +
          itemOffset +
          '&client_id=' +
          id +
          '&stage=' +
          stageId2 +
          '&from=' +
          formattedDate +
          '&to=' +
          formattedDate2 +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,

        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  const getBrandList2 = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          `adminallleads?search=${searchClient}&page=${itemOffset}&staffid=${id}&from=${
            formattedDate ? formattedDate : globalDate
          }&to=${formattedDate2 ? formattedDate2 : currentDate}&stage=${
            stageId ? stageId : '4'
          }`,
        config

        //   'adminallleads?' +
        //   'search=' +
        //   searchClient +
        //   '&page=' +
        //   itemOffset +
        //   '&staffid=' +
        //   id +
        //   '&orderby=' +
        //   '&from=' +
        //   formattedDate
        //   ? formattedDate
        //   : globalDate + '&to=' + formattedDate
        //   ? formattedDate
        //   : currentDate + '&stage=' + stageId,
      )
      .then(
        (value) =>
          console.log(
            'value.data.response.datavalue.data.response.data--->>',
            value.data.response.data
          ) &
          setLoading(false) &
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  };

  console.log('date1', globalDate);

  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  // function for delete row

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'clientsdropdown', config)
      .then(
        (value) =>
          setClientLists(value.data) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then(
        (value) =>
          setStageData(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  //   function for 01 of every month

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(1); // Set the date to the 1st

    // Format the date as "01/month/year"
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    const formattedDates = `${day}/${month}/${year}`;

    setGlobalDate(formattedDates);
  }, []);
  // console.log(Url.BASEURL + 'deleteclient/' + userId);
  console.log('currentdat', globalDate);

  useEffect(() => {
    const currentDate = new Date();

    // Format the date as "dd/MM/yyyy"
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    setCurrentDate(formattedDate);
  }, []);
  // console.log(Url.BASEURL + 'deleteclient/' + userId);
  console.log('currentdat', currentDate);
  // console.log(Url.BASEURL + 'deleteclient/' + userId);
  // console.log('clientdata', id);
  return (
    <Constant>
      <BreadScrum
        heading={'Manage Leads'}
        bgColor={'#1E336A'}
        text_color={'#FFFFFF'}
      />{' '}
      <div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body table-responsive">
                    <div
                      className="search-field d-none d-md-block"
                      style={{ marginBottom: '13px' }}
                    >
                      <div className="">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div
                            className="search-field d-none d-md-block"
                            style={{ marginBottom: '13px' }}
                          >
                            <div
                              style={{ marginTop: '19px' }}
                              className="d-flex align-items-center h-100 searchbar_parent"
                              action="#"
                            >
                              <div
                                className="input-group merchant_search_bar border_radius searchbar_size"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5
                                }}
                              >
                                <div className="input-group-prepend bg-transparent"></div>
                                <input
                                  style={{
                                    height: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchClient(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                          </div>
                          {current_report ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <label>From</label>
                              <DatePicker
                                // Pass the selectedDate variable (a Date object) here
                                onChange={handleDateChange}
                                dateFormat={globalDate} // Specify the desired date format
                                placeholderText={
                                  formattedDate ? formattedDate : globalDate
                                }
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <label>From</label>
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select date"
                                // isClearable={true}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                          )}
                          {current_report ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <label>To</label>
                              <DatePicker
                                onChange={handleDateChange2}
                                dateFormat="01-MM-yyyy"
                                placeholderText={
                                  formattedDate2 ? formattedDate2 : currentDate
                                } // isClearable={true}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <label>To</label>
                              <DatePicker
                                selected={selectedDate2}
                                onChange={handleDateChange2}
                                dateFormat="01-MM-yyyy"
                                placeholderText="select Date"
                                // isClearable={true}
                                yearDropdown
                                scrollableYearDropdown
                                showYearDropdown={true}
                              />
                            </div>
                          )}

                          <select
                            onChange={StaffChangeHandler}
                            id="select1"
                            class="ct_listing border_radius ct_listing_float ct_listing_margin "
                            style={{
                              borderWidth: '1px',
                              borderRadius: '5px',
                              width: '190px',
                              marginBottom: '9px',
                              borderColor: '0.5px solid black',
                              marginTop: '43px',

                              height: '30px'
                            }}
                          >
                            {clientLists.map((e) => {
                              return (
                                <option value={e.id}>{e.owner_name}</option>
                              );
                            })}
                            <option value="">Select Client</option>
                            {console.log('id', id)}
                          </select>
                          {current_report ? (
                            <select
                              onChange={StageChangeHandler}
                              id="select1"
                              value={stageId}
                              className="ct_listing border_radius ct_listing_float ct_listing_margin"
                              style={{
                                borderWidth: '1px',
                                borderRadius: '5px',
                                width: '190px',
                                marginBottom: '9px',
                                borderColor: '0.5px solid black',
                                marginTop: '43px',
                                height: '30px'
                              }}
                            >
                              {stageData.map((e) => {
                                return <option value={e.id}>{e.stage}</option>;
                              })}
                              <option value="">Select Stage</option>
                              {console.log('id', id)}
                            </select>
                          ) : (
                            <select
                              onChange={StagechangeHadler2}
                              id="select1"
                              class="ct_listing border_radius ct_listing_float ct_listing_margin "
                              style={{
                                borderWidth: '1px',
                                borderRadius: '5px',
                                width: '190px',
                                marginBottom: '9px',
                                borderColor: '0.5px solid black',
                                marginTop: '43px',

                                height: '30px'
                              }}
                            >
                              {stageData.map((e) => {
                                return <option value={e.id}>{e.stage}</option>;
                              })}
                              <option value="">Select Stage</option>
                              {console.log('id', id)}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="wrapper">
                      <div
                        className="spinner-container"
                        style={{ marginLeft: '500px' }}
                      >
                        <BounceLoader
                          color={'#123abc'}
                          loading={loading}
                          css={override}
                          size={60}
                        />
                      </div>

                      <table
                        id="example2"
                        className="table table-bordered table-hover"
                      >
                        <thead>
                          <tr>
                            <th style={{ fontSize: '14px' }}>
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  onClick={() =>
                                    handleArrowClick('owner_name', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'owner_name' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                  style={{ color: 'grey' }}
                                ></i>
                              </span>
                              Staff Name
                            </th>
                            <th style={{ fontSize: '14px' }}>
                              {' '}
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  style={{ color: 'grey' }}
                                  onClick={() =>
                                    handleArrowClick('owner_email', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'owner_email' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                ></i>
                              </span>
                              Client Name
                            </th>
                            <th style={{ fontSize: '14px' }}>
                              {' '}
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  onClick={() =>
                                    handleArrowClick('owner_email', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'owner_email' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                  style={{ color: 'grey' }}
                                ></i>
                              </span>
                              Title
                            </th>
                            <th style={{ fontSize: '14px' }}>
                              {' '}
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  onClick={() =>
                                    handleArrowClick('organisation', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'organisation' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                  style={{ color: 'grey' }}
                                ></i>
                              </span>
                              Organisation
                            </th>
                            <th style={{ fontSize: '14px' }}>
                              {' '}
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  onClick={() =>
                                    handleArrowClick('name', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'name' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                  style={{ color: 'grey' }}
                                ></i>
                              </span>
                              Lead Date
                            </th>
                            <th style={{ fontSize: '14px' }}>
                              {' '}
                              <span style={{ marginRight: '3px' }}>
                                <i
                                  onClick={() =>
                                    handleArrowClick('created_at', 'asc')
                                  }
                                  class={
                                    isArrowObj &&
                                    isArrowObj.column == 'created_at' &&
                                    isArrowObj.orderby == 'desc'
                                      ? 'fa fa-arrow-down'
                                      : 'fa fa-arrow-up'
                                  }
                                  style={{ color: 'grey' }}
                                ></i>
                              </span>
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientData.map((e, index) => {
                            return (
                              <tr key={index}>
                                <td>{e.staff ? e.staff.name : '--'}</td>
                                <td>{e.lead_name ? e.lead_name : ''}</td>
                                <td>{e.title ? e.title : ''}</td>
                                <td>
                                  {e.lead_organisation
                                    ? e.lead_organisation
                                    : ''}
                                </td>

                                <td>{e.created_at ? e.created_at : ''}</td>
                                <td>
                                  {e.leadstage.stage ? e.leadstage.stage : ''}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="active"
                        onPageChange={handlePageClick}
                      />
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </Constant>
  );
};
export default ManageLead;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { useSelector, useDispatch } from 'react-redux';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { setStatus } from '../Redux/Action/authaction';

const CategoryInfo = () => {
  const dispatch = useDispatch();

  const { parentCategoryId } = useParams();
  const { categoryId } = useParams();

  console.log("<><><>", parentCategoryId)

  console.log('categoryid ----->>>', categoryId);
  const [pageNumber, setPageNumber] = useState(0);

  const navigate = useNavigate();
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  console.log('sadsfdgffh', token);
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);

  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..................------->>>>>>', permissions);
  //STATE FOR MADAL
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [catIdToDelete, setcatIdToDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [btn, setBtn] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const confirmDeleteHandler = (e) => {
    deleteRowHandler(catIdToDelete);
  };

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [subCategory, setSubCategory] = useState([]);

  const [isArrowObj, setIsArrowObj] = useState({
    column: 'name',
    orderby: 'asc'
  });

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,
    isArrowObj,
    subCategory,
    parentCategoryId,
    categoryId,
    pageCount
  ]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      parent_id: parentCategoryId
    };
    console.log('loadinggggtrue', loading);
    axios
      .get(
        Url.BASEURL +
        'listcategory?' +
        'search=' +
        searchClient +
        '&parent_id=' +
        parentCategoryId +
        // '&category_id=' +
        // categoryId +
        (categoryId !== undefined ? '&category_id=' + categoryId : '') +
        '&page=' +
        itemOffset +
        '&client_id=' +
        id +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2 +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,

        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data.data) &
          setBtn(value.data.response) &
          setPageCount(value.data.response.data.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected);
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(1);
    setSearchClientByName(searchClientByName);
  };

  // const reloadPage = () => {
  //   return navigate(window.location.pathname);
  // };

  const handleSubCategory = (param) => {
    console.log('param', param);
    if (subCategory && subCategory.length > 0) {
      const subCategoryData = subCategory.find((data) => data.id === param);
      setSubCategory(subCategoryData.subcategories);
    } else {
      const subCategoryData = clientData.find((data) => data.id === param);
      setSubCategory(subCategoryData.subcategories);
    }
    console.log('subCategory', subCategory);
  };

  // function for delete row

  const deleteRowHandler = (brandId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: brandId
    };
    axios
      .delete(Url.BASEURL + 'deletecategory/' + brandId, config, params)
      .then((value) => {
        setDeleteRow(value.data.response) && navigate('/listcategory');
        getBrandList();
        console.log('getBrandList', getBrandList());
      })
      .catch((err) => console.log(err));
  };

  const reloadPage = () => {
    setItemOffset(1);
    setPageCount(1);
    setClicked(true);
    return navigate(window.location.pathname);
  };

  dispatch(setStatus(parentCategoryId));

  const handleClearInput = () => {
    setSearchClient('');
  };

  // {console.log('btn.parent_category',btn[0]?.parent_category)}
  return (
    <>
      <Constant>
        {/* <BreadScrum path="/listcatgory/0" />{' '} */}
        <BreadScrum
          routepath={`addcategory/${parentCategoryId}`}
          heading={'Manage Category'}
          btn={btn.category !== 'parent' ? 'Add Sub-Category' : 'Add Category'}
          bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={
            type === 'superadmin' ||
              permissions?.Manage_Categories?.includes('add')
              ? false
              : true
          }
        />{' '}
        <div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {/* /.card-header */}
                    <div
                      className="card-body table-responsive"
                      style={{ overflow: 'hidden' }}
                    >
                      <div
                        className="search-field d-none d-md-block"
                        style={{ marginBottom: '13px' }}
                      >
                        <div className="">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              className="search-field d-none d-md-block"
                              style={{ marginBottom: '13px' }}
                            >
                              <div
                                style={{ marginTop: '19px' }}
                                className="d-flex align-items-center h-100 searchbar_parent"
                                action="#"
                              >
                                <div
                                  className=" position-relative"
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    border: '1px solid black'
                                  }}
                                >
                                  <div className="input-group-prepend bg-transparent"></div>
                                  <input
                                    style={{
                                      height: 30,
                                      paddingRight: 30
                                    }}
                                    onChange={(e) =>
                                      setSearchClient(e.target.value) &
                                      handleKeyPress()
                                    }
                                    type="text"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Search"
                                    value={searchClient}
                                  />
                                  {searchClient && (
                                    <span
                                      className="position-absolute top-50 end-0 translate-middle-y"
                                      style={{
                                        cursor: 'pointer',
                                        marginRight: 10
                                      }}
                                      onClick={handleClearInput}
                                    >
                                      ✕
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wrapper">
                        {loading ? (
                          <div
                            className="spinner-container"
                            style={{ marginLeft: '500px' }}
                          >
                            <BounceLoader
                              color={'#123abc'}
                              loading={loading}
                              css={override}
                              size={60}
                            />
                          </div>
                        ) : (
                          <table
                            id="example2"
                            className="table table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th>
                                  {' '}
                                  {parentCategoryId == 0
                                    ? 'Parent Category'
                                    : 'Sub Category'}
                                </th>
                                {/* <th>
                                  {parentCategoryId == 0
                                    ? 'Sub Category'
                                    : null}
                                </th> */}
                                {type === 'superadmin' ||
                                  permissions?.Manage_Categories?.includes(
                                    'view'
                                  ) ||
                                  permissions?.Manage_Categories?.includes(
                                    'update'
                                  ) ||
                                  permissions?.Manage_Categories?.includes(
                                    'delete'
                                  ) ? (
                                  <th style={{ textAlign: 'center' }}>
                                    Action
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {clientData.map((e) => {
                                return (
                                  <tr>
                                    {console.log('=====>>>', e)}
                                    <td>{e.name}</td>
{/* 
                                    <td>
                                      <Link
                                        onClick={reloadPage}
                                        to={'/listcategory/' + e.id}
                                        className="nav-icon fas fa-bars"
                                      ></Link>
                                      <Link
                                        onClick={reloadPage}
                                        to={
                                          '/listcategory/' +
                                          // e.parent_category +
                                          // '/' +
                                          e.id
                                        }
                                        className="nav-icon fas fa-bars"
                                      ></Link>
                                    </td> */}
                                    {type === 'superadmin' ||
                                      permissions?.Manage_Categories?.includes(
                                        'view'
                                      ) ||
                                      permissions?.Manage_Categories?.includes(
                                        'update'
                                      ) ||
                                      permissions?.Manage_Categories?.includes(
                                        'delete'
                                      ) ? (
                                      <td>
                                        <div className="col-12 action_flex justify-content-around">
                                          {type === 'superadmin' ||
                                            permissions?.Manage_Categories?.includes(
                                              'view'
                                            ) ? (
                                            <Link
                                              to={
                                                '/viewcategory/' +
                                                e.parent_category +
                                                '/' +
                                                e.id
                                              }
                                              className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                            ></Link>
                                          ) : null}

                                          {type === 'superadmin' ||
                                            permissions?.Manage_Categories?.includes(
                                              'update'
                                            ) ? (
                                            <Link
                                              to={
                                                '/managecategory/' +
                                                e.parent_category +
                                                '/' +
                                                e.id
                                              }
                                              className="nav-icon fas fa-edit"
                                            ></Link>
                                          ) : null}
                                          {type === 'superadmin' ||
                                            permissions?.Manage_Categories?.includes(
                                              'delete'
                                            ) ? (
                                            <i
                                              onClick={() => {
                                                handleShow();
                                                setcatIdToDelete(e.id);
                                              }}
                                              className="nav-icon fas fa-times pointer_cursor"
                                            />
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          containerClassName="pagination"
                          activeClassName="active"
                          onPageChange={handlePageClick}
                          forcePage={pageNumber}
                        />

                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete the Category?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleShow2();
                handleClose();
                confirmDeleteHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>{deleteRow}</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose2}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default CategoryInfo;

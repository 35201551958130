const initialState = {
  token: null,
  status: null
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload.token
      };
    case 'DEL_TOKEN':
      return {
        ...state,
        token: null
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status
      };
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload.category
      };
    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload.permissions
      };
    case 'SET_ROLE':
      return {
        ...state,
        type: action.payload.type
      };
    case 'DEL_PASSWORD':
      return {
        ...state,
        password: null
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload.email
      };
    case 'SET_PASSWORD_TOKEN':
      return {
        ...state,
        passwordtoken: action.payload.passwordtoken
      };
    default:
      return state;
  }
};
export default authReducer;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Url from '../Element/Api';
const AddCategory = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState('');
  const [category, setCategory] = useState([]);
  const token = useSelector((state) => state.token);
  const [error, setError] = useState('');
  const [productcategory, setProductCategory] = useState([]);
  const { parentCategoryId ,subCategoryId} = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [userData, setUserData] = useState({
    pname: '',
    category: ''
  });
  // const [parentCategoryId ,subCategoryId]
  console.log('parentCategoryId', parentCategoryId,'subCategoryId',subCategoryId);
  const [addModal, setAddModal] = useState(false);
  // let { categoryId, subCategoryId } = useParams();

  const [clicked, setClicked] = useState(false);

  // funciton to get select category type
  const categoryChangeHandler = (event) => {
    setCategoryId(event.target.value);
  };
  //   console.log('dsasd', userData.pname);
  const handleInputChange = (e) => {
    console.log('category', e.target.value);
    setCategory(e.target.value);
  };

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Methods': '*'
      }
    };
    const params = {
      parent_category: parentCategoryId,
      name: category,
      enabled: isChecked
    };
    axios
      .post(Url.BASEURL + 'addcategory/' + parentCategoryId, params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/listcategory/' + status);
          // toast('Client added Successfully');
          setCategory(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          closeAddModal();
          setError(value.data.errors);
        }
      })
      .catch((err) => {
        console.log(err);
        closeAddModal();
      });
  };
  // function to list parent category

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allcategory', config)
      .then((value) => setProductCategory(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  const status = useSelector((state) => state.status);

  // function to check uncheck active status

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listcategory/' + status);
  }
  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }
  // function for reload page
  const reloadPage = (id) => {
    console.log('ffhf');
    navigate(`/listcategory/${id}`);
  };
  return (
    <Constant>
      <BreadScrum path="/listcategory/0" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>Category added successfully</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={closeModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addModal}
            onHide={closeAddModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Category Add</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Are you sure, You want to add this Category?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={submitHandler}
              >
                Yes
              </Button>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={() => closeAddModal(false)}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Category</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body col-8">
                      <div className="card-body">
                        {parentCategoryId != 0 ? (
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Parent Category
                            </label>
                            <select
                              disabled
                              id="select1"
                              value={parentCategoryId}
                              className=" form-control"
                            >
                              {productcategory.map((e) => {
                                return <option value={e.id}>{e.name}</option>;
                              })}
                              {console.log('categoryId', categoryId)}
                              <option value={categoryId}>
                                Select Parent Category
                              </option>
                            </select>
                          </div>
                        ) : (
                          <span></span>
                        )}
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="required"
                          >
                            Category Name
                          </label>
                          <input
                            onChange={handleInputChange}
                            value={category}
                            name="name"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Category Name"
                          />
                          {error && error.name ? (
                            <div style={{ color: 'red' }}>{error.name}</div>
                          ) : null}
                        </div>

                        <div className="form-group active_status_flex m-0 align-items-center pl-1">
                          {/* <div className="col-6"> */}
                          {/* <div className="form-group active_status_flex"> */}
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          {/* </div> */}
                          {/* </div> */}

                          <label
                            htmlFor="exampleInputEmail1"
                            className="m-0 pl-3"
                          >
                            Active Status
                          </label>
                        </div>
                      </div>
                      <div className="row pl-2">
                        <div
                          className="col-4"
                          style={{
                            display: 'flex',

                            marginLeft: '10px',
                            marginBottom: '10px'
                          }}
                        >
                          <div className="">
                            <button
                              onClick={openAddModal}
                              // className="btn btn-primary"
                              className="btn text-white"
                              style={{ backgroundColor: '#1e336a' }}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="" style={{ marginLeft: '20px' }}>
                            <button
                              onClick={() => {
                                reloadPage(parentCategoryId);
                              }}
                              // onClick={reloadPage}
                              // className="btn btn-primary"
                              className="btn text-black"
                              style={{ backgroundColor: '#DCDCDC' }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default AddCategory;

import React, { useState, useEffect } from 'react'
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { useSelector } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import axios from 'axios'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'



const Subcategoriesinfo = () => {
    const override = css`
    display: block;
    margin: 0 auto;
  `;
    const [searchClient, setSearchClient] = useState('');
    const [loading, setLoading] = useState(false);
    const [itemOffset, setItemOffset] = useState(1);
    const [subCategories, setSubCategories] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [subId, setSubId] = useState('')
    const [deleteRow, setDeleteRow] = useState([]);



    const permissions = useSelector((state) => state.permissions);
    const type = useSelector((state) => state.type);
    const token = useSelector((state) => state.token);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const handleClearInput = () => {
        setSearchClient('');
    };
    useEffect(() => {
        getSubCategoryList();
    }, [searchClient, itemOffset, pageCount]);
    // deleteRow

    //get sub category api
    const getSubCategoryList = () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        axios
            .get(
                Url.BASEURL +
                'list-sub-category?' +
                'search=' +
                searchClient +
                '&page=' +
                itemOffset,
                config
            )
            .then(
                (value) =>
                    setSubCategories(value.data.response.data) &
                    setPageCount(value.data.response.last_page) &
                    setLoading(false)
            )
            .catch((err) => console.log(err));
    };


    const deleteRowHandler = () => {
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        // const params = {
        //     id: subId
        // };
        axios
            .delete(Url.BASEURL + 'delete-sub-category/' + subId, config)
            .then((value) => {
                setDeleteRow(value.data.response);
                getSubCategoryList();
                setSearchClient('');
                // setSearchClientByName('');
            })
            .catch((err) => console.log(err));
    };

    // function for pagination
    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        console.log('newOffset', newOffset);

        setItemOffset(newOffset);
    };
    // function for search
    // const handleKeyPress = () => {
    //     setItemOffset(0);
    //     setSearchClient(searchClient);
    // };
    return (
        <Constant>
            <BreadScrum
                routePath={'listsubcategory'}
                heading={'Manage Sub-Categories'}
                btn={'Add Sub-Category'}
                routepath={'addsubcategory'}
                bgColor={'#1E336A'}
                text_color={'#FFFFFF'}
                disabled={
                    type === 'superadmin' || permissions?.Manage_Sub_Category?.includes('add')
                        ? false
                        : true
                }
            />
            <div>
                <section className="content section_padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    {/* /.card-header */}
                                    <div
                                        className="card-body table-responsive"
                                        style={{ overflow: 'hidden' }}
                                    >
                                        <div
                                            className="search-field d-none d-md-block"
                                            style={{ marginBottom: '13px' }}
                                        >
                                            <div className="">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <div
                                                        className="search-field d-none d-md-block"
                                                        style={{ marginBottom: '13px' }}
                                                    >
                                                        <div
                                                            style={{ marginTop: '19px' }}
                                                            className="d-flex align-items-center h-100 searchbar_parent"
                                                            action="#"
                                                        >
                                                            <div
                                                                className=" position-relative"
                                                                style={{
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    border: '1px solid black'
                                                                }}
                                                            >
                                                                <div className="input-group-prepend bg-transparent"></div>
                                                                <input
                                                                    style={{
                                                                        height: 30,
                                                                        paddingRight: 30
                                                                    }}
                                                                    onChange={(e) =>
                                                                        setSearchClient(e.target.value)
                                                                        // handleKeyPress()
                                                                    }
                                                                    type="text"
                                                                    className="form-control bg-transparent border-0"
                                                                    placeholder="Search"
                                                                    value={searchClient}
                                                                />
                                                                {searchClient && (
                                                                    <span
                                                                        className="position-absolute top-50 end-0 translate-middle-y"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            marginRight: 10
                                                                        }}
                                                                        onClick={handleClearInput}
                                                                    >
                                                                        ✕
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrapper">
                                            {loading ? (
                                                <div
                                                    className="spinner-container"
                                                    style={{ marginLeft: '500px' }}
                                                >
                                                    <BounceLoader
                                                        color={'#123abc'}
                                                        loading={loading}
                                                        css={override}
                                                        size={60}
                                                    />
                                                </div>
                                            ) : (
                                                <table
                                                    id="example2"
                                                    className="table table-bordered table-hover"
                                                >
                                                    <thead>
                                                        <tr>

                                                            <th className="tablehead">
                                                                Sub Category
                                                            </th>
                                                            <th className="tablehead">

                                                                Parent Category
                                                            </th>

                                                            {type === 'superadmin' ||
                                                                permissions?.Manage_Sub_Category?.includes('view') ||
                                                                permissions?.Manage_Sub_Category?.includes(
                                                                    'update'
                                                                ) ||
                                                                permissions?.Manage_Sub_Category?.includes(
                                                                    'delete'
                                                                ) ? (
                                                                <th style={{ textAlign: 'center' }}>Actions</th>
                                                            ) : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subCategories.map((e, index) => {

                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td>{index + 1}</td> */}
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {e?.sub_category_name}
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {e?.parent_category_name ? e?.parent_category_name : ''}
                                                                    </td>

                                                                    {type === 'superadmin' ||
                                                                        permissions?.Manage_Products?.includes(
                                                                            'view'
                                                                        ) ||
                                                                        permissions?.Manage_Products?.includes(
                                                                            'update'
                                                                        ) ||
                                                                        permissions?.Manage_Products?.includes(
                                                                            'delete'
                                                                        ) ? (
                                                                        <td>
                                                                            <div className="col-12 action_flex">
                                                                                {type === 'superadmin' ||
                                                                                    permissions?.Manage_Products?.includes(
                                                                                        'view'
                                                                                    ) ? (
                                                                                    <Link
                                                                                        to={'/subcategoryDetail/' + e.id}
                                                                                        className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                                                                    ></Link>
                                                                                ) : null}
                                                                                {type === 'superadmin' ||
                                                                                    permissions?.Manage_Products?.includes(
                                                                                        'update'
                                                                                    ) ? (
                                                                                    <Link
                                                                                        to={'/editsubcategory/' + e.id}
                                                                                        className="nav-icon fas fa-edit"
                                                                                    ></Link>
                                                                                ) : null}
                                                                                {type === 'superadmin' ||
                                                                                    permissions?.Manage_Products?.includes(
                                                                                        'delete'
                                                                                    ) ? (
                                                                                    <i
                                                                                        onClick={() => {
                                                                                            // setUserId(e.id);
                                                                                            handleShow();
                                                                                            setSubId(e.id);
                                                                                            // deleteRowHandler(e.id);
                                                                                        }}
                                                                                        className="nav-icon fas fa-times pointer_cursor"
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </td>
                                                                    ) : null}
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>

                                                </table>
                                            )}
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                onPageChange={handlePageClick}
                                            />
                                        </div>
                                        {show && (
                                            <Modal
                                                show={show}
                                                onHide={handleClose}
                                                backdrop={'static'}
                                                keyboard={false}
                                                centered
                                            >
                                                <Modal.Header
                                                    closeButton
                                                    style={{
                                                        backgroundColor: '#1e336a',
                                                        color: 'white',
                                                        border: 'none'
                                                    }}
                                                >
                                                    <Modal.Title>Delete Sub-Category</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body style={{ fontSize: '20px' }}>
                                                    Are you sure you want to delete this Sub-Category?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button
                                                        style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                                                        onClick={() => {
                                                            handleShow2();
                                                            handleClose();
                                                            deleteRowHandler();
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        )}
                                        {show2 && (
                                            <Modal
                                                show={show2}
                                                onHide={handleClose2}
                                                backdrop={'static'}
                                                keyboard={false}
                                                centered
                                            >
                                                <Modal.Header
                                                    closeButton
                                                    style={{
                                                        backgroundColor: '#1e336a',
                                                        color: 'white',
                                                        border: 'none'
                                                    }}
                                                >
                                                    <Modal.Title>Delete Sub-Category</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body style={{ fontSize: '20px' }}>
                                                    Sub-Category Deleted Sucessfully.
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                                                        onClick={handleClose2}
                                                    >
                                                        Ok
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        )}
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
        </Constant>
    )
}

export default Subcategoriesinfo
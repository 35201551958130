import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Url from '../Element/Api';
import axios from 'axios';
import { passwordSchema } from './ValidationSchema';
import Swal from 'sweetalert2'
import PasswordStrength from './PasswordStrength';

// import {
//   setToken,
//   setPermissions,
//   setRole,
//   setType
// } from '../Redux/Action/Authaction';
import { setPermissions, setToken, setType } from '../Redux/Action/authaction';
const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const [loginResponse, setLoginResponse] = useState([]);
  // const [permission,setPermissions] =useState([]);
  // const [role,setRole] =useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checknew = useSelector((e) => console.log('eeeeeeeee', e));
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  const savedtoken = useSelector((state) => state.passwordtoken);

  // dispatch(permission)
  // console.log('permission..............................',permission);
  const submitHandle = (e) => {
    // navigate('/');
    if (newPassword && confirmPassword) {
      setLoading(true)
      const config = {
        headers: {
          Authorization: `Bearer ${savedtoken}`
        }
      };
      const params = {
        password: newPassword,
        password_confirmation: confirmPassword
      };
      axios
        .post(Url.BASEURL + 'reset-password', params, config)
        .then((value) => {
          if (value.data.status === 200) {
            setLoading(false)
            Swal.fire({
              title: "Reset Password",
              text: "Password Reset Successful",
              icon: "success"
            });
            navigate('/');
          } else {
            setLoading(false)
            // setError('please enter valid credentials');
          }
        })

        .catch((err) => console.log(err));
    }
  };

  const handlePasswordValidation = async ({ options }) => {
    let errors = {};
    try {
      const pswValidate = await passwordSchema.validate({
        newPassword: options.newPassword,
        confirmPassword: options.confirmPassword
      }, {
        abortEarly: false
      });
      if (pswValidate) {
        submitHandle();
      }
    } catch (validationError) {
      if (validationError.name === 'ValidationError') {
        validationError.inner.forEach(err => {
          errors[err.path] = err.message;
        });
      }
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
    }
  };

  return (
    <div className="hold-transition login-page">
      <img
        src="dist/img/symang_logo_za.png"
        alt="AdminLTE Logo"
        // className="brand-image img-circle elevation-3"
        style={{
          width: '224px',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          // marginLeft: '133px',
          marginBottom: '20px'
        }}
      //   style={{ opacity: '.8' }}
      />{' '}
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Unlock Your Account: Reset Password</p>
            {/* {error ? (
              <div
                style={{
                  textAlign: 'center',
                  margin: '10px 0px',
                  background: '#FFCCCB',
                  color: 'red'
                }}
                className="alert "
                role="alert"
              >
                {error}
              </div>
            ) : null} */}
            <div>
              <div className="input-group mb-1">
                <input
                  onChange={(e) => setNewPassword(e.target.value)}
                  type={isPasswordVisible ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Enter New Password"
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </div>
                </div>
              </div>
              <PasswordStrength password={newPassword} />
              <div className="input-group mb-3">
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  // type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                />
                <div className="input-group-append">
                  <div
                    className="input-group-text"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {isConfirmPasswordVisible ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </div>
                  {/* <div className="input-group-text">
                    <span className="fas fa-eye" />
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p className="mb-1">
                    <Link to="/">Back to Login</Link>
                  </p>
                </div>
                <div className="col-6">
                  <button
                    onClick={
                      handlePasswordValidation({
                        options: {
                          newPassword: newPassword,
                          confirmPassword: confirmPassword
                        }
                      })
                    }
                    // type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Sumbit
                  </button>
                </div>
              </div>
            </div>
            {/* /.social-auth-links */}
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div >
  );
};
export default SetNewPassword;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

import { useNavigate, useParams } from 'react-router-dom';

const ManageClients = () => {
  let { clientId } = useParams();
  console.log('clientId', clientId);
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [updateClientData, setUpdateclientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [response2, setResponse2] = useState([]);
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [industry, setIndustry] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);
  const token = useSelector((state) => state.token);
  //dynamic input fields
  const [inputFields, setInputFields] = useState([]);

  // const inputParam = inputFields.map((field, key) => ({
  //   [`contact_person_name_${key + 1}`]: field.value1,
  //   [`contact_person_email_${key + 1}`]: field.value2,
  //   [`contact_person_mobile_${key + 1}`]: field.value3,
  //   [`contact_person_designation_${key + 1}`]: field.value4,
  // }));

  // console.log(inputParam);

  const handleInputChange = (index, fieldName, value) => {
    setInputFields((prevInputFields) => {
      const updatedFields = [...prevInputFields];
      updatedFields[index][fieldName] = value;
      return updatedFields;
    });
  };

  const handleAddFields = () => {
    // Check the total number of fields added
    if (inputFields.length < 3) {
      setInputFields([...inputFields, {}]);
    }
  };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };
  // state for modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // function for select staff
  // console.log('staffid', clientId);
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  // api function for get staff id
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffsdropdown', config)
      .then((value) => setStaffId(value.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (staffId) {
      setResponse(staffId);
    }
  }, [staffId]);
  // function for store clients info
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'editclient/' + clientId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          console.log('value.data.response==>>', value.data.response);
          setClientData(value.data.response);
          // set(value.data.response[0].clientInfo)
          setIsChecked(value.data.response.enabled);
          setId(value.data.response.staff_id);
          setId2(value.data.response.industry_id);
          setInputFields(value.data.response.clientInfo);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  console.log('inputs>>>>>>>>>>>>>>>>>>>', inputFields);
  // state for add user data

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    setClientData({ ...clientData, [name]: value });
    // setName(value);
    // setEmail(value);
    console.log('formState------------>>>>>>', value);
  };
  const updateHandle = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    console.log('ewef');

    const params = {
      clientInfo: inputFields,
      organisation: clientData.organisation,
      address: clientData.address,
      gst_no: clientData.gst_no,
      notes: clientData.notes,
      industry_id: id2,
      staff_id: id,
      owner_name: clientData.owner_name,
      owner_email: clientData.owner_email,
      owner_phone: clientData.owner_phone,
      enabled: isChecked
    };
    axios.defaults.headers.put['Content-Type'] =
      'application/json;charset=utf-8';
    axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';

    axios
      .put(Url.BASEURL + 'updateclient/' + clientId, params, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          setUpdateclientData(value.data.response);
          setShow(true);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
    // navigate('/listclients');
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  // function for selct/unselect active status of client

  const handleCheckboxChange = (event) => {
    console.log('event', event.target);
    setIsChecked(isChecked === 1 ? 0 : 1);
  };

  const IndustryChangeHandler = (event) => {
    setId2(event.target.value);
  };
  // function to reload the page and navigate to the lsiting client
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listclients');
  };
  const transformDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));

    return [selectOption, ...dataOptions];
  };

  const options = transformDataToOptions(staffId);
  const myStaffValue = options.find(
    (e) => console.log('eeeeeeeeee', e)
    // e.label == industryType
  );

  const transformIndustryToOptionsData = (data) => {
    // console.log('datadatatdatdtatata', data);
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type, email }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };

  // const [iName, setIName] = useState('');
  // const [industryId, setIndustryId] = useState('');
  const optionsData = transformIndustryToOptionsData(industry);

  const HandleIndustry = (selectedOption) => {
    console.log('000000000selectedOption', selectedOption);
    setId2(selectedOption.value);
    // Your additional logic here
  };

  const HandleStaff = (selectedOption) => {
    setId(selectedOption.value);
  };
  return (
    <>
      <Constant>
        <BreadScrum path="/listclients" />{' '}
        <div className="wrapper">
          <section className="content section_padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Client Information</h3>
                    </div>
                    {/* /.card-header */}
                    {/* form start */}
                    <div>
                      <div className="card-body">
                        <p className="h5 m-0 px-1 py-2">
                          Organisation Details:
                        </p>
                        <div className="clientinfo-box">
                          <div className="dynamic-input ">
                            <div className="row align-items-center justify-content-between inputrow">
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    <span className="required">
                                      GSTIN Number
                                    </span>
                                  </label>
                                  <input
                                    maxLength={15}
                                    onChange={handleInputChange1}
                                    value={clientData.gst_no}
                                    name="gst_no"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Enter GSTIN Number"
                                  />
                                  {error && error.gst_no ? (
                                    <div style={{ color: 'red' }}>
                                      {error.gst_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail2">
                                    <span className="required">
                                      Organisation
                                    </span>
                                  </label>
                                  <input
                                    onChange={handleInputChange1}
                                    value={clientData.organisation}
                                    name="organisation"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail2"
                                    placeholder="Enter Organisation"
                                  />
                                  {error && error.organisation ? (
                                    <div style={{ color: 'red' }}>
                                      {error.organisation}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail3">
                                    <span className="required">Address</span>
                                  </label>
                                  <input
                                    onChange={handleInputChange1}
                                    value={clientData.address}
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail3"
                                    placeholder="Enter Address"
                                  />
                                  {error && error.address ? (
                                    <div style={{ color: 'red' }}>
                                      {error.address}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail4">
                                    <span className="required">
                                      Industry Type
                                    </span>
                                  </label>
                                  <Select
                                    // defaultValue={myVlue}
                                    value={optionsData.find(
                                      (e) => e.value == id2
                                    )}
                                    // defaultValue={industryType}
                                    onChange={HandleIndustry}
                                    options={optionsData}
                                    id="exampleInputEmail4"
                                    // isClearable={true}
                                  />
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* client Information */}
                        <p className="h5 m-0 px-1 py-2">Client Details:</p>
                        <div className="clientinfo-box">
                          <div className="dynamic-input ">
                            <div
                              className="row "
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                              }}
                            >
                              <div
                                className="inputs"
                                style={{ marginLeft: '-45px' }}
                              >
                                <div className="form-group">
                                  <label htmlFor={`contactPersonName${''}`}>
                                    <span style={{ fontSize: '15px' }}>
                                      Owner Name
                                    </span>
                                  </label>
                                  <input
                                    placeholder="Enter Owner Name"
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ''
                                      ))
                                    }
                                    minLength={3}
                                    onChange={handleInputChange1}
                                    value={clientData.owner_name}
                                    name="owner_name"
                                    type="text"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor={`contactPersonName${''}`}>
                                    <span style={{ fontSize: '15px' }}>
                                      Owner Email
                                    </span>
                                  </label>
                                  <input
                                    placeholder="Enter Owner Name"
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ''
                                      ))
                                    }
                                    minLength={3}
                                    onChange={handleInputChange1}
                                    value={clientData.owner_email}
                                    name="owner_email"
                                    type="text"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor={`contactPersonName${''}`}>
                                    <span style={{ fontSize: '15px' }}>
                                      Owner Contact Number
                                    </span>
                                  </label>
                                  <input
                                    placeholder="Enter Owner Contact Number"
                                    maxLength={10}
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ''
                                      ))
                                    }
                                    onChange={handleInputChange1}
                                    value={clientData.owner_phone}
                                    name="owner_phone"
                                    type="text"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {inputFields.map((field, index) => (
                              <div
                                className="row align-items-center justify-content-between inputrow"
                                key={index}
                              >
                                <div className="inputs">
                                  <div className="form-group">
                                    <label
                                      htmlFor={`contactPersonName${index}`}
                                    >
                                      <span className="required">
                                        Contact Person Name
                                      </span>
                                    </label>
                                    <input
                                      minLength={3}
                                      onInput={(e) =>
                                        (e.target.value =
                                          e.target.value.replace(
                                            /[^a-zA-Z ]/g,
                                            ''
                                          ))
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          `contact_person_name_${index}`,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        field[`contact_person_name_${index}`]
                                      }
                                      name="owner_name"
                                      type="text"
                                      className="form-control"
                                      id={`contactPersonName${index}`}
                                      placeholder={`Enter Name ${index + 1}`}
                                    />
                                    {error &&
                                      error.clientInfo &&
                                      error.clientInfo[index] && (
                                        <div>
                                          {error.clientInfo[index][
                                            `contact_person_name_${index}`
                                          ] && (
                                            <div style={{ color: 'red' }}>
                                              {
                                                error.clientInfo[index][
                                                  `contact_person_name_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="inputs">
                                  <div className="form-group">
                                    <label
                                      htmlFor={`contactPersonEmail${index}`}
                                    >
                                      <span className="required">
                                        Contact Person's Email address
                                      </span>
                                    </label>
                                    <input
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          `contact_person_email_${index}`,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        field[`contact_person_email_${index}`]
                                      }
                                      name="owner_email"
                                      type="email"
                                      className="form-control"
                                      id={`contactPersonEmail${index}`}
                                      placeholder={`Enter email ${index}`}
                                    />
                                    {error &&
                                      error.clientInfo &&
                                      error.clientInfo[index] && (
                                        <div>
                                          {error.clientInfo[index][
                                            `contact_person_email_${index}`
                                          ] && (
                                            <div style={{ color: 'red' }}>
                                              {
                                                error.clientInfo[index][
                                                  `contact_person_email_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                          {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="inputs">
                                  <div className="form-group">
                                    <label
                                      htmlFor={`contactPersonMobile${index}`}
                                    >
                                      <span className="required">
                                        Contact Person's Mobile Number
                                      </span>
                                    </label>
                                    <input
                                      onInput={(e) =>
                                        (e.target.value =
                                          e.target.value.replace(/[^0-9]/g, ''))
                                      }
                                      maxLength={10}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          `contact_person_mobile_${index}`,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        field[`contact_person_mobile_${index}`]
                                      }
                                      name="owner_contact_no"
                                      type="text"
                                      className="form-control"
                                      id={`contactPersonMobile${index}`}
                                      placeholder={`Enter Mobile Number ${
                                        index + 1
                                      }`}
                                    />
                                    {error &&
                                      error.clientInfo &&
                                      error.clientInfo[index] && (
                                        <div>
                                          {error.clientInfo[index][
                                            `contact_person_mobile_${index}`
                                          ] && (
                                            <div style={{ color: 'red' }}>
                                              {
                                                error.clientInfo[index][
                                                  `contact_person_mobile_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                          {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="inputs">
                                  <div className="form-group">
                                    <label
                                      htmlFor={`contactPersonDesignation${index}`}
                                    >
                                      <span className="required">
                                        Contact Person Designation
                                      </span>
                                    </label>
                                    <input
                                      // onInput={(e) =>
                                      //   (e.target.value =
                                      //     e.target.value.replace(
                                      //       /[^a-zA-Z]/g,
                                      //       ''
                                      //     ))
                                      // }
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          `contact_person_designation_${index}`,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        field[
                                          `contact_person_designation_${index}`
                                        ]
                                      }
                                      name="designation_name"
                                      type="text"
                                      className="form-control"
                                      id={`contactPersonDesignation${index}`}
                                      placeholder={`Enter Contact Person Designation`}
                                    />
                                    {error &&
                                      error.clientInfo &&
                                      error.clientInfo[index] && (
                                        <div>
                                          {error.clientInfo[index][
                                            `contact_person_designation_${index}`
                                          ] && (
                                            <div style={{ color: 'red' }}>
                                              {
                                                error.clientInfo[index][
                                                  `contact_person_designation_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                          {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {index > 0 && (
                                  <div className="add fs-4 text-center">
                                    <i
                                      className="fa-solid fa-circle-xmark"
                                      onClick={() => handleRemoveFields(index)}
                                    ></i>
                                  </div>
                                )}
                                {index === 0 && (
                                  <div className="add fs-4 text-center">
                                    <i
                                      className="fa-solid fa-circle-plus"
                                      onClick={handleAddFields}
                                    ></i>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <p className="h5 m-0 px-1 py-2">Sales staff Info:</p>
                        <div className="clientinfo-box">
                          <div className="dynamic-input ">
                            <div className="row align-items-center justify-content-between inputrow">
                              <div className="col-3">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail5">
                                    <span className="required">
                                      Sales Staff
                                    </span>
                                  </label>
                                  <Select
                                    // defaultValue={id}
                                    value={options.find((e) => e.value == id)}
                                    // value={id}
                                    onChange={HandleStaff}
                                    options={options}
                                    id="exampleInputEmail5"
                                  />
                                  {/* <select
                                    id="select1"
                                    value={id}
                                    className=" form-control"
                                    onChange={StaffChangeHandler}
                                  >
                                    {staffId.map((e) => {
                                      return (
                                        <option value={e.id}>
                                          {e.name} <span>({e.email})</span>{' '}
                                        </option>
                                      );
                                    })}
                                    <option value={id}>Select Staff</option>
                                  </select> */}
                                  {error && error.select ? (
                                    <div style={{ color: 'red' }}>
                                      {error.select}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail6">
                                    Description
                                  </label>
                                  <textarea
                                    cols="50"
                                    rows="1"
                                    style={{ overflowY: 'scroll' }}
                                    onChange={handleInputChange1}
                                    value={clientData.notes}
                                    name="notes"
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail6"
                                    placeholder="Enter Description"
                                  />
                                  {error && error.notes ? (
                                    <div style={{ color: 'red' }}>
                                      {error.notes}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="form-group active_status_flex  align-items-center m-0">
                                  <label
                                    htmlFor="exampleInputEmail7"
                                    className="m-0 px-2"
                                  >
                                    Active Status
                                  </label>
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    id="exampleInputEmail7"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-3 py-3"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '10px'
                            // marginBottom: '10px'
                          }}
                        >
                          <div className="">
                            <button
                              className="btn text-white"
                              style={{ backgroundColor: '#1e336a' }}
                              onClick={updateHandle}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="" style={{ marginLeft: '20px' }}>
                            <button
                              // style={{ width: "120px" }}
                              onClick={reloadPage}
                              // className="btn btn-danger"
                              className="btn text-black"
                              style={{ backgroundColor: '#DCDCDC' }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Constant>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Upadate Client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Client Updated Successfully
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => {
                handleClose();
                navigate('/listclients');
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ManageClients;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const AddClient = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [isChecked, setIsChecked] = useState(0);
  const [industry, setIndustry] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState();
  const token = useSelector((state) => state.token);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [gstError, setGstError] = useState('');
  const [finalCommunication, setFinalCommunication] = useState({});
  const [breadData, setBreadData] = useState([]);
  //dynamic input fields
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);

  const inputParam = inputFields.map((field, key) => ({
    [`contact_person_name_${key}`]: field.value1,
    [`contact_person_email_${key}`]: field.value2,
    [`contact_person_mobile_${key}`]: field.value3,
    [`contact_person_designation_${key}`]: field.value4
  }));

  console.log(inputParam);

  const handleInputChange = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };
  // const handleAddFields = () => {
  //   const currentInputValues = inputFields[inputFields.length - 1];

  //   // Check if any field is empty
  //   const isEmpty = Object.values(currentInputValues).some(
  //     (value) => value.trim() === ''
  //   );
  //   if (isEmpty) {
  //     // Display an error message or take appropriate action to inform the user about empty input.
  //     alert('Please fill all fields before adding more.');
  //     return;
  //   }

  //   // Check if any combination is duplicate
  //   const isDuplicate = inputFields.some(
  //     (field) =>
  //       field.value1 === currentInputValues.value1 &&
  //       field.value2 === currentInputValues.value2 &&
  //       field.value3 === currentInputValues.value3 &&
  //       field.value4 === currentInputValues.value4
  //   );

  //   if (isDuplicate) {
  //     // Display an error message or take appropriate action to inform the user about the duplicate entry.
  //     alert(
  //       'Duplicate entry! Please enter unique details for each Contact Person.'
  //     );
  //     return;
  //   }

  //   setInputFields([
  //     ...inputFields,
  //     { value1: '', value2: '', value3: '', value4: '' }
  //   ]);
  // };

  const handleAddFields = () => {
    const currentInputValue1 = inputFields[inputFields.length - 1].value1;
    const isDuplicate = inputFields
      .slice(0, -1)
      .some((field) => field.value1 === currentInputValue1);
    const currentInputValue2 = inputFields[inputFields.length - 1].value2;
    const isDuplicate2 = inputFields
      .slice(0, -1)
      .some((field) => field.value2 === currentInputValue2);
    const currentInputValue3 = inputFields[inputFields.length - 1].value3;
    const isDuplicate3 = inputFields
      .slice(0, -1)
      .some((field) => field.value3 === currentInputValue3);
    const currentInputValue4 = inputFields[inputFields.length - 1].value4;
    const isDuplicate4 = inputFields
      .slice(0, -1)
      .some((field) => field.value4 === currentInputValue4);
    const currentInputValues = inputFields[inputFields.length - 1];

    // Check if any field is empty
    const isEmpty = Object.values(currentInputValues).some(
      (value) => value.trim() === ''
    );
    if (isEmpty) {
      // Display an error message or take appropriate action to inform the user about empty input.
      Swal.fire({
        text: 'Please fill all fields before adding more.',
        confirmButtonText: 'OK',
        confirmButtonColor: "rgb(30, 51, 106)"
      })

      return;
    }
    // if (currentInputValue1.trim() === '') {
    //   // Display an error message or take appropriate action to inform the user about empty input.
    //   alert('Please enter a value for Contact Person before adding more.');
    // }
    else if (isDuplicate || isDuplicate2 || isDuplicate3 || isDuplicate4) {
      // Display an error message or take appropriate action to inform the user about the duplicate entry.
      alert('Duplicate entry! Please enter a unique name for Contact Person.');
    } else {
      setInputFields([
        ...inputFields,
        { value1: '', value2: '', value3: '', value4: '' }
      ]);
    }
  };

  // const handleAddFields = () => {
  //   // Check the total number of fields added
  //   if (inputFields.length < 3) {
  //     setInputFields([
  //       ...inputFields,
  //       { value1: '', value2: '', value3: '', value4: '' }
  //     ]);
  //   }
  // };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  // function for select staff

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  // funciton to get select industry type
  const IndustryChangeHandler = (event) => {
    setId2(event.target.value);
  };

  // api function to get staff id
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffsdropdown', config)
      .then((value) => setStaffId(value.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (staffId) {
      setResponse(staffId);
    }
  }, [staffId]);
  // function for store clients info

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      clientInfo: inputParam,
      organisation: userData.organisation,
      address: userData.address,
      gst_no: userData.gst_no,
      notes: userData.notes,
      staff_id: id?.value,
      enabled: isChecked,
      industry_id: id2?.value,
      owner_name: userData.owner_name,
      owner_email: userData.owner_email,
      owner_phone: userData.owner_phone
    };
    console.log('params.........................................', params);

    // inputParam.forEach((contact, index) => {
    //   Object.keys(contact).forEach(key => {
    //     // Assuming you want to create params with the format "contact_person_name_{index+1}"
    //     params[`${key}_${index + 1}`] = contact[key];
    //   });
    // });

    axios
      .post(Url.BASEURL + 'addclient', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/listclients');
          // toast('Client added Successfully');
          setClientData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          // setError(value.data.errors);
          closeAddModal();
          handleServerError(value.data.errors);
          setHasError(true);

          console.log(gstError);
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  // Update error state based on server response
  const handleServerError = (serverErrors) => {
    setError((prevError) => ({
      ...prevError,
      ...serverErrors
    }));
  };

  // state for add user data
  const [userData, setUserData] = useState({
    gst_no: '',
    organisation: '',
    address: '',
    notes: '',
    owner_name: '',
    owner_email: '',
    owner_phone: ''
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('USERDATA>>>......', userData);
  };

  // function for selct/unselect active status of client

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listclients');
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listclients');
  }
  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }

  const transformDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name} (${email})`
    }));

    return [selectOption, ...dataOptions];
  };

  const options = transformDataToOptions(staffId);
  const transformIndustryToOptionsData = (data) => {
    // console.log('datadatatdatdtatata', data);
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type, email }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };

  const optionsData = transformIndustryToOptionsData(industry);
  // api for breadscrums
  useEffect(() => {
    axios
      .get(Url.BASEURL + 'breadcrumbs' + location.pathname)
      .then((value) => {
        setBreadData(value.data.breadcrumbs);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Constant>
      <BreadScrum path="/listclients" />{' '}
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Client Added</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Client has been added successfully
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addModal}
            onHide={closeAddModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Client Add</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Are you sure, You want to add this Client?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={submitHandler}
              >
                Yes
              </Button>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={() => closeAddModal(false)}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Client Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="inputs">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  <span className="required">GSTIN Number</span>
                                </label>
                                <input
                                  maxLength={15}
                                  onChange={handleInputChange1}
                                  value={userData.gst_no}
                                  name="gst_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter GSTIN Number"
                                />
                                {error && error.gst_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.gst_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail2">
                                  <span className="required">Organisation</span>
                                </label>
                                <input
                                  onChange={handleInputChange1}
                                  value={userData.organisation}
                                  name="organisation"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Enter Organisation"
                                />
                                {error && error.organisation ? (
                                  <div style={{ color: 'red' }}>
                                    {error.organisation}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail3">
                                  <span className="required">Address</span>
                                </label>
                                <input
                                  onChange={handleInputChange1}
                                  value={userData.address}
                                  name="address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Enter Address"
                                />
                                {error && error.address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.address}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="inputs">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail4">
                                  <span className="required">
                                    Industry Type
                                  </span>
                                </label>
                                <Select
                                  defaultValue={id2}
                                  onChange={setId2}
                                  options={optionsData}
                                  isClearable={true}
                                  id="exampleInputEmail4"
                                />
                                {error && error.industry_id ? (
                                  <div style={{ color: 'red' }}>
                                    {error.industry_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* client Information */}
                      <p className="h5 m-0 px-1 py-2">Client Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div
                            className="row "

                          >
                            <div
                              className="col-4"
                            >
                              <div className="form-group">
                                <label htmlFor={`contactPersonName${''}`}>
                                  <span style={{ fontSize: '15px' }}>
                                    Owner Name
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Owner Name"
                                  onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ''
                                  ))
                                  }
                                  minLength={3}
                                  onChange={handleInputChange1}
                                  value={userData.owner_name}
                                  name="owner_name"
                                  type="text"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor={`contactPersonName${''}`}>
                                  <span style={{ fontSize: '15px' }}>
                                    Owner Email
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Owner Name"
                                  minLength={3}
                                  onChange={handleInputChange1}
                                  value={userData.owner_email}
                                  name="owner_email"
                                  type="email"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor={`contactPersonName${''}`}>
                                  <span style={{ fontSize: '15px' }}>
                                    Owner Contact Number
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Owner Contact Number"
                                  maxLength={10}
                                  onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ''
                                  ))
                                  }
                                  onChange={handleInputChange1}
                                  value={userData.owner_phone}
                                  name="owner_phone"
                                  type="text"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          {inputFields.map((field, index) => (
                            <div
                              className="row align-items-center justify-content-between inputrow"
                              key={index}
                            >
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor={`contactPersonName${index}`}>
                                    <span
                                      style={{ fontSize: '15px' }}
                                      className="required"
                                    >
                                      Contact Person Name
                                    </span>
                                  </label>
                                  <input
                                    onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                    }
                                    minLength={3}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        'value1',
                                        e.target.value
                                      )
                                    }
                                    value={field.value1}
                                    name="owner_name"
                                    type="text"
                                    className="form-control"
                                    id={`contactPersonName${index}`}
                                    placeholder={`Enter Name ${index + 1}`}
                                    required
                                  />
                                  {error &&
                                    error.clientInfo &&
                                    error.clientInfo[index] && (
                                      <div>
                                        {error.clientInfo[index][
                                          `contact_person_name_${index}`
                                        ] && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: '15px'
                                              }}
                                              className="text-center"
                                            >
                                              {/* {error.clientInfo[index][`contact_person_name_${index}`]} */}
                                              {/* Person's Name Required */}
                                              {
                                                error.clientInfo[index][
                                                `contact_person_name_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label htmlFor={`contactPersonEmail${index}`}>
                                    <span
                                      style={{ fontSize: '15px' }}
                                      className="required"
                                    >
                                      Contact Person's Email address
                                    </span>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        'value2',
                                        e.target.value
                                      )
                                    }
                                    value={field.value2}
                                    name="owner_email"
                                    type="email"
                                    className="form-control"
                                    id={`contactPersonEmail${index}`}
                                    placeholder={`Enter email ${index + 1}`}
                                    required
                                  />
                                  {error &&
                                    error.clientInfo &&
                                    error.clientInfo[index] && (
                                      <div>
                                        {error.clientInfo[index][
                                          `contact_person_email_${index}`
                                        ] && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: '15px'
                                              }}
                                              className="text-center"
                                            >
                                              {/* {error.clientInfo[index][`contact_person_email_${index}`]} */}
                                              {/* Person's Email Required */}
                                              {
                                                error.clientInfo[index][
                                                `contact_person_email_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                        {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label
                                    htmlFor={`contactPersonMobile${index}`}
                                  >
                                    <span
                                      className="required"
                                      style={{ fontSize: '15px' }}
                                    >
                                      Contact Person's Mobile Number
                                    </span>
                                  </label>
                                  <input
                                    maxLength={10}
                                    onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        'value3',
                                        e.target.value
                                      )
                                    }
                                    value={field.value3}
                                    name="owner_contact_no"
                                    type="text"
                                    className="form-control"
                                    id={`contactPersonMobile${index}`}
                                    placeholder={`Enter Mobile Number ${index + 1
                                      }`}
                                    required
                                  />
                                  {error &&
                                    error.clientInfo &&
                                    error.clientInfo[index] && (
                                      <div>
                                        {error.clientInfo[index][
                                          `contact_person_mobile_${index}`
                                        ] && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: '15px'
                                              }}
                                              className="text-center"
                                            >
                                              {/* {error.clientInfo[index][`contact_person_mobile_${index}`]} */}
                                              {/* Person's Mobile Required */}
                                              {
                                                error.clientInfo[index][
                                                `contact_person_mobile_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                        {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="inputs">
                                <div className="form-group">
                                  <label
                                    htmlFor={`contactPersonDesignation${index}`}
                                  >
                                    <span
                                      style={{ fontSize: '15px' }}
                                      className="required"
                                    >
                                      Contact Person Designation
                                    </span>
                                  </label>
                                  <input
                                    onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z\s]/g,
                                      ''
                                    ))
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        'value4',
                                        e.target.value
                                      )
                                    }
                                    value={field.value4}
                                    name="designation_name"
                                    type="text"
                                    className="form-control"
                                    id={`contactPersonDesignation${index}`}
                                    placeholder={`Enter Contact Person Designation`}
                                    required
                                  />
                                  {error &&
                                    error.clientInfo &&
                                    error.clientInfo[index] && (
                                      <div>
                                        {error.clientInfo[index][
                                          `contact_person_designation_${index}`
                                        ] && (
                                            <div
                                              style={{ color: 'red' }}
                                              className="text-center"
                                            >
                                              {/* {error.clientInfo[index][`contact_person_designation_${index}`]} */}
                                              {/* Person's Designation Required */}
                                              {
                                                error.clientInfo[index][
                                                `contact_person_designation_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                        {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                      </div>
                                    )}
                                </div>
                              </div>
                              {index > 0 && (
                                <div className="add fs-4 text-center">
                                  <i
                                    className="fa-solid fa-circle-xmark"
                                    onClick={() => handleRemoveFields(index)}
                                  ></i>
                                </div>
                              )}
                              {index === 0 && (
                                <div className="add fs-4 text-center">
                                  <i
                                    className="fa-solid fa-circle-plus"
                                    onClick={handleAddFields}
                                  ></i>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Sales staff Info:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail5">
                                  <span className="required">Sales Staff</span>
                                </label>
                                <Select
                                  defaultValue={id}
                                  onChange={setId}
                                  options={options}
                                  isClearable={true}
                                  id="exampleInputEmail5"
                                  maxMenuHeight="140px" // Set the minimum height here
                                // styles={{
                                //   menu: (provided, state) => ({
                                //     ...provided,
                                //     minHeight: '10px' // Adjust the value as needed
                                //   })
                                // }}
                                />
                                {/* <select
                                  id="select1"
                                  value={id}
                                  className=" form-control"
                                  onChange={StaffChangeHandler}
                                >
                                  {staffId.map((e) => {
                                    return <option value={e.id}>
                                      {e.name} <span>({e.email})</span> </option>;
                                  })}
                                  <option value={id}>Select Staff</option>
                                </select> */}
                                {error && error.staff_id ? (
                                  <div style={{ color: 'red' }}>
                                    {error.staff_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail6">
                                  Description
                                </label>
                                <textarea
                                  cols="50"
                                  rows="1"
                                  style={{ overflowY: 'scroll' }}
                                  onChange={handleInputChange1}
                                  value={userData.notes}
                                  name="notes"
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail6"
                                  placeholder="Enter Description"
                                />
                                {error && error.notes ? (
                                  <div style={{ color: 'red' }}>
                                    {error.notes}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group active_status_flex  align-items-center m-0">
                                <label
                                  htmlFor="exampleInputEmail7"
                                  className="m-0 px-2"
                                >
                                  Active Status
                                </label>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                  id="exampleInputEmail7"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-3 py-3"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px'
                          // marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={openAddModal}
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            // style={{ width: "120px" }}
                            onClick={reloadPage}
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </Constant>
  );
};
export default AddClient;

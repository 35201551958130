import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Link, NavLink, useNavigate } from 'react-router-dom';
import BreadScrum from './BreadScrum';
import './AdminLayout.css';
import { useSelector, useDispatch } from 'react-redux';
import { delToken, setToken } from '../Redux/Action/authaction';
// import menuItems from './MenuItem';
import superAdminPermission from '../../superAdminPermissions';
import { Button, Modal } from 'react-bootstrap';

import Url from '../Element/Api';
import axios from 'axios';
function Constant({ children, loading }) {
  //state for nav management
  const [allowedItems, setAllowedItems] = useState([]);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState('');
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const [companydata, setCompanydata] = useState([])


  const type = useSelector((state) => state.type);
  const permissions = useSelector((state) => state.permissions);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    dispatch(delToken(), navigate('/'));

    // navigate('/listclients');
  }

  const menuItems = [
    //dashboard
    {
      key: 'Manage Dashboard',
      to: '/dashboard',
      icon: 'nav-icon fas fa-tachometer-alt',
      label: 'Dashboard',
      disable: false
    },

    //manage clients
    {
      key: 'Manage Clients',
      to: '/listclients',
      icon: 'nav-icon fas fa-users',
      label: 'Manage Clients',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Clients?.includes('view') ||
          permissions?.Manage_Clients?.includes('update') ||
          permissions?.Manage_Clients?.includes('delete') ||
          permissions?.Manage_Clients?.includes('add')
          ? false
          : true
    },

    //manage staff
    {
      key: 'Manage Staff',
      to: '/liststaff',
      icon: 'nav-icon fas fa-male',
      label: 'Manage Staff',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Staff?.includes('view') ||
          permissions?.Manage_Staff?.includes('update') ||
          permissions?.Manage_Staff?.includes('delete') ||
          permissions?.Manage_Staff?.includes('add')
          ? false
          : true
    },

    //manage product
    {
      key: 'Manage Products',
      to: '/listproducts',
      icon: 'nav-icon fas fa-cart-plus',
      label: 'Manage Products',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Products?.includes('view') ||
          permissions?.Manage_Products?.includes('update') ||
          permissions?.Manage_Products?.includes('delete') ||
          permissions?.Manage_Products?.includes('add')
          ? false
          : true
    },

    //manage categories
    {
      key: 'Manage Categories',
      to: '/listcategory/0',
      icon: 'nav-icon fas fa-layer-group',
      label: 'Manage Categories',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Categories?.includes('view') ||
          permissions?.Manage_Categories?.includes('update') ||
          permissions?.Manage_Categories?.includes('delete') ||
          permissions?.Manage_Categories?.includes('add')
          ? false
          : true
    },

    //manage sub-categories
    {
      key: 'Manage Sub-Categories',
      to: '/listsubcategory',
      icon: 'nav-icon fas fa-solid fa-list',
      label: 'Manage Sub-Cat.',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Sub_Categories?.includes('view') ||
          permissions?.Manage_Sub_Categories?.includes('update') ||
          permissions?.Manage_Sub_Categories?.includes('delete') ||
          permissions?.Manage_Sub_Categories?.includes('add')
          ? false
          : true
    },

    //manage brands
    {
      key: 'Manage Brands',
      to: '/listbrands',
      icon: 'nav-icon fas fa-edit',
      label: 'Manage Brands',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Brands?.includes('view') ||
          permissions?.Manage_Brands?.includes('update') ||
          permissions?.Manage_Brands?.includes('delete') ||
          permissions?.Manage_Brands?.includes('add')
          ? false
          : true
    },

    //manage department
    {
      key: 'Manage Department',
      to: '/manage_user_rights',
      icon: 'nav-icon fa-solid fa-people-roof',
      label: 'Manage Department',
      disable: type === 'superadmin' ? false : true
    },

    //manage designatiuon
    {
      key: 'Manage Designation',
      to: '/listdesignation',
      icon: 'nav-icon fas fa-star',
      label: ' Manage Designation',
      disable:
        type === 'superadmin' ||
          permissions?.Designation?.includes('view') ||
          permissions?.Designation?.includes('update') ||
          permissions?.Designation?.includes('delete') ||
          permissions?.Designation?.includes('add')
          ? false
          : true
    },

    //manage industry
    {
      key: 'Manage Industry',
      to: '/listindustry',
      icon: 'nav-icon fas fa-building',
      label: 'Manage Industry',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Industry?.includes('view') ||
          permissions?.Manage_Industry?.includes('update') ||
          permissions?.Manage_Industry?.includes('delete') ||
          permissions?.Manage_Industry?.includes('add')
          ? false
          : true
    },

    //manage attendance
    {
      key: 'Manage Attendance',
      to: '/listattendance',
      icon: ' nav-icon fa-solid fa-calendar-check',
      label: 'Manage Attendance',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Staff_Attendance?.includes('view') ||
          permissions?.Manage_Staff_Attendance?.includes('update') ||
          permissions?.Manage_Staff_Attendance?.includes('delete') ||
          permissions?.Manage_Staff_Attendance?.includes('add')
          ? false
          : true
    },

    //manage users

    {
      key: 'Manage Users',
      to: '/adminusers',
      icon: ' nav-icon fa-solid fa-user-gear',
      label: 'Manage Users',
      disable: type === 'superadmin' ? false : true
    },

    //manage reporting
    {
      key: 'Manage Reporting',
      to: '/sales_report',
      icon: 'nav-icon fas fa-clipboard',
      label: 'Sales Report',
      disable:
        type === 'superadmin' ||
          permissions?.Sales_Report?.includes('view') ||
          permissions?.Sales_Report?.includes('update') ||
          permissions?.Sales_Report?.includes('delete') ||
          permissions?.Sales_Report?.includes('add')
          ? false
          : true
    },
    {
      key: 'Manage Daily task Report',
      to: '/listreport',
      icon: 'nav-icon fas fa-clipboard',
      label: 'Staff Daily Task  Report',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Staff_Daily_Task_Report?.includes('view') ||
          permissions?.Manage_Staff_Daily_Task_Report?.includes('update') ||
          permissions?.Manage_Staff_Daily_Task_Report?.includes('delete') ||
          permissions?.Manage_Staff_Daily_Task_Report?.includes('add')
          ? false
          : true
    },
    {
      key: 'Manage Staff Requests',
      to: '/staff_request',
      icon: 'nav-icon fas fa-clipboard',
      label: 'Manage Staff Requests',
      disable:
        type === 'superadmin' ||
          permissions?.Manage_Staff_Requests?.includes('view') ||
          permissions?.Manage_Staff_Requests?.includes('update') ||
          permissions?.Manage_Staff_Requests?.includes('delete') ||
          permissions?.Manage_Staff_Requests?.includes('add')
          ? false
          : true
    }

    //manage attendance
    // { key: "Manage Leads", to: "/manageleads", icon: "nav-icon fas fa-users", label: "Manage Leads" },

    // { key: "User Rights", to: "/listadminusers", icon: "nav-icon fas fa-check", label: "Admin Users" },
    // { key: "Designation", to: "/listdesignation", icon: "nav-icon fas fa-check", label: "Admin Rights" },
  ];
  // export default menuItems;

  // useEffect(() => {
  //   // RefreshTokenApi();
  //   const interval = setInterval(() => {
  //     RefreshTokenApi();
  //   }, 45 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const RefreshTokenApi = () => {
      RefreshTokenApis();
    };
    RefreshTokenApi();
    // Set up interval to call RefreshTokenApi every 45 minutes
    const interval = setInterval(RefreshTokenApi, 45 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    Getcompanydetails()
    GetRequestsCount();
  }, []);

  const RefreshTokenApis = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .post(Url.BASEURL + 'refresh-token', {}, config)
      .then((value) => {
        if (value.status === 200) {
          console.log('alueeee', value);
          // setSessionModalOpen(true);
          dispatch(setToken(value.data.token));
        } else {
          setSessionModalOpen(true);

          // dispatch(delToken(), navigate('/'));
          // setError('please enter valid credentials');
        }
      })
      .catch((err) => {
        console.log(err);
        setSessionModalOpen(true);
      });
  };

  const GetRequestsCount = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'staff-request-all', config)
      .then((value) => {
        // console.log('valuevaluevalue',value.data.status)
        if (value.data.status === 200) {
          setNotificationCount(value.data.response.count);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //// Getting role from state

  //get permissions here using useSelector

  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..............check permission........', permissions);

  // //filtering permissions here

  // console.log('filterdpermission<<<<<<<<<<<>>>>>>>>>>', Object.keys(superAdminPermission)[0]);

  // Extract permission group names from permissions array
  const allowedGroupNames = allowedItems.map(
    (allowedItem) => Object.keys(allowedItem)[0]
  );

  console.log(
    '<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>',
    allowedItems,
    allowedGroupNames
  );

  //filtering menuitems dynamically
  const filteredMenuItems = menuItems.filter((item) =>
    allowedGroupNames.includes(item.key)
  );
  console.log(
    'filter>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
    filteredMenuItems
  );

  const navigate = useNavigate();
  const logouthandler = () => {
    console.log('logout seccessfull', token);
    dispatch(delToken(), navigate('/'));
  };
  console.log('filtermenu', filteredMenuItems);
  console.log('menuitem', menuItems);

  // const filteredItems = menuItems.filter((item) => {
  //   return (
  //     type === 'superadmin' ||
  //     (item.key !== 'Manage Staff Requests' &&
  //       item.key !== 'Manage Department' &&
  //       item.key !== 'Manage Users')
  //   );
  // });

  const visibleMenuItems = menuItems.filter((item) => !item.disable);

  // console.log('-0-0-0-0-0-0-0-0-0-', filteredItems, 'type', type);




  //get comapnayt details--------

  const Getcompanydetails = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    await axios
      .get(`${Url.BASEURL}get-comp-listing`, config)
      .then((value) => {
        // console.log('valuevaluevalue',value.data.status)
        if (value.data.status === 200) {
          setCompanydata(value.data.response);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(">>>>>>>>>>company data", companydata)


  if (token) {
    return (
      // <Constant>
      // <BreadScrum brcName="Manage Clients" routePath={'dashboard'} />
      <div className="hold-transition sidebar-mini layout-fixed">
        {sessionModalOpen && (
          <Modal
            show={sessionModalOpen}
            onHide={() => setSessionModalOpen(false)}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Session Expired</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Your Session has been Expired.
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Are you sure , You want to Logout?
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={closeModal}
            >
              Yes
            </Button>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => setIsOpen(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="wrapper">
          <nav className="main-header navbar navbar-expand navbar-dark justify-content-between h-20px p-0">
            <ul className="navbar-nav">
              <li className="nav-item">
                <div
                  className="nav-link"
                  data-widget="pushmenu"
                  role="button"
                // onClick={() => setOpen(!open)}
                >
                  <i className="fas fa-bars" />
                </div>
              </li>
            </ul>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ul
                className="navbar-nav"
                style={{ position: 'relative', marginRight: 20 }}
              >
                {type === 'superadmin' ||
                  permissions?.Manage_Staff_Requests?.includes('view') ||
                  permissions?.Manage_Staff_Requests?.includes('update') ||
                  permissions?.Manage_Staff_Requests?.includes('delete') ||
                  permissions?.Manage_Staff_Requests?.includes('add') ? (
                  <Link to={'/staff_request/'}>
                    <i
                      className="fa-solid fa-bell"
                      style={{ fontSize: '24px', color: 'white' }}
                    ></i>
                    {notificationCount ? (
                      <div
                        style={{
                          backgroundColor: 'red',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          position: 'absolute',
                          top: '-6px',
                          right: '-12px',
                          fontSize: '12px' // Adjust font size for the count
                        }}
                      >
                        <span
                          style={{
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 'bold'
                          }}
                        >
                          {notificationCount}
                        </span>
                      </div>
                    ) : null}
                  </Link>
                ) : (
                  <div>
                    <i
                      className="fa-solid fa-bell"
                      style={{ fontSize: '24px', color: 'white' }}
                    ></i>
                    {notificationCount ? (
                      <div
                        style={{
                          backgroundColor: 'red',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          position: 'absolute',
                          top: '-6px',
                          right: '-12px',
                          fontSize: '12px' // Adjust font size for the count
                        }}
                      >
                        <span
                          style={{
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 'bold'
                          }}
                        >
                          {notificationCount}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              </ul>

              <ul className="navbar-nav">
                <Button
                  className="button"
                  style={{
                    background: ' rgb(30, 51, 106)',
                    border: '1px solid white'
                  }}
                  onClick={openModal}

                // onClick={logouthandler}
                >
                  Logout
                </Button>
              </ul>
            </div>
          </nav>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div
              className="brand-link"
              style={{
                margin: 'auto',
                textAlign: 'center',
                background: '#f4f6f9'
              }}
            >
              <img
                src="dist/img/symang_logo_za.png"
                // src={companydata && companydata.length > 0 ? companydata[0].url : ''}
                alt="AdminLTE Logo"
                style={{ width: '66px', height: '66px' }}
              />
            </div>
            <div
              className="sidebar"
              style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}
            >
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar justify-content-evenly"
                  // style={{ height: '90vh' }}
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {console.log(
                    '-----------------menuItems->>>>',
                    visibleMenuItems
                  )}
                  {visibleMenuItems.map((item) => (
                    // console.log('itemitem',item)

                    <li className="nav-item" key={item.key}>
                      {/* {item.disable == false ? ( */}
                      <NavLink to={item.to} className="nav-link">
                        <i className={item.icon} />
                        <p>{item.label}</p>
                      </NavLink>
                      {/* // ) : null} */}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </aside>
          <div className="content-wrapper">
            <div className="content">{children}</div>
            <footer
              className="mainfooter"
              style={{
                color: '#757575',
                fontFamily: 'Open Sans',
                fontSize: '1.2rem'
              }}
            >
              <strong
                style={{
                  color: '#757575',
                  fontSize: '1.2rem',
                  fontWeight: 'bold'
                }}
              >
                {/* OM METALS © 2023{' '} */}
                {companydata && companydata.length > 0 ? companydata[0].company_name : ''} © 2023{' '}
                <a
                  // href="https://adminlte.io"
                  style={{ textDecoration: 'none', color: '#1e336a' }}
                >
                  {/* OM METALS */}
                  {companydata && companydata.length > 0 ? companydata[0].company_name : ''}
                </a>
                .
              </strong>
              All rights reserved.
              {/* <div className="float-right d-none d-sm-inline-block">
              <b>Version</b> 3.2.0
            </div> */}
            </footer>
          </div>
        </div>
      </div>
      // </Constant>
    );
  } else {
    return (
      <>
        <Navigate to="/"></Navigate>
      </>
    );
  }
}
export default Constant;

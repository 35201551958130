import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productCategoryData } from '../Admin/Data';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Url from '../Element/Api';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

// import './AdminRights.css';
const ManageAdminRights = () => {
  const [adminRight, setAdminRight] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const token = useSelector((state) => state.token);
  const [modalIsOpen, setIsModalOpen] = useState(false);

  console.log('>>>>>>............>>>>>>>...........>>>>>>>>>>', permissions);

  let { adminRightId } = useParams();
  const navigate = useNavigate();
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/manage_user_rights');
  };

  // console.log('adminrights......', adminRight);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    // axios
    //   .get(
    //     'https://apirest.symang.in/api/getroleandpermissions/' + adminRightId,

    //     config
    //   )
    axios
      .get(
        Url.BASEURL + 'getroleandpermissions/' + adminRightId,

        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          console.log('value.data.response==>>', value.data.response);
          setAdminRight(value.data.response);
          setIsChecked(value.data.response.enabled);
          setPermissions(value.data.response.permissions);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log('permissionsAdmin>>>>>>>>>',permissions);
  const updateRights = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      role_name: adminRight.role_name,
      permissions: permissions,
      enabled: isChecked
    };
    axios
      .put(
        Url.BASEURL + 'updateroleandpermissions/' + adminRightId,
        params,
        config
      )
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal();
          // navigate('/manage_department');
          // setAdmiRights(value.data.response.data);
          // setTimeout(() => {
          // }, 2000);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCheckboxChange = (section, permission, isChecked) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };
      if (!updatedPermissions[section]) {
        updatedPermissions[section] = [];
      }

      if (isChecked) {
        // Add permission if checked
        updatedPermissions[section] = [
          ...updatedPermissions[section],
          permission
        ];
      } else {
        // Remove permission if unchecked
        updatedPermissions[section] = updatedPermissions[section].filter(
          (p) => p !== permission
        );
      }

      return updatedPermissions;
    });
  };
  // function for active update
  const handleCheckbox = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    navigate('/manage_user_rights');

    // navigate('/listbrands');
  }
  return (
    <Constant>
      <BreadScrum path="/adminrights" />{' '}
      <div>
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: '#1e336a',
              color: 'white',
              border: 'none'
            }}
          >
            <Modal.Title>UPDATE DEPARTMENT</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            Department Updated successfully
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={closeModal}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body table-responsive">
                    <div className="wrapper">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="form-group">
                            <label
                              htmlFor="exampleInputEmail1"
                              style={{ padding: '15px' }}
                            >
                              Selected Admin Right
                            </label>
                            <input
                              style={{ width: '603px', marginLeft: '10px' }}
                              value={adminRight.role_name}
                              name="pname"
                              type="text"
                              className="form-control col-8 ml-3"
                              id="exampleInputEmail1"
                            />
                          </div>
                          <div className="col-6">
                            <div className="form-group active_status_flex m-0 align-items-center pl-3">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckbox}
                              />
                              <label
                                htmlFor="exampleInputEmail1"
                                className="m-0 p-2"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                          <hr></hr>
                          {/* /.card-header */}
                          <div className="card-body p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th style={{ width: 10 }}>#</th>
                                  <th>Section</th>
                                  <th className="checkbox_alignment">View</th>
                                  <th className="checkbox_alignment">Add</th>
                                  <th className="checkbox_alignment">Update</th>
                                  <th
                                    className="checkbox_alignment"
                                    style={{ width: 40 }}
                                  >
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(permissions).map(
                                  ([section, permissionArray], index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{section}</td>
                                      <td className="checkbox_alignment">
                                        <input
                                          // defaultChecked={permissionArray.includes(
                                          //   'view'
                                          // )}
                                          type="checkbox"
                                          checked={permissionArray.includes(
                                            'view'
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              section,
                                              'view',
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="checkbox_alignment">
                                        <input
                                          // defaultChecked={permissionArray.includes(
                                          //   'add'
                                          // )}
                                          checked={permissionArray.includes(
                                            'add'
                                          )}
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              section,
                                              'add',
                                              e.target.checked
                                            )
                                          }
                                        // type="checkbox"
                                        />
                                      </td>
                                      <td className="checkbox_alignment">
                                        <input
                                          // defaultChecked={permissionArray.includes(
                                          //   'update'
                                          // )}
                                          // type="checkbox"
                                          checked={permissionArray.includes(
                                            'update'
                                          )}
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              section,
                                              'update',
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="checkbox_alignment">
                                        <input
                                          checked={permissionArray.includes(
                                            'delete'
                                          )}
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              section,
                                              'delete',
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                        {/* /.card */}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={updateRights}
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </Constant>
  );
};
export default ManageAdminRights;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { userData } from '../Admin/Data';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import {Authaction} from '../Redux/Action/authaction'
import axios from 'axios';
import Url from '../Element/Api';
import Modal from 'react-modal';
import { setStatus } from '../Redux/Action/authaction';

const AddAdminUser = () => {
  const navigate = useNavigate();
  const [teamManagerId, setTeamManagerId] = useState('');
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [designationId, setdesignationId] = useState('');
  const [response, setResponse] = useState([]);
  const [response2, setResponse2] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [isChecked, setIsChecked] = useState('');
  const [error, setError] = useState('');

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    contact_no: '',
    enabled: '',
    password: ''
  });
  const token = useSelector((state) => state.token);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('userdata>>>>>>>.......', userData);
  };

  // function to get selected Manager

  const UserChangeHandler = (event) => {
    setId(event.target.value);
  };
  // function to get selected designation

  const designationChangeHandler = (event) => {
    setId2(event.target.value);
  };

  // function to get designatios list
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'getroles', config)
      .then((value) => setDesignations(value.data.response))
      .catch((err) => console.log(err));
    console.log('teammanagerdata', designations);
  }, []);

  // function for active update
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // fucntion to store user data

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      name: userData.name,
      email: userData.email,
      enabled: isChecked,
      password: userData.password,
      admin_right: id2,
      type:'admin',
    };
    axios
      .post(Url.BASEURL + 'adduser', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal(true);

          navigate('/listadminusers');
          // toast('Client added Successfully');
          setUserData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listadminusers');
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/liststaff');
  }
  return (
    <Constant>
      <BreadScrum routePath={''} heading={'Add Admin User'} />{' '}
      <div className="wrapper">
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Admin User</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">User Name</label>
                        <input
                          // onChange={handleInputChange}
                          // value={userData.pname}
                          name="name"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter User Name"
                          onChange={handleInputChange}
                          value={userData.name}
                        />
                        {error && error.name ? (
                          <div style={{ color: 'red' }}>{error.name}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          // onChange={handleInputChange}
                          // value={userData.pname}
                          name="email"
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Email"
                          onChange={handleInputChange}
                          value={userData.email}
                        />
                        {error && error.email ? (
                          <div style={{ color: 'red' }}>{error.email}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <input
                          // onChange={handleInputChange}
                          // value={userData.pname}
                          name="password"
                          type="password"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Password"
                          onChange={handleInputChange}
                          value={userData.password}
                        />
                        {error && error.password ? (
                          <div style={{ color: 'red' }}>{error.password}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Select Admin Right
                        </label>
                        <select
                          id="select1"
                          value={id2}
                          className=" form-control"
                          onChange={designationChangeHandler}
                        >
                          {designations.map((e) => {
                            return (
                              <option value={e.id}>{e.role_name}</option>
                            );
                          })}
                          {console.log('id2', id2)}
                          <option value={id2}>Select Industry</option>
                        </select>
                        {error && error.admin_right ? (
                          <div style={{ color: 'red' }}>
                            {error.admin_right}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group active_status_flex">
                        <label htmlFor="exampleInputEmail1">
                          Active Status
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group active_status_flex">
                              <input onChange={handleCheckboxChange} type="checkbox" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={submitHandler}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            className="btn btn-primary"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default AddAdminUser;

import React, { useState, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import axios from 'axios';
import Url from '../Element/Api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

const AddEditClient = () => {
  let add = 0;
  let edit = 1;
  const [hasError, setHasError] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [gstError, setGstError] = useState('');
  const [finalCommunication, setFinalCommunication] = useState({});
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [updateClientData, setUpdateclientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [response2, setResponse2] = useState([]);
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [industry, setIndustry] = useState([]);
  const token = useSelector((state) => state.token);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  // function for select staff

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  // funciton to get select industry type
  const IndustryChangeHandler = (event) => {
    setId2(event.target.value);
  };

  // api function to get staff id
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allstaff', config)
      .then((value) => setStaffId(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (staffId) {
      setResponse(staffId);
    }
  }, [staffId]);
  // function for store clients info

  const submitHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      owner_name: userData.owner_name,

      owner_email: userData.owner_email,
      owner_contact_no: userData.owner_contact_no,
      first_person_name: userData.first_person_name,
      first_person_email: userData.first_person_email,
      first_person_contact_no: userData.first_person_contact_no,
      second_person_name: userData.second_person_name,
      second_person_email: userData.second_person_email,
      second_person_contact_no: userData.second_person_contact_no,
      organisation: userData.organisation,
      address: userData.address,
      gst_no: userData.gst_no,
      notes: userData.notes,
      staff_id: id,
      enabled: isChecked,
      industry_id: id2
    };

    axios
      .post(Url.BASEURL + 'addclient', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          openModal(true);
          // navigate('/listclients');
          // toast('Client added Successfully');
          setClientData(value.response);
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
          setHasError(true);

          console.log(gstError);
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  // state for add user data
  const [userData, setUserData] = useState({
    owner_name: '',
    owner_email: '',
    owner_contact_no: '',
    first_person_name: '',
    first_person_email: '',
    first_person_contact_no: '',
    second_person_name: '',
    second_person_email: '',
    second_person_contact_no: '',
    organisation: '',
    address: '',
    gst_no: '',
    client_dob: '',
    notes: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('USERDATA>>>......', userData);
  };

  // function for selct/unselect active status of client

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  const reloadPage = () => {
    console.log('ffhf');
    navigate('/listclients');
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'listindustry', config)
      .then((value) => setIndustry(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/listclients');
  }

  return (
    <Constant>
      <BreadScrum routePath={'manageclient'} heading={'Add Client'} />{' '}
      <div className="wrapper">
        <div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <i class="fa-solid fa-xmark"></i>{' '}
            <div>Client has been added successfully</div>
            <dv className="btn_style">
              <button
                onClick={closeModal}
                type="button"
                class="btn btn-primary"
              >
                OK
              </button>
            </dv>
          </Modal>
        </div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Client Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">Owner Name</span>
                            </label>
                            {add ? <h1>hello</h1> : <h1>byeee</h1>}
                            <input
                              onChange={handleInputChange}
                              value={userData.owner_name}
                              name="owner_name"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Name"
                            />
                            {error && error.owner_name ? (
                              <div style={{ color: 'red' }}>
                                {error.owner_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">
                                Owner's Email address
                              </span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.owner_email}
                              name="owner_email"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter email"
                            />
                            {error && error.owner_email ? (
                              <div style={{ color: 'red' }}>
                                {error.owner_email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">
                                Owner's Mobile Number
                              </span>
                            </label>
                            <input
                              maxLength={10}
                              // disabled={userData.owner_contact_no.length >= 10}
                              style={{
                                borderColor:
                                  userData.owner_contact_no.length > 10
                                    ? 'red'
                                    : '#ced4da'
                              }} // Highlight input border if exactly 10 digits
                              onChange={handleInputChange}
                              value={userData.owner_contact_no}
                              name="owner_contact_no"
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Mobile  Number"
                            />
                            {error && error.owner_contact_no ? (
                              <div style={{ color: 'red' }}>
                                {error.owner_contact_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">
                                Contact Person Name
                              </span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.first_person_name}
                              name="first_person_name"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Name"
                            />
                            {error && error.first_person_name ? (
                              <div style={{ color: 'red' }}>
                                {error.first_person_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">
                                Contact Person's Email
                              </span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.first_person_email}
                              name="first_person_email"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Email"
                            />
                            {error && error.first_person_email ? (
                              <div style={{ color: 'red' }}>
                                {error.first_person_email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">
                                Contact Person Mobile number
                              </span>
                            </label>
                            <input
                              style={{
                                borderColor:
                                  userData.first_person_contact_no.length > 10
                                    ? 'red'
                                    : '#ced4da'
                              }}
                              maxlength="10"
                              onChange={handleInputChange}
                              value={userData.first_person_contact_no}
                              name="first_person_contact_no"
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Number"
                            />
                            {error && error.first_person_contact_no ? (
                              <div style={{ color: 'red' }}>
                                {error.first_person_contact_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact Person Name 2
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.second_person_name}
                              name="second_person_name"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Name 2"
                            />
                            {error && error.contact_no ? (
                              <div style={{ color: 'red' }}>
                                {error.contact_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact Person Email 2
                            </label>
                            <input
                              style={{
                                borderColor:
                                  userData.second_person_contact_no.length > 10
                                    ? 'red'
                                    : '#ced4da'
                              }}
                              onChange={handleInputChange}
                              value={userData.second_person_email}
                              name="second_person_email"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Name 2"
                            />
                            {error && error.second_person_email ? (
                              <div style={{ color: 'red' }}>
                                {error.contact_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact Person Mobile Number 2
                            </label>
                            <input
                              onChange={handleInputChange}
                              name="name"
                              value={userData.name}
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Mobile Number 2"
                            />
                            {error && error.contact_no ? (
                              <div style={{ color: 'red' }}>
                                {error.contact_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">Organisation</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.organisation}
                              name="organisation"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Organisation"
                            />
                            {error && error.organisation ? (
                              <div style={{ color: 'red' }}>
                                {error.organisation}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">Address</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.address}
                              name="address"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Address"
                            />
                            {error && error.address ? (
                              <div style={{ color: 'red' }}>
                                {error.address}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">Industry Type</span>
                            </label>

                            <select
                              id="select1"
                              value={id2}
                              className=" form-control"
                              onChange={IndustryChangeHandler}
                            >
                              {industry.map((e) => {
                                return (
                                  <option value={e.id}>
                                    {e.industry_type}
                                  </option>
                                );
                              })}
                              {console.log('id2', id2)}
                              <option value={id2}>Select Industry</option>
                            </select>
                            {error && error.industry_id ? (
                              <div style={{ color: 'red' }}>
                                {error.industry_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">GSTIN Number</span>
                            </label>
                            <input
                              style={{
                                borderColor:
                                  userData.gst_no.length > 15
                                    ? 'red'
                                    : '#ced4da'
                              }}
                              onChange={handleInputChange}
                              value={userData.gst_no}
                              name="gst_no"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter GSTIN Number"
                            />
                            {console.log('userdata', userData)}
                            {error && error.gst_no ? (
                              <div style={{ color: 'red' }}>{error.gst_no}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span className="required">Sales Staff</span>
                            </label>

                            <select
                              id="select1"
                              value={id}
                              className=" form-control"
                              onChange={StaffChangeHandler}
                            >
                              {staffId.map((e) => {
                                return <option value={e.id}>{e.name}</option>;
                              })}
                              <option value={id}>Select Staff</option>
                            </select>
                            {hasError && (
                              <p style={{ color: 'red' }}>
                                Please select an option
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span>Date Of Birth</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.client_dob}
                              name="dob"
                              type="date"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Date of Birth"
                            />
                            {console.log('userdata', userData)}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span>Anniversary Date</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.anniversary}
                              name="anniversary"
                              type="date"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Anniversary"
                            />
                            {console.log('userdata', userData)}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <span>Designation</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.designation}
                              name="anniversary"
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Designation"
                            />
                            {console.log('userdata', userData)}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group active_status_flex">
                            <label htmlFor="exampleInputEmail1">
                              Active Status
                            </label>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group active_status_flex">
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Description
                            </label>
                            <textarea
                              cols="50"
                              rows="5"
                              style={{ overflow: 'scroll' }}
                              onChange={handleInputChange}
                              value={userData.notes}
                              name="notes"
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Description"
                            />
                            {error && error.notes ? (
                              <div style={{ color: 'red' }}>{error.notes}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '10px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={submitHandler}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            className="btn btn-primary"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </Constant>
  );
};
export default AddEditClient;

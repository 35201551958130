import React, { useState, useEffect } from 'react';
import Constant from './Admin/AdminLayout';
import BreadScrum from './Admin/BreadScrum';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Test = () => {
  const [staffData, setStaffData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchStaff, setSearchStaff] = useState('');
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [deleteRow, setDeleteRow] = useState([]);

  const token = useSelector((state) => state.token);

  useEffect(() => {
    const token =
      'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzhiODE3OGVlZGFhMjhlNDdkNmU5NmZjY2M0ZDY2NTA2ZjY0N2FmMzYzNzNhM2QxYjY5ZTJiOTEzNTAyYmE3ZDRlOWJmODM4ODFlYmY5NmUiLCJpYXQiOjE2ODg1MzIyODAuMzY0NjMsIm5iZiI6MTY4ODUzMjI4MC4zNjQ2NDYsImV4cCI6MTcyMDE1NDY4MC4xNzQ3MDUsInN1YiI6IjIiLCJzY29wZXMiOltdfQ.Wdz0BW8N5mXylxbT2NYgfLmm4pdSiMh2xmP-E7SKIZE3HspNnEGqhEDLd0QQeqlrCMWQmxUsOQ9U63hseFy_EIHKSUHjSFnHFoAAUYt2vUFf5APQTacu29_LucecLnLdF4wooUR9wAB2M7wXP1-k9LNHlchtD44ABHnTSylazghBQOQUveJxTownvL-Sx2gEfKZVeJ065GKSfmR8XW0NFbHoZCpCbDlj2PG4PjMUM5R8BwAwLBAJtfeG2NnMEueR_Tv4gE_VdLHLfLIG8DV6r5Qzqu8ooxyFuqCByrSouMYbYSwoVP1pF9JsMP9-qf8tmWerVdC9p4n0tQs4l5eyL6qN1rXYJlue_J9Tk2c_yC2pl1eldxXwQ75MouQKUSkw-d0UrBby3tp-sjkPQquiikXDElVgVlOA052BE1FbgIeVzeSbtPpF0MbTsvPImjxqvI6mjaXux95d2nUeTnfJ1lJMF2Sb9zCh7CH_9rCh2kAP3j9NXEOX_OvMhisBsCFgue8q_qzkJc7MmGsL2iyXHLil9Aja2Mw_iReEG4-YlW-JqfxlWCPZR6R0QZ9danGAAs4NsWKx7g_igMfFyTmbUPVz-UxxFKAPFwb7zeIw_i2Pg0x3bjRQkFWAfEkT8wjWlfiu5b9fUEX6Cvml1bF5Ws56xyzhrqrwgy_PKzJf7Ro';
    const config = {
      headers: {
        Authorization: token
      }
    };

    axios
      .get('http://360.myhostapp.com/api/audit_report/2', config)
      .then((value) =>
        setStaffData(
          value.data.data[0].sites.level_area[0].kt_docs_repeater_nested_inner
        )
      )
      .catch((err) => console.log(err));
  }, [searchClient, itemOffset, deleteRow]);
  console.log('dataaaaaaa', staffData);

  return <div>{}</div>;
};
export default Test;

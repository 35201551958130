import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
import { Link } from 'react-router-dom';
import './Dashboard.css';

export const DashBoard = (props) => {
  const token = useSelector((state) => state.token);

  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [reportCards, setReportCards] = useState([]);
  const [latestLeads, setLatestLeads] = useState([]);
  const [monthlySale, setMonthlySale] = useState([]);
  const [speciprodSale, setSpeciProdSale] = useState([]);
  const [name, setName] = useState([]);
  const monthlySaleWithPercentage = monthlySale.map((value) => `${value}%`);
  const type = useSelector((state) => state.type);
  const permissions = useSelector((state) => state.permissions);
  const state = {
    series: [
      {
        name: 'Sale',
        data: monthlySaleWithPercentage
      }
    ],

    options: {
      annotations: {
        points: [
          {
            x: 'Bananas',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0'
              }
            }
          }
        ]
      },
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        categories: [
          'January',
          'Februrary',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Sales'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        }
      }
    }
  };

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'admintoptabs?',

        config
      )
      .then((value) => setReportCards(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'latestleads?',

        config
      )
      .then((value) => setLatestLeads(value.data))
      .catch((err) => console.log(err));
  }, []);

  // product wise sale

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'piechartgraph?',

        config
      )
      .then(
        (value) => setSpeciProdSale(value.data.name) & setName(value.data.value)
      )
      .catch((err) => console.log(err));
  }, []);
  // graph monthly sale

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'graphmonthlysale',

        config
      )
      .then((value) => setMonthlySale(value.data))
      .catch((err) => console.log(err));
  }, []);
  useLayoutEffect(() => {
    // setWidth(ref.current.offsetWidth);
    // setHeight(ref.current.offsetHeight);
  }, []);

  useEffect(() => {
    console.log(monthlySaleWithPercentage);
  });

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      // position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        }
      }
    },
    title: {
      floating: true,
      offsetY: 100,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  });
  const series2 = name;
  const options2 = {
    chart: {
      width: 380,
      // height: 367,
      type: 'donut'
    },
    labels: speciprodSale,
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
      }
    },
    // colors: [
    //   '#008FFB',
    //   '#FF4560',
    //   '#00E396',
    //   '#FEB019',
    //   '#775DD0',
    //   '#D4526E',
    //   '#7C4D79',
    //   '#FF5A5F',
    //   '#00A699',
    //   '#F86624',
    //   '#D7263D',
    //   '#007F2D',
    //   '#B565A7',
    //   '#FFA400',
    //   '#ED553B'
    // ],

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
            // height: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };
  return (
    <Constant>
      <BreadScrum
        brcName="Dashboard"
        routePath={'dashboard'}
        heading={'Dashboard'}
      />
      <div className="wrapper">
        <section className="content section_padding">
          <div className="container-fluid">
            {/* Small boxes (Stat box) */}

            <div className="row">
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="d-flex flex-column justify-content-start box-main">
                    <div className="inner pl-3">
                      <h3>{reportCards.total_order}</h3>
                      <p>Total Win Leads</p>
                    </div>
                    <div className="inner pl-3">
                      <h4>{reportCards.current_month_order}</h4>
                      <p>Current Month's Leads</p>
                    </div>
                    {/* <div className="icon">
                    <i className="ion ion-bag" />
                  </div> */}
                  </div>
                  {type === 'superadmin' ||
                  permissions?.Sales_Report?.includes('view') ||
                  permissions?.Sales_Report?.includes('update') ||
                  permissions?.Sales_Report?.includes('delete') ||
                  permissions?.Sales_Report?.includes('add') ? (
                    <Link to="/sales_report" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  ) : (
                    <div className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </div>
                  )}
                  {/* <Link to={'/sales_report'} className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link> */}
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="d-flex flex-column justify-content-start box-main">
                    <div className="inner pl-3">
                      <h3>{reportCards.total_clients}</h3>
                      <p>Total Active Clients</p>
                    </div>
                    {/* <hr class="border border-white border-1 m-0 opacity-100 rotate"></hr> */}
                    <div className="inner pl-3">
                      <h4>{reportCards.current_month_clients}</h4>
                      <p className="fs-6">Current Month's Clients</p>
                    </div>
                  </div>

                  {/* <div className="icon">
                    <i className="ion ion-stats-bars" />
                  </div> */}
                  {type === 'superadmin' ||
                  permissions?.Manage_Clients?.includes('view') ||
                  permissions?.Manage_Clients?.includes('update') ||
                  permissions?.Manage_Clients?.includes('delete') ||
                  permissions?.Manage_Clients?.includes('add') ? (
                    <Link to="/listclients" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  ) : (
                    <div className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </div>
                  )}
                  {/* <Link to="/listclients" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link> */}
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="d-flex flex-column justify-content-start box-main">
                    <div className="inner">
                      <h3>{reportCards.total_staff}</h3>
                      <p>Total Active Staff</p>
                    </div>
                    <div className="inner">
                      <h4>{reportCards.current_month_staff}</h4>
                      <p>Current Month's Staff</p>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-person-add" />
                    </div> */}
                  </div>

                  {type === 'superadmin' ||
                  permissions?.Manage_Staff?.includes('view') ||
                  permissions?.Manage_Staff?.includes('update') ||
                  permissions?.Manage_Staff?.includes('delete') ||
                  permissions?.Manage_Staff?.includes('add') ? (
                    <Link to="/liststaff" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  ) : (
                    <div className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </div>
                  )}
                  {/* <Link to="/liststaff" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link> */}
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="d-flex flex-column justify-content-start box-main">
                    <div className="inner">
                      <h3>{reportCards.total_revenue}</h3>
                      <p>Total Revenue</p>
                    </div>
                    <div className="inner">
                      <h4>{reportCards.current_month_revenue}</h4>
                      <p>Current Month's Revenue</p>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div> */}
                  </div>

                  {type === 'superadmin' ||
                  permissions?.Sales_Report?.includes('view') ||
                  permissions?.Sales_Report?.includes('update') ||
                  permissions?.Sales_Report?.includes('delete') ||
                  permissions?.Sales_Report?.includes('add') ? (
                    <Link to="/sales_report" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  ) : (
                    <div className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </div>
                  )}

                  {/* <Link    to="/sales_report"
                   className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link> */}
                </div>
              </div>{' '}
            </div>

            <div className="row section_padding flex-wrap">
              <div className="col-lg-6 col-md-10">
                <div className="card" ref={ref}>
                  <div className="card-header">Won Leads Report 2024</div>
                  <div className="card-body p-1">
                    <ReactApexChart
                      options={state?.options}
                      series={state?.series}
                      type="bar"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="card">
                  <div className="card-header">Categorized Sales Report</div>
                  <div className="card-body p-1">
                    <ReactApexChart
                      options={options2 }
                      series={series2}
                      type="donut"
                    />
                    {console.log('height', height)}
                    {console.log('width', width)}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h5>Latest Orders</h5>
              </div>
              <div className="card-body p-2">
                <div className="table-responsive">
                  <table className="table m-0">
                    <thead>
                      <tr>
                        {/* <th>Order ID</th> */}
                        <th>Sr no.</th>

                        <th>Lead</th>
                        <th>Staff Name</th>

                        <th>Status</th>
                        <th>Order Date</th>
                      </tr>
                    </thead>
                    {latestLeads.map((e, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              <a href="pages/examples/invoice.html">
                                {index + 1}
                              </a>
                            </td>
                            <td>{e?.title}</td>
                            <td>{e?.staff?.name}</td>
                            <td>
                              <span
                                className={
                                  e?.leadstage?.stage === 'Win'
                                    ? 'badge badge-success'
                                    : 'badge badge-danger'
                                }
                              >
                                {e?.leadstage?.stage}
                              </span>
                            </td>
                            <td>
                              <div
                                className="sparkbar"
                                data-color="#00a65a"
                                data-height={20}
                              >
                                {e?.created_at}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
                {/* /.table-responsive */}
              </div>
            </div>
            <div className="row"></div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default DashBoard;

import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { staffData } from '../Admin/Data';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Element/Api';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';

let fileDataUrl = '';

const AddStaff = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teamManagerId, setTeamManagerId] = useState('');
  const [id, setId] = useState('');
  const [id2, setId2] = useState('');
  const [staffData, setStaffData] = useState([]);
  const [designationId, setdesignationId] = useState('');
  const [response, setResponse] = useState([]);
  const [response2, setResponse2] = useState([]);
  const [teamManagers, setTeamManagers] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [isChecked, setIsChecked] = useState(0);
  const [isChecked2, setIsChecked2] = useState(1);
  const [isChecked3, setIsChecked3] = useState(0);
  const [isChecked4, setIsChecked4] = useState(0);
  const [isChecked5, setIsChecked5] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState('');
  const [department, setDepartment] = useState([]);
  const [error, setError] = useState('');
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [addModal, setAddModal] = useState(false)

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    contact_no: '',
    designation: '',
    team_manager: '',
    enabled: '',
    dob: '',
    whtsapp_no: '',
    alternate_no: '',
    join_date: '',
    whatsapp_no: '',
    aadhaar_number: '',
    pan_number: '',
    password: '',
    alternate_name: '',
    permanent_address: '',
    current_address: ''
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const token = useSelector((state) => state.token);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    console.log('keyToRemove', keyToRemove);
    setError({ ...error, [e.target.name]: '' });

    console.log('userdata>>>>>>>.......', userData);
  };

  // useEffect(() => {
  //   if (isChecked2 === 1) {
  //     // alert('sdsdf ');
  //     setId('');
  //   }
  // }, [isChecked2]);

  const handleSelectChange = (value) => {
    if (isChecked2 !== 1) {
      setId(value);
    } else {
      setId('');
    }
  };
  // function to get selected Manager

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  //function for department handler
  const departmentChangeHandler = (event) => {
    setDepartmentId(event.target.value);
  };
  // function to get selected designation

  const designationChangeHandler = (event) => {
    setId2(event.target.value);
  };
  // getting allteamanagers data throuh axios

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'staffmanagerlist',config)
  //     .then((value) => setTeamManagers(value.data.response))
  //     .catch((err) => console.log(err));
  //   // console.log('teammanagerdata', teamManagers[0].designation.designation);
  // }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffmanagerlist', config)
      .then((value) => setTeamManagers(value.data.response))
      .catch((err) => console.log(err));
    // console.log('teammanagerdata', teamManagers[0].designation.designation);
  }, []);

  useEffect(() => {
    if (teamManagers) {
      setResponse(teamManagers);
    }
  }, [teamManagers]);
  const transformStaffDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name, email }) => ({
      value: id.toString(),
      label: `${name}(${email})`
    }));

    return [selectOption, ...dataOptions];
  };

  const staffOptions = transformStaffDataToOptions(teamManagers);
  console.log('departmentdepartmentdepartment', designations);

  const transformSubAdminDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, role_name }) => ({
      value: id.toString(),
      label: `${role_name}`
    }));

    return [selectOption, ...dataOptions];
  };

  const subAdminOptions = transformSubAdminDataToOptions(department);

  const transformDesignationDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, designation }) => ({
      value: id.toString(),
      label: `${designation}`
    }));

    return [selectOption, ...dataOptions];
  };

  const desinationOptions = transformDesignationDataToOptions(designations);

  // function to get designatios list
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'alldesignations', config)
      .then((value) => setDesignations(value.data.response))
      .catch((err) => console.log(err));
    console.log('teammanagerdata', designations);
  }, []);

  //getting all department
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'listDepartments', config)
      .then((value) => setDepartment(value.data.response))
      .catch((err) => console.log(err));
    // console.log('teammanagerdata', teamManagers[0].designation.designation);
  }, []);

  // function for active update
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // function for manager selection
  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked ? 1 : 0);
  };

  //function for making number same
  const handleCheckboxChange3 = (event) => {
    setIsChecked3(event.target.checked ? 1 : 0);
  };

  const handleCheckboxChange4 = (event) => {
    setIsChecked4(event.target.checked ? 1 : 0);
  };

  const handleCheckboxChange5 = (event) => {
    setIsChecked5(event.target.checked ? 1 : 0);
  };

  // state for image upload
  // const [file, setFile] = useState(null)
  const [fileDataUrl, setFileDataUrl] = useState('');
  console.log(fileDataUrl);
  const convertImageToBase64 = (file, callback) => {
    if (!file) {
      console.error('No file provided.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Url = event.target.result;
      callback(base64Url);
      setFileDataUrl(base64Url);
      // submitHandler()
    };

    reader.readAsDataURL(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && allowedFileTypes.includes(file.type)) {
      convertImageToBase64(file, (base64Url) => {
        console.log('Base64 URL:', base64Url);
        // Now you can use the base64Url as needed, such as setting it in the component state
        // this.setState({ imageBase64Url: base64Url });
      });
    } else {
      // error('Invalid file type. Please select a JPG, JPEG, or PNG file.');
    }
  };
  // fucntion to store staff data

  const submitHandler = () => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    // const params = {
    //   name: userData.name,
    //   email: userData.email,
    //   contact_no: userData.contact_no,
    //   whatsapp_no: isChecked3 ? userData.contact_no : userData.whatsapp_no,
    //   alternate_no: userData.alternate_no,
    //   dob: userData.dob,
    //   join_date: userData.join_date,
    //   team_manager: id,
    //   designation: id2,
    //   manager_status: isChecked2,
    //   sub_admin: isChecked5,
    //   department_id: departmentId,
    //   aadhaar_number: userData.aadhaar_number,
    //   pan_number: userData.pan_number,
    //   password: userData.password,
    //   alternate_name: userData.alternate_name,
    //   current_address: isChecked4 ? userData.permanent_address : userData.current_address,
    //   permanent_address: userData.permanent_address,
    //   profile_image: fileDataUrl,
    //   enabled: isChecked,
    //   isCheckedNumber: isChecked3,
    //   isCheckedAddress: isChecked4,
    // };
    // console.log(params);
    const params = {
      name: userData.name,
      email: userData.email,
      contact_no: userData.contact_no,
      whatsapp_no: isChecked3 ? userData.contact_no : userData.whatsapp_no,
      alternate_no: userData.alternate_no,
      dob: userData.dob,
      join_date: userData.join_date,
      team_manager: id?.value,
      designation: id2?.value,
      manager_status: isChecked2,
      sub_admin: isChecked5,
      department_id: departmentId?.value,
      aadhaar_number: userData.aadhaar_number,
      pan_number: userData.pan_number,
      password: userData.password,
      alternate_name: userData.alternate_name,
      current_address: isChecked4
        ? userData.permanent_address
        : userData.current_address,
      permanent_address: userData.permanent_address,
      profile_image: fileDataUrl,
      enabled: isChecked,
      isCheckedNumber: isChecked3,
      isCheckedAddress: isChecked4
    };

    axios
      .post(Url.BASEURL + 'addstaff', params, config)
      .then((value) => {
        setLoading(false);
        if (value && value.data.success === true) {
          openModal();
          // setLoading(false);
        }

        // if (
        //   (value && value.data.status === true) ||
        //   value.data.emailSend === 1
        // ) {

        //   // toast('Client added Successfully');
        //   setStaffData(value.response);

        //   // setTimeout(() => {
        //   // }, 2000);
        // }
        else {
          setError(value.data.errors);
          closeAddModal()
        }
      })
      .catch((err) => console.log(err));
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    navigate('/liststaff');
  }
  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }
  const reloadPage = () => {
    console.log('ffhf');
    navigate('/liststaff');
  };

  const currentDate = new Date().toISOString().split('T')[0];
  return (
    <Constant>
      <BreadScrum path="/liststaff" />
      <div className="wrapper">
        <div>
          <Modal
            show={modalIsOpen}
            onHide={closeModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Staff Added</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
              Staff added successfully
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={closeModal}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addModal}
            onHide={closeAddModal}
            backdrop={'static'}
            keyboard={false}
            centered
          >
            <Modal.Header
              closeButton
              style={{
                backgroundColor: '#1e336a',
                color: 'white',
                border: 'none'
              }}
            >
              <Modal.Title>Staff Add</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '20px' }}>
             Are you sure, You want to add this Staff?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: '#1E3F6A', border: 'none' }}
                onClick={submitHandler}
              >
                Yes
              </Button>
              <Button
              style={{ backgroundColor: '#1E3F6A', border: 'none' }}
              onClick={() => closeAddModal(false)}
            >
              No
            </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {loading && (
          <div className="spinner-overlay">
            <BounceLoader
              color={'#123abc'}
              loading={loading}
              css={override}
              size={60}
            />
          </div>
        )}

        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 m-auto">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Staff Information</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <div>
                    <div className="card-body">
                      <p className="h5 m-0 px-1 py-2">Personal Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="required"
                                >
                                  Name
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                  }
                                  value={userData.name}
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter name"
                                />
                                {error && error.name ? (
                                  <div style={{ color: 'red' }}>
                                    {error.name}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail2"
                                  // className="required"
                                >
                                  Pan Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     userData.pan_number.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onChange={handleInputChange}
                                  value={userData.pan_number.toUpperCase()}
                                  name="pan_number"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Enter Pan Number"
                                />
                                {error && error.pan_number ? (
                                  <div style={{ color: 'red' }}>
                                    {error.pan_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail3"
                                  // className="required"
                                >
                                  Aadhaar Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     userData.aadhaar_number.length > 12
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={12}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={userData.aadhaar_number}
                                  name="aadhaar_number"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Enter Aadhaar Number"
                                />
                                {error && error.aadhaar_number ? (
                                  <div style={{ color: 'red' }}>
                                    {error.aadhaar_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail4"
                                  className="required"
                                >
                                  Date Of Birth
                                </label>
                                <input
                                  max={currentDate}
                                  onChange={handleInputChange}
                                  name="dob"
                                  value={userData.dob}
                                  type="date"
                                  className="form-control"
                                  id="exampleInputEmail4"
                                />

                                {error && error.dob ? (
                                  <div style={{ color: 'red' }}>
                                    {error.dob}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail5"
                                  className="required"
                                >
                                  Permanent Address
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={userData.permanent_address}
                                  name="permanent_address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail5"
                                  placeholder="Enter Address"
                                />
                                {error && error.permanent_address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.permanent_address}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail6"
                                  className="required"
                                >
                                  Current Address
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={
                                    isChecked4
                                      ? userData.permanent_address
                                      : userData.current_address
                                  }
                                  name="current_address"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail6"
                                  placeholder="Enter Address"
                                  disabled={isChecked4}
                                />
                                {error && error.current_address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.current_address}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail7">
                                  Profile Image{' '}
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      paddingLeft: '10px'
                                    }}
                                  >
                                    (Only JPG, JPEG,GIF and PNG files are
                                    allowed)
                                  </span>
                                </label>
                                <input
                                  onChange={handleFileInputChange}
                                  name="profileImage_url"
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .gif"
                                  className="form-control"
                                  id="exampleInputEmail7"
                                />
                                {/* {error && error.permanent_address ? (
                                  <div style={{ color: 'red' }}>
                                    {error.permanent_address}
                                  </div>
                                ) : null} */}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group active_status_flex  align-items-center m-0 pt-2">
                                <input
                                  type="checkbox"
                                  checked={isChecked4}
                                  onChange={handleCheckboxChange4}
                                  id="exampleInputEmail8"
                                />
                                <label
                                  htmlFor="exampleInputEmail8"
                                  className="m-0 px-2"
                                >
                                  Current Address Same as Permanent Address
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Contact Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputPassword9"
                                  className="required"
                                >
                                  Email
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={userData.email}
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  id="exampleInputPassword9"
                                  placeholder="Enter Email"
                                />
                                {error && error.email ? (
                                  <div style={{ color: 'red' }}>
                                    {error.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail10"
                                  className="required"
                                >
                                  Contact Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     userData.contact_no.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={userData.contact_no}
                                  name="contact_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail10"
                                  placeholder="Enter Contact Number"
                                />
                                {error && error.contact_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.contact_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail11"
                                  className="required"
                                >
                                  WhatsApp Number
                                </label>
                                <input
                                  // style={{
                                  //   borderColor:
                                  //     userData.whatsapp_no.length > 10
                                  //       ? 'red'
                                  //       : '#ced4da'
                                  // }}
                                  maxLength={10}
                                  onChange={handleInputChange}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  value={
                                    isChecked3
                                      ? userData.contact_no
                                      : userData.whatsapp_no
                                  }
                                  type="text"
                                  name="whatsapp_no"
                                  className="form-control"
                                  id="exampleInputEmail11"
                                  placeholder="Enter whatsapp number"
                                  disabled={isChecked3}
                                />
                                {error &&
                                (error.whatsapp_no || error.contact_no) ? (
                                  <div style={{ color: 'red' }}>
                                    {error.whatsapp_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group active_status_flex  align-items-center m-0">
                                <input
                                  type="checkbox"
                                  checked={isChecked3}
                                  onChange={handleCheckboxChange3}
                                  id="exampleInputEmail12"
                                />
                                <label
                                  htmlFor="exampleInputEmail12"
                                  className="m-0 px-2"
                                >
                                  Whatsapp Number Same as Contact Number
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Employee Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail13"
                                  className="required"
                                >
                                  Joining Date
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  name="join_date"
                                  value={userData.join_date}
                                  type="date"
                                  className="form-control"
                                  id="exampleInputEmail13"
                                  placeholder="Enter Joining Year"
                                  // max={currentDate}
                                />
                                {error && error.join_date ? (
                                  <div style={{ color: 'red' }}>
                                    {error.join_date}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail14">
                                  Team Manager
                                </label>
                                <Select
                                  value={isChecked2 !== 1 ? id : null}
                                  // defaultValue={id}
                                  onChange={handleSelectChange}
                                  // onChange={isChecked2 === 1 ? null : setId}
                                  options={staffOptions}
                                  placeholder="Select Manager"
                                  isDisabled={isChecked2 === 1}
                                  id="exampleInputEmail14"
                                />
                                {error && error.team_manager ? (
                                  <div style={{ color: 'red' }}>
                                    {error.team_manager}
                                  </div>
                                ) : null}
                              </div>{' '}
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail15"
                                  className={
                                    isChecked5 === 1 ? 'required' : null
                                  }
                                >
                                  Sub-Admin's Department
                                </label>
                                <Select
                                  // defaultValue={
                                  //   isChecked5 === 1 ? departmentId : null
                                  // }
                                  value={
                                    isChecked5 === 1 ? departmentId : null
                                  }
                                  onChange={setDepartmentId}
                                  options={subAdminOptions}
                                  placeholder="Select Sub-Admin"
                                  isDisabled={isChecked5 !== 1}
                                  id="exampleInputEmail15"
                                />
                                {error && error.department_id ? (
                                  <div style={{ color: 'red' }}>
                                    {error.department_id}
                                  </div>
                                ) : null}
                              </div>{' '}
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail16"
                                  className="required"
                                >
                                  Designations
                                </label>
                                <Select
                                  defaultValue={id2}
                                  onChange={setId2}
                                  options={desinationOptions}
                                  placeholder="Select Designations"
                                  id="exampleInputEmail16"
                                />
                                {error && error.designation ? (
                                  <div style={{ color: 'red' }}>
                                    {error.designation}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group active_status_flex m-0 align-items-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked2}
                                  onChange={handleCheckboxChange2}
                                  id="exampleInputEmail17"
                                />

                                <label
                                  htmlFor="exampleInputEmail17"
                                  className="p-2 m-0"
                                >
                                  Is Manager
                                </label>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group active_status_flex m-0 align-items-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked5}
                                  onChange={handleCheckboxChange5}
                                  id="exampleInputEmail18"
                                />
                                <label
                                  htmlFor="exampleInputEmail18"
                                  className="m-0 p-2"
                                >
                                  Is Sub-Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Additional Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between inputrow">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail19">
                                  Alternate Name
                                </label>
                                <input
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={userData.alternate_name}
                                  name="alternate_name"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail19"
                                  placeholder="Enter Alternate Name"
                                />
                                {/* {error && error.password ? (
                                  <div style={{ color: 'red' }}>
                                    {error.password}
                                  </div>
                                ) : null} */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail20"
                                  // className="required"
                                >
                                  Emergency Contact No. From Family
                                </label>
                                <input
                                  //
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    ))
                                  }
                                  onChange={handleInputChange}
                                  value={userData.alternate_no}
                                  name="alternate_no"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail20"
                                  placeholder="Enter Emergency Number"
                                />
                                {error && error.alternate_no ? (
                                  <div style={{ color: 'red' }}>
                                    {error.alternate_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail21"
                                  className="required"
                                >
                                  Password
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={userData.password}
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="exampleInputEmail21"
                                  placeholder="Enter password"
                                />
                                {error && error.password ? (
                                  <div style={{ color: 'red' }}>
                                    {error.password}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group active_status_flex m-0 align-items-center pl-1 pt-3">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                  id="exampleInputEmail22"
                                />
                                <label
                                  htmlFor="exampleInputEmail22"
                                  className="m-0 p-2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* /.card-body */}
                    <div className="row">
                      <div
                        className="col-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '20px',
                          marginBottom: '10px'
                        }}
                      >
                        <div className="">
                          <button
                            onClick={openAddModal}
                            className="btn text-white"
                            style={{ backgroundColor: '#1e336a' }}
                          >
                            Submit
                          </button>
                        </div>

                        <div className="" style={{ marginLeft: '20px' }}>
                          <button
                            onClick={reloadPage}
                            className="btn text-black"
                            style={{ backgroundColor: '#DCDCDC' }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Constant>
  );
};
export default AddStaff;

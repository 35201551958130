import { Axios } from 'axios';
import React, { useEffect, useState } from 'react';
import Constant from '../Admin/AdminLayout';
import BreadScrum from '../Admin/BreadScrum';
import { productsData } from '../Admin/Data';

import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Url from '../Element/Api';
import { useSelector } from 'react-redux';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Button, Modal } from 'react-bootstrap';

const ProductsInfo = () => {
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const token = useSelector((state) => state.token);
  console.log('sadsfdgffh', token);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [productId, setProductId] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: ' ',
    orderby: 'asc'
  });
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.type);

  // setPermission(useSelector((state) => state.permissions));
  console.log('permission..................------->>>>>>', permissions);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  useEffect(() => {
    getBrandList();
  }, [searchClient, itemOffset, deleteRow, isArrowObj,pageCount]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'product-list?' +
          'search=' +
          searchClient +
          '&page=' +
          itemOffset +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,

        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    console.log('newOffset', newOffset);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  // function for delete row

  const deleteRowHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: productId
    };
    axios
      .delete(Url.BASEURL + 'deleteproduct/' + productId, config, params)
      .then((value) => {
        setDeleteRow(value.data.response);
        getBrandList();
        setSearchClient('');
        setSearchClientByName('');
      })
      .catch((err) => console.log(err));
  };
  const handleClearInput = () => {
    setSearchClient('');
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  return (
    <Constant>
      <BreadScrum
        routePath={'manageproduct'}
        heading={'Manage Product'}
        btn={'Add Product'}
        routepath={'addproduct'}
        bgColor={'#1E336A'}
        text_color={'#FFFFFF'}
        disabled={
          type === 'superadmin' || permissions?.Manage_Products?.includes('add')
            ? false
            : true
        }
      />
      <div>
        <section className="content section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div
                    className="card-body table-responsive"
                    style={{ overflow: 'hidden' }}
                  >
                    <div
                      className="search-field d-none d-md-block"
                      style={{ marginBottom: '13px' }}
                    >
                      <div className="">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div
                            className="search-field d-none d-md-block"
                            style={{ marginBottom: '13px' }}
                          >
                            <div
                              style={{ marginTop: '19px' }}
                              className="d-flex align-items-center h-100 searchbar_parent"
                              action="#"
                            >
                              <div
                                className=" position-relative"
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  border: '1px solid black'
                                }}
                              >
                                <div className="input-group-prepend bg-transparent"></div>
                                <input
                                  style={{
                                    height: 30,
                                    paddingRight: 30
                                  }}
                                  onChange={(e) =>
                                    setSearchClient(e.target.value) &
                                    handleKeyPress()
                                  }
                                  type="text"
                                  className="form-control bg-transparent border-0"
                                  placeholder="Search"
                                  value={searchClient}
                                />
                                {searchClient && (
                                  <span
                                    className="position-absolute top-50 end-0 translate-middle-y"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: 10
                                    }}
                                    onClick={handleClearInput}
                                  >
                                    ✕
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrapper">
                      {loading ? (
                        <div
                          className="spinner-container"
                          style={{ marginLeft: '500px' }}
                        >
                          <BounceLoader
                            color={'#123abc'}
                            loading={loading}
                            css={override}
                            size={60}
                          />
                        </div>
                      ) : (
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              {/* <th>Sr. No</th> */}
                              <th className="tablehead">
                                {' '}
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('brand_id', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'brand_id' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Brand Name
                              </th>
                              <th className="tablehead">
                                {' '}
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('product_name', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'product_name' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Product Name
                              </th>
                              <th className="tablehead">
                                {' '}
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('category_id', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'category_id' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Category
                              </th>
                              <th className="tablehead">
                                {' '}
                                {/* <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('sub_category_id', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                      isArrowObj.column == 'sub_category_id' &&
                                      isArrowObj.orderby == 'desc'
                                        ? 'fa fa-arrow-down'
                                        : 'fa fa-arrow-up'
                                    }
                                    style={{ color: 'grey' }}
                                  ></i>
                                </span> */}
                                Sub Category
                              </th>
                              {type === 'superadmin' ||
                              permissions?.Manage_Products?.includes('view') ||
                              permissions?.Manage_Products?.includes(
                                'update'
                              ) ||
                              permissions?.Manage_Products?.includes(
                                'delete'
                              ) ? (
                                <th style={{ textAlign: 'center' }}>Actions</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {clientData.map((e, index) => {
                              console.log('eeeeeeeee', e);
                              return (
                                <tr>
                                  {/* <td>{index + 1}</td> */}
                                  <td style={{ textAlign: 'center' }}>
                                    {e?.product_brand?.bname}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {e?.product_name ? e?.product_name : ''}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {e?.product_category.name}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {' '}
                                    {e?.product_sub_category?.name}
                                  </td>
                                  {type === 'superadmin' ||
                                  permissions?.Manage_Products?.includes(
                                    'view'
                                  ) ||
                                  permissions?.Manage_Products?.includes(
                                    'update'
                                  ) ||
                                  permissions?.Manage_Products?.includes(
                                    'delete'
                                  ) ? (
                                    <td>
                                      <div className="col-12 action_flex">
                                        {type === 'superadmin' ||
                                        permissions?.Manage_Products?.includes(
                                          'view'
                                        ) ? (
                                          <Link
                                            to={'/productDetail/' + e.id}
                                            className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                          ></Link>
                                        ) : null}
                                        {type === 'superadmin' ||
                                        permissions?.Manage_Products?.includes(
                                          'update'
                                        ) ? (
                                          <Link
                                            to={'/manageproduct/' + e.id}
                                            className="nav-icon fas fa-edit"
                                          ></Link>
                                        ) : null}
                                        {type === 'superadmin' ||
                                        permissions?.Manage_Products?.includes(
                                          'delete'
                                        ) ? (
                                          <i
                                            onClick={() => {
                                              // setUserId(e.id);
                                              handleShow();
                                              setProductId(e.id);
                                              // deleteRowHandler(e.id);
                                            }}
                                            className="nav-icon fas fa-times pointer_cursor"
                                          />
                                        ) : null}
                                      </div>
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="active"
                        onPageChange={handlePageClick}
                      />
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Product?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleShow2();
                handleClose();
                deleteRowHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop={'static'}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Product Deleted Sucessfully.</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose2}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Constant>
  );
};
export default ProductsInfo;
